import React from 'react';
import PropTypes from 'prop-types';
import { isNullOrUndefined } from '../../helpers/Utils';
import { makeStyles, Button, Grid } from '@material-ui/core';
import { mdiFacebookBox } from '@mdi/js';
import { Icon } from '@mdi/react';
import UserController from '../../controllers/UserController';

const useStyles = makeStyles(() => ({
	socialIcon: {
		marginRight: '10px',
	},
	googleButtonHolder: {
		'& .abcRioButton': {
			width: '100% !important',
		},
	},
}));

export function LoginSocial(props) {
	const { error, complete, isRegistration, ...otherProps } = props;

	return null;

	React.useEffect(() => {
		window.gapi.load('auth2', () => {
			const auth2 = window.gapi.auth2.init({
				client_id: '523243064179-givrom5hco81i6204ijiipssme5cnc5f.apps.googleusercontent.com',
			});
			auth2.then(() => {
				//TODO: check if logged in
				auth2.isSignedIn.get();
			});
		});

		window.gapi.load('signin2', function () {
			// Method 3: render a sign in button
			// using this method will show Signed In if the user is already signed in
			var opts = {
				height: 50,
				longtitle: true,
				client_id: '523243064179-givrom5hco81i6204ijiipssme5cnc5f.apps.googleusercontent.com',
				onsuccess: loggedInWithGoogle,
				onfailure: loggedInFailed,
			};
			window.gapi.signin2.render('loginButton', opts);
		});
	}, []);

	const classes = useStyles();

	async function loggedInWithGoogle(googleUser) {
		let response;
		if (isRegistration) {
			response = await UserController.registerGoogle(googleUser.xc.access_token);
		} else {
			response = await UserController.loginGoogle(googleUser.xc.access_token);
		}
		if (response.hasError) {
			if (!isNullOrUndefined(response.data) && response.data == 'TwoFactorRequired') {
				//TODO:Redirect to a dedicated 2fa page
				return;
			}
			props.error(response.data);
		} else {
			const { userName, role } = response.data;
			props.complete(userName, role);
		}
	}

	function loggedInFailed(error) {
		console.log(error);
		props.error(error);
	}

	return (
		<Grid container spacing={2} {...otherProps}>
			{/* <Grid item xs={12}>
            <Button fullWidth variant="contained" onClick={handleFacebookLogin}>
                <Icon className={classes.socialIcon} path={mdiFacebookBox} size={1} />
                Login with Facebook
            </Button>
        </Grid> */}
			<Grid item xs={12}>
				<div id="loginButton" className={classes.googleButtonHolder}></div>
			</Grid>
		</Grid>
	);
}

LoginSocial.propTypes = {
	error: PropTypes.func.isRequired,
	complete: PropTypes.func.isRequired,
	isRegistration: PropTypes.bool.isRequired,
};
