export function isNullOrUndefined(obj) {
	return obj === null || obj === undefined;
}

export function isNullOrEmpty(obj) {
	return obj === null || obj === undefined || obj === '';
}

export function isNullOrWhitespace(string) {
	return isNullOrUndefined(string) || string.trim().length === 0;
}

export function generateResponse(hasError = false, data) {
	return { hasError, data };
}

export function checkPassword(passwordRequirements, password) {
	if (isNullOrUndefined(passwordRequirements) || isNullOrUndefined(password)) {
		return;
	}
	const validChars = Array.from('ABCDEFGHJKLMNOPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz0123456789!@$?_-');
	const currentChars = Array.from(password);
	currentChars.forEach(element => {
		if (!validChars.includes(element)) {
			return 'Invalid character detected';
		}
	});
	if (currentChars.length < passwordRequirements.requiredLength) {
		return 'Password must be at least ' + passwordRequirements.requiredLength + ' characters long';
	}
	if (passwordRequirements.requireDigit === true) {
		if (!/\d/.test(password)) {
			return 'Password must contain one number';
		}
	}
	if (passwordRequirements.requireLowercase === true) {
		if (!/[a-z]/.test(password)) {
			return 'Password must contain one lowercase character';
		}
	}
	if (passwordRequirements.requireUppercase === true) {
		if (!/[A-Z]/.test(password)) {
			return 'Password must contain one uppercase character';
		}
	}
	if (passwordRequirements.requireNonAlphanumeric === true) {
		if (!/[!@$?_-]/.test(password)) {
			return 'Password must contain one special character (!@$?_-)';
		}
	}
	return null;
}

export function getQueryStringParam(paramName, location) {
	return new URLSearchParams(location.search).get(paramName);
}

export function flattenArrayForForm(formData, rootValue, value, index) {
	//This is a simple implementation for a 1 deep object with 1 object arrays
	//For a full tree search implementation look at https://stackoverflow.com/a/42483509/12078154
	for (var key in value) {
		const currentValue = value[key];
		if (isNullOrUndefined(currentValue)) {
			continue;
		}
		if (Array.isArray(currentValue)) {
			for (let i = 0; i < currentValue.length; i++) {
				formData.append(`${rootValue}[${index}][${key}][${i}]`, currentValue[i]);
			}
			continue;
		}
		if (currentValue instanceof File) {
			formData.append(`${rootValue}[${index}][${key}]`, index);
			continue;
		}
		formData.append(`${rootValue}[${index}][${key}]`, currentValue);
	}
}
