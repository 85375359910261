import Axios from 'axios';
import UserController from './UserController';
import { APIRoute, APIPath } from '../helpers/Constants';
import { generateResponse } from '../helpers/Utils';

const VariationController = {
	getVariations,
	addVariation,
	editVariation,
	deleteVariation,
};

async function getVariations() {
	return Axios.get(APIRoute + APIPath.GET_VARIATION)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function addVariation(VariationName, VariationTypeId, UIObject, OrderIndex) {
	const userHeaders = await UserController.getUserHeaders();
	const data = {
		Name: VariationName,
		VariationTypeId: VariationTypeId,
		UIObject: UIObject,
		OrderIndex: Number(OrderIndex),
	};
	return Axios.post(APIRoute + APIPath.ADD_VARIATION, data, userHeaders)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function editVariation(id, VariationName, UIObject, OrderIndex) {
	const userHeaders = await UserController.getUserHeaders();
	const data = {
		Id: id,
		Name: VariationName,
		UIObject: UIObject,
		OrderIndex: Number(OrderIndex),
	};
	return Axios.post(APIRoute + APIPath.EDIT_VARIATION + id, data, userHeaders)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function deleteVariation(id) {
	const userHeaders = await UserController.getUserHeaders();

	return Axios.delete(APIRoute + APIPath.DELETE_VARIATION + id, userHeaders)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

export default VariationController;
