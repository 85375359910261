import React from 'react';
import DeliveryPointController from '../../controllers/DeliveryPointController';
import {
	Container,
	Typography,
	Grid,
	Table,
	TableHead,
	TableRow,
	TableCell,
	Button,
	TableBody,
	Dialog,
	DialogTitle,
	Divider,
	DialogContent,
	TextField,
	DialogActions,
} from '@material-ui/core';
import { LoadingOverlay } from '../../components/Common/LoadingOverlay';
import { Alert } from '@material-ui/lab';
import { AddCircle, Delete, Edit } from '@material-ui/icons';
import { isNullOrUndefined } from '../../helpers/Utils';

export default function DeliveryPointEditor() {
	const [loading, setLoading] = React.useState(true);
	const [warningText, setWarningText] = React.useState(null);
	const [deliveryPoints, setDeliveryPoints] = React.useState([]);

	const [showAddModal, setShowAddModal] = React.useState(false);
	const [newDeliveryPointName, setNewDeliveryPointName] = React.useState('');
	const [adding, SetAdding] = React.useState(false);

	const [showDelete, setShowDelete] = React.useState(false);
	const [showEdit, setShowEdit] = React.useState(false);
	const [editValue, setEditValue] = React.useState(null);
	// initialise
	React.useEffect(() => {
		async function init() {
			const response = await DeliveryPointController.getDeliveryPoints();
			if (response.hasError) {
				HandleError(response.data.response);
				return;
			}
			setDeliveryPoints(response.data);
			setLoading(false);
		}
		init();
	}, [setDeliveryPoints]);

	function handleInput(event) {
		const name = event.target.name;
		const value = event.target.value;

		switch (name) {
			case 'deliveryPointName':
				setNewDeliveryPointName(value);
				break;
			default:
				return;
		}
	}

	async function AddDeliveryPoint() {
		SetAdding(true);
		const response = await DeliveryPointController.addDeliveryPoint(newDeliveryPointName);
		if (response.hasError) {
			SetAdding(false);
			HandleError(response.data.response);
			return;
		}
		SetAdding(false);
		setDeliveryPoints([...deliveryPoints, response.data]);
		setShowAddModal(false);
		setNewDeliveryPointName('');
		setWarningText(null);
	}

	async function SaveDeliveryPoint() {
		SetAdding(true);
		const response = await DeliveryPointController.editDeliveryPoint(editValue.id, newDeliveryPointName);
		if (response.hasError) {
			SetAdding(false);
			HandleError(response.data.response);
			return;
		}
		SetAdding(false);
		editValue.name = newDeliveryPointName;
		setShowEdit(false);
		setNewDeliveryPointName('');
		setWarningText(null);
	}

	async function ConfirmDeleteDeliveryPoint() {
		SetAdding(true);
		const response = await DeliveryPointController.deleteDeliveryPoint(editValue.id);
		if (response.hasError) {
			SetAdding(false);
			HandleError(response.data.response);
			return;
		}
		SetAdding(false);
		const currentDeliveryPoints = deliveryPoints;
		currentDeliveryPoints.splice(currentDeliveryPoints.indexOf(editValue), 1);
		setDeliveryPoints([...currentDeliveryPoints]);
		setShowDelete(false);
		setWarningText(null);
	}

	function HandleError(response) {
		if (isNullOrUndefined(response)) {
			return 'Failed to connect to server. Please check your internet connection';
		}
		if (isNullOrUndefined(response.response) || isNullOrUndefined(response.response.data)) {
			setWarningText(response.message);
			return;
		}
		setWarningText(response.response.data);
	}

	function EditDeliveryPoint(value) {
		setShowEdit(true);
		setEditValue(value);
		setNewDeliveryPointName(value.name);
	}

	function CloseEdit() {
		setShowEdit(false);
		setEditValue(null);
		setNewDeliveryPointName('');
		setWarningText(null);
	}

	function DeleteDeliveryPoint(value) {
		setShowDelete(true);
		setEditValue(value);
		setNewDeliveryPointName(value.name);
	}

	function CloseDelete() {
		setShowDelete(false);
		setEditValue(null);
		setWarningText(null);
	}

	function buildTable() {
		return (
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Name</TableCell>
						<TableCell></TableCell>
						<TableCell align="right">
							<Button
								onClick={() => {
									setShowAddModal(true);
								}}
							>
								<AddCircle />
							</Button>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{deliveryPoints
						.sort((a, b) => {
							return a.name < b.name ? -1 : 1;
						})
						.map(value => {
							return (
								<TableRow key={value.id}>
									<TableCell>{value.name}</TableCell>
									<TableCell align="right">
										<Button
											onClick={() => {
												EditDeliveryPoint(value);
											}}
										>
											<Edit />
										</Button>
									</TableCell>
									<TableCell align="right">
										<Button
											onClick={() => {
												DeleteDeliveryPoint(value);
											}}
										>
											<Delete />
										</Button>
									</TableCell>
								</TableRow>
							);
						})}
				</TableBody>
			</Table>
		);
	}

	function buildAddModal() {
		return (
			<Dialog
				open={showAddModal}
				onClose={() => {
					setShowAddModal(false);
				}}
			>
				<DialogTitle>Add Delivery Point</DialogTitle>
				<Divider />
				<DialogContent>
					<TextField
						id="DeliveryPoint-Name"
						label="Delivery Point Name *"
						value={newDeliveryPointName}
						onChange={handleInput}
						variant="outlined"
						name="deliveryPointName"
					/>
					{buildWarning()}
				</DialogContent>
				<Divider />
				<DialogActions>
					<Button onClick={() => AddDeliveryPoint()} disabled={adding}>
						{adding ? 'Adding' : 'Add'}
					</Button>
					<Button
						onClick={() => {
							setShowAddModal(false);
						}}
					>
						Close
					</Button>
				</DialogActions>
			</Dialog>
		);
	}

	function buildEditModal() {
		return (
			<Dialog
				open={showEdit}
				onClose={() => {
					CloseEdit();
				}}
			>
				<DialogTitle>Edit Delivery Point</DialogTitle>
				<Divider />
				<DialogContent>
					<TextField
						id="DeliveryPoint-Name"
						label="Delivery Point Name *"
						value={newDeliveryPointName}
						onChange={handleInput}
						variant="outlined"
						name="deliveryPointName"
					/>
					{buildWarning()}
				</DialogContent>
				<Divider />
				<DialogActions>
					<Button onClick={() => SaveDeliveryPoint()} disabled={adding}>
						{adding ? 'Saving' : 'Save'}
					</Button>
					<Button
						onClick={() => {
							CloseEdit();
						}}
					>
						Close
					</Button>
				</DialogActions>
			</Dialog>
		);
	}

	function buildDeleteModal() {
		return (
			<Dialog
				open={showDelete}
				onClose={() => {
					CloseDelete();
				}}
			>
				<DialogTitle>Delete Delivery Point</DialogTitle>
				<Divider />
				<DialogContent>
					<p>You are permanently deleting {editValue?.name}</p>
					{buildWarning()}
				</DialogContent>
				<Divider />
				<DialogActions>
					<Button onClick={() => ConfirmDeleteDeliveryPoint()} disabled={adding}>
						{adding ? 'Deleting' : 'Delete'}
					</Button>
					<Button
						onClick={() => {
							CloseDelete();
						}}
					>
						Close
					</Button>
				</DialogActions>
			</Dialog>
		);
	}

	function buildWarning() {
		if (isNullOrUndefined(warningText)) return null;
		return <Alert severity="warning">{warningText}</Alert>;
	}

	return (
		<Container maxWidth="xs">
			<LoadingOverlay loading={loading} />

			<Typography variant="h3" align="center" gutterBottom>
				Delivery Point Editing
			</Typography>
			{buildAddModal()}
			{buildEditModal()}
			{buildDeleteModal()}
			<Grid item xs={12}>
				{buildWarning()}
			</Grid>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					{buildTable()}
				</Grid>
			</Grid>
		</Container>
	);
}

DeliveryPointEditor.propTypes = {};
