import React from 'react';
import { Link } from 'react-router-dom';
import HomePageTextController from '../../controllers/HomePageTextController';
import { Button, Container, Card, CardContent, CardActions, CardHeader } from '@material-ui/core';
import logo from '../../images/uex-logo.png';
import { isNullOrUndefined } from '../../helpers/Utils';
import { Alert } from '@material-ui/lab';
import { LoadingOverlay } from '../../components/Common/LoadingOverlay';
import SettingsController from '../../controllers/SettingsController';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Role } from '../../helpers/Constants';

function Home(props) {
	const [loading, setLoading] = React.useState(false);
	const [disabled, setDisabled] = React.useState(false);
	const [warningText, setWarningText] = React.useState(null);
	const [descriptionText, setDescriptionText] = React.useState([]);
	const [buttonText, setButtonText] = React.useState(null);
	const [disableAdditionalInfo, setDisableAdditionalInfo] = React.useState(false);

	const { role } = props.LogIn;
	const isAdmin = role && role.includes(Role.Admin);

	React.useEffect(() => {
		async function init() {
			setLoading(true);
			const homePageResponse = await HomePageTextController.getHomePageText();
			if (homePageResponse.hasError) {
				HandleError(homePageResponse.data);
				setLoading(false);
				return;
			}
			setDescriptionText(homePageResponse.data.text.split('\n'));
			setButtonText(homePageResponse.data.buttonText);

			await fetchSettings();
			setLoading(false);
		}
		init();
	}, []);

	const fetchSettings = async () => {
		const settingsResponse = await SettingsController.getSettings();
		if (!settingsResponse.hasError) {
			const buttonDisabled = !isAdmin && settingsResponse.data.disableBookingForm;
			setDisabled(buttonDisabled);
			setDisableAdditionalInfo(settingsResponse.data.disableAdditionalInfoPage);
			return;
		}
	};

	function HandleError(response) {
		if (isNullOrUndefined(response)) {
			return 'Failed to connect to server. Please check your internet connection';
		}
		if (isNullOrUndefined(response.response) || isNullOrUndefined(response.response.data)) {
			setWarningText(response.message);
			return;
		}
		setWarningText(response.response.data);
	}

	function buildWarning() {
		if (isNullOrUndefined(warningText)) return null;
		return <Alert severity="warning">{warningText}</Alert>;
	}

	return (
		<React.Fragment>
			<LoadingOverlay loading={loading} />
			<Container maxWidth="sm">
				<Card variant="outlined">
					<CardContent style={{ textAlign: 'center' }}>
						<img src={logo} alt="Uniform Exchange" style={{ maxWidth: 200 }} />
					</CardContent>
					<CardHeader title="Dear User" />
					<CardContent>
						{buildWarning()}
						{descriptionText.map((value, index) => {
							return [value, <br key={index} />];
						})}
					</CardContent>
					<CardActions>
						<Button
							component={Link}
							to={disableAdditionalInfo ? '/Children' : '/AdditionalInfo'}
							color="primary"
							variant="contained"
							fullWidth
							disableElevation
							disabled={disabled}
						>
							{disabled ? 'Unable To Request Uniform' : buttonText ?? 'Request Uniform'}
						</Button>
					</CardActions>
				</Card>
			</Container>
		</React.Fragment>
	);
}

const mapStateToProps = state => ({
	LogIn: state.Authentication,
});

export default connect(mapStateToProps, null)(Home);

Home.propTypes = {
	LogIn: PropTypes.object,
};
