import Axios from 'axios';
import UserController from './UserController';
import { APIRoute, APIPath } from '../helpers/Constants';
import { generateResponse } from '../helpers/Utils';

const AdditionalInfoController = {
	getAdditionalInfoSettings,
	putAdditionalInfoSettings,
	getAdminAdditionalInfoSettings,
};

async function getAdditionalInfoSettings() {
	return Axios.get(APIRoute + APIPath.ADDITIONAL_INFO_SETTINGS)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function getAdminAdditionalInfoSettings() {
	const userHeaders = await UserController.getUserHeaders();
	return Axios.get(APIRoute + APIPath.ADMIN_ADDITIONAL_INFO_SETTINGS, userHeaders)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function putAdditionalInfoSettings(
	title,
	description,
	optionAUserText,
	optionBUserText,
	optionAAdminText,
	optionBAdminText
) {
	const userHeaders = await UserController.getUserHeaders();
	const data = {
		Title: title,
		Description: description,
		OptionA: {
			Id: 'A',
			UserText: optionAUserText,
			AdminText: optionAAdminText,
		},
		OptionB: {
			Id: 'B',
			UserText: optionBUserText,
			AdminText: optionBAdminText,
		},
	};
	return Axios.put(APIRoute + APIPath.ADDITIONAL_INFO_SETTINGS, data, userHeaders)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

export default AdditionalInfoController;
