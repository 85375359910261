import React from 'react';
import PropTypes from 'prop-types';
import UserController from '../../controllers/UserController';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { Button, Grid, Container, Typography, TextField } from '@material-ui/core';
import { Alert } from '../../components/Common/Alert';

function PasswordForgot(props) {
	const [email, setEmail] = React.useState('');
	const [warningText, setWarningText] = React.useState(null);
	const [emailSent, setEmailSent] = React.useState(false);
	const [submitting, setSubmitting] = React.useState(false);

	// initialise
	React.useEffect(() => {
		if (props.Auth.isAuthenticated) {
			props.PushHistory('/Login');
		}
	}, [props]);

	async function handleSubmit(event) {
		event.preventDefault();
		setWarningText(null);
		setSubmitting(true);
		const response = await UserController.forgotPassword(email);
		if (response.hasError) {
			setWarningText(response.data);
		} else {
			setEmailSent(true);
		}
		setSubmitting(false);
	}

	function handleInput(event) {
		const value = event.target.value;
		setEmail(value);
	}

	function buildEmailForm() {
		return (
			<form onSubmit={handleSubmit}>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<TextField
							name="email"
							label="Enter Email"
							placeholder="Placeholder"
							margin="normal"
							onChange={handleInput}
							value={email}
							type="text"
							fullWidth
							required
						/>
					</Grid>
					<Grid item xs={12}>
						<Alert header="Something went wrong!" text={warningText} />
					</Grid>
					<Grid item xs={12}>
						<Button disabled={submitting} variant="contained" type="submit" fullWidth>
							Submit
						</Button>
					</Grid>
				</Grid>
			</form>
		);
	}

	function buildSuccessContent() {
		return (
			<Grid container spacing={3} style={{ marginTop: 20 }}>
				<Grid item xs={12}>
					<Typography variant="subtitle2" align="center" paragraph>
						An email has been sent to your account containing a reset password link
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Button onClick={() => props.PushHistory('/Login')} variant="contained" fullWidth>
						Back to Login
					</Button>
				</Grid>
			</Grid>
		);
	}

	function buildContent() {
		return !emailSent ? buildEmailForm() : buildSuccessContent();
	}

	return (
		<Container maxWidth="xs">
			<Typography variant="h3" align="center" gutterBottom>
				Forgot Password
			</Typography>
			{buildContent()}
		</Container>
	);
}

const mapStateToProps = state => ({
	Auth: state.Authentication,
});

const mapDispatchToProps = dispatch => ({
	PushHistory: data => dispatch(push(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordForgot);

PasswordForgot.propTypes = {
	Auth: PropTypes.object,
	PushHistory: PropTypes.func,
};
