import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import combineReducers from '../Store';

export const history = createBrowserHistory();

export default function configureStore(preloadedState) {
	const store = createStore(
		combineReducers(history), // root reducer with router state
		preloadedState,
		compose(applyMiddleware(routerMiddleware(history)))
	);

	return store;
}
