import React from 'react';
import PropTypes from 'prop-types';
import UserController from '../../controllers/UserController';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '../../components/Common/Alert';
import { checkPassword, isNullOrUndefined } from '../../helpers/Utils';
import { Button, Container, Grid, Typography, TextField } from '@material-ui/core';
import { LoginSocial } from '../../components/Auth/LoginSocial';
import { SpacedDivider } from '../../components/Common/SpacedDivider';
import { LogIn } from '../../stores/Actions/Authentication';

const useStyles = makeStyles(() => ({
	socialWrapper: {
		marginTop: 20,
	},
}));

function Register(props) {
	const [email, setEmail] = React.useState('');
	const [firstName, setFirstName] = React.useState('');
	const [lastName, setLastName] = React.useState('');
	const [password, setPassword] = React.useState('');
	const [passwordConfirmation, setPasswordConfirmation] = React.useState('');
	const [submitting, setSubmitting] = React.useState(false);
	const [warningText, setWarningText] = React.useState(null);
	const [passwordRequirements, setPasswordRequirements] = React.useState(null);
	const [passwordError, setPasswordError] = React.useState(null);
	const [redirectUrl, setRedirectUrl] = React.useState(null);
	const classes = useStyles();

	// initialise
	React.useEffect(() => {
		async function init() {
			const pwordReqResponse = await UserController.getPasswordRequirements();
			if (!pwordReqResponse.hasError) {
				setPasswordRequirements(pwordReqResponse.data);
			} else {
				setWarningText('Failed to fetch password requirements');
			}
		}
		init();
	}, []);

	// redirect
	React.useEffect(() => {
		if (isNullOrUndefined(redirectUrl)) {
			return;
		}
		props.PushHistory(redirectUrl);
	}, [redirectUrl, props]);

	async function handleSubmit(event) {
		event.preventDefault();
		setSubmitting(true);
		setWarningText(null);

		if (password !== passwordConfirmation) {
			setWarningText('The two passwords do not match');
			return;
		}

		const response = await UserController.register(firstName, lastName, email, password, passwordConfirmation);
		if (!response.hasError) {
			const { userName, role } = response.data;
			props.LogIn({
				userName,
				role,
				isLoggingIn: false,
				isAuthenticated: true,
			});
			setRedirectUrl('/');
		} else {
			setSubmitting(false);
			setWarningText(response.data);
		}
	}

	function handleInput(event) {
		const name = event.target.name;
		const value = event.target.value;

		switch (name) {
			case 'firstName':
				setFirstName(value);
				break;
			case 'lastName':
				setLastName(value);
				break;
			case 'email':
				setEmail(value);
				break;
			case 'password':
				setPassword(value);
				break;
			case 'passwordConfirmation':
				setPasswordConfirmation(value);
				break;
			default:
				return;
		}
	}

	async function checkPasswordIsValid() {
		setPasswordError(checkPassword(passwordRequirements, password));
	}

	function buildRegisterForm() {
		return (
			<form onSubmit={handleSubmit}>
				<Grid container spacing={3}>
					<Grid item md={12} sm={12} xs={12}>
						<TextField
							id="email-input"
							label="Email"
							type="email"
							required
							value={email}
							onChange={handleInput}
							name="email"
							fullWidth
						/>
					</Grid>
					<Grid item md={6} sm={12} xs={12}>
						<TextField
							id="first-name-input"
							label="First Name"
							type="text"
							required
							value={firstName}
							onChange={handleInput}
							name="firstName"
							fullWidth
						/>
					</Grid>
					<Grid item md={6} sm={12} xs={12}>
						<TextField
							id="last-name-input"
							label="Last Name"
							type="text"
							required
							value={lastName}
							onChange={handleInput}
							name="lastName"
							fullWidth
						/>
					</Grid>
					<Grid item md={6} sm={12} xs={12}>
						<TextField
							id="password-input"
							label="Password"
							type="password"
							required
							helperText={passwordError}
							value={password}
							onChange={handleInput}
							onBlur={() => checkPasswordIsValid()}
							name="password"
							fullWidth
						/>
					</Grid>
					<Grid item md={6} sm={12} xs={12}>
						<TextField
							id="confirm-password-input"
							label="Confirm Password"
							type="password"
							required
							value={passwordConfirmation}
							onChange={handleInput}
							name="passwordConfirmation"
							fullWidth
						/>
					</Grid>
					<Grid item xs={12}>
						<Button type="submit" disabled={submitting} fullWidth color="secondary" variant="contained">
							Register
						</Button>
					</Grid>
					<Grid item xs={12}>
						<Alert header="Something went wrong!" text={warningText} />
					</Grid>
				</Grid>
			</form>
		);
	}

	return (
		<Container maxWidth="xs">
			<Typography align="center" variant="h2" gutterBottom>
				Register
			</Typography>
			{buildRegisterForm()}

			<SpacedDivider className="lg-bottom md-top" />

			<Typography align="center" variant="h5" gutterBottom>
				Sign in with Google or Facebook
			</Typography>
			<LoginSocial
				className={classes.socialWrapper}
				error={err => setWarningText(err)}
				complete={(userName, role) => {
					props.LogIn({
						userName,
						role,
						isLoggingIn: false,
						isAuthenticated: true,
					});
					setRedirectUrl('/');
				}}
				isRegistration={true}
			/>
		</Container>
	);
}

const mapStateToProps = state => ({
	Auth: state.Authentication,
});

const mapDispatchToProps = dispatch => ({
	PushHistory: data => dispatch(push(data)),
	LogIn: data => dispatch(LogIn(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Register);

Register.propTypes = {
	Auth: PropTypes.object,
	PushHistory: PropTypes.func,
	LogIn: PropTypes.func,
};
