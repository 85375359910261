import React from 'react';
import { Container, Typography } from '@material-ui/core';

export default function NotFound() {
	return (
		<Container maxWidth="xs">
			<Typography align="center">404 Page Not Found</Typography>
		</Container>
	);
}
