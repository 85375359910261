import { Divider, withStyles } from '@material-ui/core';

export const SpacedDivider = withStyles({
	root: {
		'&.lg-top': {
			marginTop: 50,
		},
		'&.lg-bottom': {
			marginBottom: 50,
		},
		'&.md-top': {
			marginTop: 30,
		},
		'&.md-bottom': {
			marginBottom: 30,
		},
		'&.sm-top': {
			marginTop: 10,
		},
		'&.sm-bottom': {
			marginBottom: 10,
		},
	},
})(Divider);
