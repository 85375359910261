import React from 'react';
import {
	Container,
	Typography,
	Grid,
	Button,
	Dialog,
	DialogTitle,
	Divider,
	DialogContent,
	TextField,
	DialogActions,
	makeStyles,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Paper,
	TableContainer,
	FormControlLabel,
	Tooltip,
	Switch,
	Box,
	IconButton,
} from '@material-ui/core';
import { LoadingOverlay } from '../../components/Common/LoadingOverlay';
import { Alert } from '@material-ui/lab';
import { isNullOrUndefined, isNullOrWhitespace } from '../../helpers/Utils';
import ProductController from '../../controllers/ProductController';
import ProductRequestController from '../../controllers/ProductRequestController';
import moment from 'moment';
import { Colours } from '../../helpers/Colours';
import { Email, GetApp, Search, ChevronLeft, ChevronRight } from '@material-ui/icons';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

const useStyles = makeStyles(theme => ({
	formControl: {
		width: '100%',
	},
	displayColour: {
		height: 30,
		width: 30,
	},
	section: {
		backgroundColor: 'rgba(0, 0, 0, 0.1)',
		borderRadius: '25px',
		margin: '10px 0px',
		padding: '5px',
	},
	image: {
		'& .image': {
			width: '100%',
			height: '100px',
		},
	},
	variationDisplay: {
		display: 'flex',
		alignItems: 'center',
		flexWrap: 'wrap',
		'& > div': {
			margin: 5,
		},
	},
	childCheckout: {
		display: 'flex',
		flexWrap: 'wrap',
		'& .MuiFormControl-root': {
			width: '75px',
		},
		'& .MuiButtonBase-root': {
			height: '56px',
			fontSize: 'x-large',
		},
		'& .left-btn': {
			borderRight: 0,
			borderTopRightRadius: 0,
			borderBottomRightRadius: 0,
		},
		'& .right-btn': {
			borderLeft: 0,
			borderTopLeftRadius: 0,
			borderBottomLeftRadius: 0,
		},
		'& .center-val': {
			'& .MuiInputBase-root': {
				borderRadius: 0,
			},
		},
	},
	displayContainer: {
		display: 'flex',
		alignItems: 'center',
	},
	tableWrapper: {
		marginBottom: 20,
		overflowY: 'hidden',
		pageBreakAfter: 'always',
	},
	head: {
		backgroundColor: Colours.primary,
		color: theme.palette.common.white,
		'& .headerContainer': {
			display: 'flex',
			justifyContent: 'space-between',
			position: 'relative',
		},
	},
	tableDivide: {
		borderTop: '5px solid rgba(224, 224, 224, 1)',
	},
	searchButton: {
		height: '100%',
		width: '100%',
	},
	internalCheckBox: {
		color: '#ffffff !important',
		position: 'absolute',
		padding: '0px',
		left: '0px',
	},
	submitButton: {
		color: '#fff',
		backgroundColor: Colours.primary,

		'&:hover': {
			backgroundColor: '#cf6626',
		},
	},
	tooltip: {
		fontSize: 14,
		padding: 4,
	},
}));

export default function ProductRequestView() {
	const classes = useStyles();
	const [loading, setLoading] = React.useState(true);
	const [warningText, setWarningText] = React.useState(null);
	const [products, setProducts] = React.useState([]);
	const [productRequests, setProductRequests] = React.useState([]);
	const [selectedState, setSelectedState] = React.useState(0);
	const [searchFilter, setSearchFilter] = React.useState('');
	const [currentSearchFilter, setCurrentSearchFilter] = React.useState('');
	const [excelSheetFile, setExcelSheetFile] = React.useState(null);
	const [selectedProductRequest, setSelectedProductRequest] = React.useState(null);
	const [adding, SetAdding] = React.useState(false);
	const [showAcceptNoEdits, setShowAcceptNoEdits] = React.useState(false);
	const [showEdit, setShowEdit] = React.useState(false);
	const [showDecline, setShowDecline] = React.useState(false);
	const [editValue, setEditValue] = React.useState(null);
	const [mailMergeData, setMailMergeData] = React.useState(null);
	const [sortAscending, setSortAscending] = React.useState(true);
	const [reRender, setReRender] = React.useState(true);

	const [startDate, setStartDate] = React.useState(moment().utc().startOf('day'));
	const [endDate, setEndDate] = React.useState(moment().utc().startOf('day').add({ week: 1 }));

	const loadProductRequests = React.useCallback(
		async (state, searchFilter) => {
			if (!isDateRangeValid()) return;

			setLoading(true);

			const productRequestResponse = await ProductRequestController.getProductRequests(
				state,
				searchFilter,
				startDate.toISOString(),
				endDate.toISOString()
			);

			if (productRequestResponse.hasError) {
				handleError(productRequestResponse.data);
				setLoading(false);
				return;
			}

			setProductRequests(productRequestResponse.data);
			setSelectedState(state);
			setCurrentSearchFilter(searchFilter);
			setLoading(false);
		},
		[productRequests, startDate, endDate]
	);

	function isDateRangeValid() {
		if (moment(startDate).isAfter(moment(endDate))) {
			setWarningText('Start date must come before the end date');
			return false;
		}

		if (moment(endDate).isBefore(moment(startDate))) {
			setWarningText('End date must come after the start date');
			return false;
		}

		if (moment(endDate).isAfter(moment(startDate).add({ month: 1 }))) {
			setWarningText('The selected date range must be no greater than 1 month');
			return false;
		}

		return true;
	}

	async function downloadFile() {
		setLoading(true);

		const excelResponse = await ProductRequestController.getProductRequestExcels(
			selectedState,
			currentSearchFilter,
			startDate.toISOString(),
			endDate.toISOString()
		);
		if (excelResponse.hasError) {
			handleError(excelResponse.data);
			setLoading(false);
			return;
		}
		setExcelSheetFile(excelResponse.data);
		setLoading(false);
	}

	// initialise
	React.useEffect(() => {
		async function init() {
			setLoading(true);
			const productResponse = await ProductController.getProducts();
			if (productResponse.hasError) {
				handleError(productResponse.data.response);
				setLoading(false);
				return;
			}
			setProducts(productResponse.data);

			await loadProductRequests(selectedState, currentSearchFilter);
		}
		init();
	}, [setProducts, setProductRequests]);

	function handelRequestUpdate(productRequest, amount) {
		const amountNum = Number(amount);
		if (amountNum < 0) {
			productRequest.amount = 0;
		} else {
			productRequest.amount = amountNum;
		}
		setProductRequests([...productRequests]);
	}

	function handleError(response) {
		if (isNullOrUndefined(response)) {
			return 'Failed to connect to server. Please check your internet connection';
		}
		if (isNullOrUndefined(response.response) || isNullOrUndefined(response.response.data)) {
			setWarningText(response.message);
			return;
		}
		setWarningText(response.response.data);
	}

	function generateMailMergeData() {
		let maxChildCount = 0;
		const mergeRequests = [];
		for (let i = 0; i < productRequests.length; i++) {
			const request = productRequests[i];
			if (request.children.length > maxChildCount) {
				maxChildCount = request.children.length;
			}
			mergeRequests.push(request);
		}

		let header = `"Order placed date","Requester name","Role","Email","Delivery point","Contact details"`;

		for (let i = 0; i < maxChildCount; i++) {
			header += `,"Child ${i + 1}","Child ${i + 1} School"`;
		}
		header += '\n';

		const rows = mergeRequests
			.map(
				e =>
					`"${e.added}","${e.requesterName}","${e.requesterRole}","${e.email}","${e.deliveryPoint}","${
						e.contactDetails
					}"${e.children.map(r => `,"${r.lastNameOrRef} aged ${r.age}","${r.schoolFullName}"`).join('')}`
			)
			.join('\n');
		setMailMergeData(header + '\n' + rows);
	}

	function moveDates(dir) {
		const dateDiffMs = endDate.diff(startDate, 'milliseconds') * dir;
		setStartDate(e => e.add({ milliseconds: dateDiffMs }));
		setEndDate(e => e.add({ milliseconds: dateDiffMs }));
		setReRender(!reRender);
	}

	function CloseEdit() {
		setShowEdit(false);
		setEditValue(null);
		setWarningText(null);
	}

	async function SubmitEditRequest() {
		SetAdding(true);
		const childrensStocks = editValue.children.map(child => {
			return child.products.map(product => {
				return {
					Amount: product.amount,
					ProductId: product.productId,
					ProductRequestChildId: product.prodcutRequestChildId,
					StockCountId: product.stocksCount.id,
				};
			});
		});
		const stocks = [];
		for (let i = 0; i < childrensStocks.length; i++) {
			stocks.push(...childrensStocks[i]);
		}
		const response = await ProductRequestController.acceptProductRequestWithChanges(editValue.id, stocks);
		if (response.hasError) {
			SetAdding(false);
			handleError(response.data);
			return;
		}
		productRequests.splice(productRequests.indexOf(editValue), 1);
		setSelectedProductRequest(null);
		setProductRequests([...productRequests]);
		setShowEdit(false);
		handleError(null);

		SetAdding(false);
	}

	function ShowAcceptRequest(request) {
		setSelectedProductRequest(request);
		setShowAcceptNoEdits(true);
	}

	function ClosesAcceptRequest() {
		setSelectedProductRequest(null);
		setShowAcceptNoEdits(false);
		setWarningText(null);
	}

	async function SubmitAcceptRequest() {
		SetAdding(true);
		const response = await ProductRequestController.acceptProductRequestWithoutChanges(selectedProductRequest.id);
		if (response.hasError) {
			SetAdding(false);
			handleError(response.data);
			return;
		}
		productRequests.splice(productRequests.indexOf(selectedProductRequest), 1);
		setSelectedProductRequest(null);
		setProductRequests([...productRequests]);
		setShowAcceptNoEdits(false);
		handleError(null);
		SetAdding(false);
	}

	function ShowDeclineRequest(request) {
		setSelectedProductRequest(request);
		setShowDecline(true);
	}

	function CloseDeclineRequest() {
		setSelectedProductRequest(null);
		setShowDecline(false);
		setWarningText(null);
	}

	async function SubmitDeclineRequest() {
		SetAdding(true);
		const response = await ProductRequestController.declineProductRequest(selectedProductRequest.id);
		if (response.hasError) {
			SetAdding(false);
			handleError(response.data);
			return;
		}
		productRequests.splice(productRequests.indexOf(selectedProductRequest), 1);
		setSelectedProductRequest(null);
		setProductRequests([...productRequests]);
		setShowDecline(false);
		handleError(null);

		SetAdding(false);
	}

	async function SubmitForFilledRequest(value) {
		setLoading(true);
		const response = await ProductRequestController.fulfillProductRequest(value.id);
		if (response.hasError) {
			SetAdding(false);
			handleError(response.data);
			return;
		}
		productRequests.splice(productRequests.indexOf(selectedProductRequest), 1);
		setSelectedProductRequest(null);
		setProductRequests([...productRequests]);
		setShowDecline(false);
		handleError(null);

		setLoading(false);
	}

	function buildUIObjectDisplay(variation) {
		if (isNullOrUndefined(variation.variationTypeName)) {
			return variation.name;
		}
		switch (variation.variationTypeName) {
			case 'Colour':
				if (isNullOrUndefined(variation.uiObject) || !variation.uiObject.includes('#')) {
					return variation.name;
				}
				return (
					<div className={classes.displayContainer}>
						<div style={{ backgroundColor: variation.uiObject }} className={classes.displayColour}></div>
						<span> {variation.name}</span>
					</div>
				);
			default:
				return variation.name;
		}
	}

	function buildTable(children, editable) {
		return children.map((child, i) => {
			return [
				<TableRow key={'child-name' + child.id + editable}>
					<TableCell colSpan={4} className={classes.tableDivide}>
						<Typography variant="subtitle1" align="left">
							<b>Last Name or Ref: {child.lastNameOrRef}</b>
						</Typography>
					</TableCell>
				</TableRow>,
				<TableRow key={'child-details-1-' + child.id + editable}>
					<TableCell>Age: {child.age}</TableCell>
					<TableCell>Gender: {child.gender}</TableCell>
					<TableCell>First part of postcode: {child.firstPostcode}</TableCell>
				</TableRow>,
				<TableRow key={'child-details-2-' + child.id + editable}>
					<TableCell>Build: {child.build}</TableCell>
					<TableCell>Height: {child.height}</TableCell>
					<TableCell></TableCell>
				</TableRow>,
				<TableRow key={'child-details-3-' + child.id + editable}>
					<TableCell>School Name: {child.schoolFullName}</TableCell>
					<TableCell>Year: {child.schoolYear}</TableCell>
					<TableCell>House: {child.schoolHouse}</TableCell>
				</TableRow>,
				<TableRow key={'child-details-4-' + child.id + editable}>
					<TableCell>Shoe Size: {child.shoeSize}</TableCell>
					<TableCell>Top Size: {child.topSize}</TableCell>
					<TableCell>Trouser Size: {child.trouserSize}</TableCell>
				</TableRow>,
				<TableRow key={'child-details-5-' + child.id + editable}>
					<TableCell colSpan={3}>
						Addition Info:
						<p>{child.notes}</p>
					</TableCell>
				</TableRow>,
				<TableRow key={'child-productHeader' + child.id + editable}>
					<TableCell colSpan={4}>
						<Typography variant="subtitle1" align="left">
							<b>{`(Child ${i + 1})`} Selected Products</b>
						</Typography>
					</TableCell>
				</TableRow>,
				...child.products
					.sort((a, b) => {
						const productA = products.find(value => {
							return value.id == a.productId;
						});
						const productB = products.find(value => {
							return value.id == b.productId;
						});
						const index = productA.productCategoryName.localeCompare(productB.productCategoryName);
						if (index !== 0) {
							return index;
						}
						return productA.name.localeCompare(productB.name);
					})
					.map((productRequest, cartItemIndex) => {
						const product = products.find(value => {
							return value.id == productRequest.productId;
						});
						return (
							<TableRow key={'child-' + child.id + 'cart-' + cartItemIndex + editable}>
								<TableCell>{product.productCategoryName} </TableCell>
								<TableCell>{product.name} </TableCell>
								<TableCell>
									{' '}
									<div className={classes.variationDisplay}>
										Variation:{' '}
										{productRequest.stocksCount.variations.map((variation, variationIndex) => {
											return (
												<div
													key={
														'child-' +
														child.id +
														'cart-' +
														cartItemIndex +
														'vair-' +
														variationIndex +
														editable
													}
												>
													{buildUIObjectDisplay(variation)}
												</div>
											);
										})}{' '}
									</div>{' '}
								</TableCell>
								{editable ? (
									<TableCell>
										<div className={classes.childCheckout}>
											<Button
												className="left-btn"
												variant="outlined"
												onClick={() => {
													handelRequestUpdate(productRequest, productRequest.amount - 1);
												}}
											>
												-
											</Button>
											<TextField
												id={'child-' + child.id + 'cart-' + cartItemIndex + 'amount'}
												value={productRequest.amount}
												onChange={event => {
													handelRequestUpdate(productRequest, event.target.value);
												}}
												type="number"
												variant="outlined"
												className="center-val"
											/>
											<Button
												className="right-btn"
												variant="outlined"
												onClick={() => {
													handelRequestUpdate(productRequest, productRequest.amount + 1);
												}}
											>
												+
											</Button>
										</div>
									</TableCell>
								) : null}
							</TableRow>
						);
					}),
			];
		});
	}

	function buildNoResults() {
		return (
			<Box
				style={{
					border: '1px solid #ddd',
					borderRadius: 8,
					padding: 24,
				}}
			>
				<Typography align="center" gutterBottom style={{ fontSize: 24, marginBottom: 16 }}>
					No Results found in the current selection
				</Typography>
				<Typography align="center" gutterBottom style={{ fontSize: 20 }}>
					Please enter a start date, end date, and press the search button to load results
				</Typography>
				<Typography align="center" style={{ fontSize: 20 }}>
					The maximum date range is 1 month
				</Typography>
			</Box>
		);
	}

	function buildItems() {
		return productRequests
			.sort((a, b) => {
				if (sortAscending) {
					return new Date(a.added) - new Date(b.added);
				} else {
					return new Date(b.added) - new Date(a.added);
				}
			})
			.map(value => {
				const bottomButtons = [];
				switch (value.state) {
					case 'WaitingApproval':
						bottomButtons.push(
							<TableCell colSpan={4} key={value.id + 'Approve-Edit'}>
								<Grid container spacing={2}>
									<Grid item xs={4}>
										<Button
											onClick={() => ShowAcceptRequest(value)}
											fullWidth
											variant="contained"
											disableElevation
										>
											Approve Without Edits
										</Button>
									</Grid>
									<Grid item xs={4}>
										<Button
											onClick={() => ShowDeclineRequest(value)}
											fullWidth
											variant="contained"
											disableElevation
										>
											Decline
										</Button>
									</Grid>
								</Grid>
							</TableCell>
						);
						break;
					case 'Accepted':
						bottomButtons.push(
							<TableCell key={value.id + 'Forfill'} colSpan={4}>
								<Button
									onClick={() => SubmitForFilledRequest(value)}
									fullWidth
									variant="contained"
									disableElevation
								>
									Mark as fulfilled
								</Button>
							</TableCell>
						);
						break;
					default:
						break;
				}
				return (
					<TableContainer key={value.id} component={Paper} className={classes.tableWrapper}>
						<Table size="small">
							<TableBody>
								<TableRow key={value.id + 1}>
									<TableCell colSpan={4} className={classes.head}>
										<div className="headerContainer">
											<Typography variant="subtitle1" align="left">
												Requested by: <b>{value.requesterName}</b>
											</Typography>
											<Typography variant="subtitle1" align="center" className="">
												Requested on: <b>{moment(value.added).format('Do/MMM/YYYY')}</b>
											</Typography>
											<Typography variant="subtitle1" align="right">
												Ref: <b>{value.id}</b>
											</Typography>
										</div>
									</TableCell>
								</TableRow>
								<TableRow key={value.id + 2}>
									<TableCell>Email: {value.email}</TableCell>
									<TableCell>Role: {value.requesterRole}</TableCell>
									<TableCell>Requestor Agency Workplace: {value.requesterCenter}</TableCell>
								</TableRow>
								<TableRow key={value.id + 12}>
									<TableCell colSpan={2}>Mobile Number: {value.requesterMobileNumber}</TableCell>
									<TableCell colSpan={2}>
										Request Reason: {getRequestReasonText(value.requestReason)}
									</TableCell>
								</TableRow>
								<TableRow key={value.id + 'Urgent'}>
									<TableCell colSpan={2}>
										<span>
											Requested For:
											{value.isUrgent ? moment(value.requestedDate).format('Do/MMM/YYYY') : 'N/A'}
										</span>
									</TableCell>
									<TableCell colSpan={2}>
										<span>Urgency: {value.additionalInfoAdminText ?? 'Standard'}</span>
									</TableCell>
								</TableRow>
								<TableRow key={value.id + 3}>
									<TableCell colSpan={2}>
										<p>Delivery Point:</p>
										<p>{value.deliveryPoint}</p>
									</TableCell>
									<TableCell colSpan={2}>
										<p>Contact Details:</p>
										<p>{value.contactDetails}</p>
									</TableCell>
								</TableRow>
								{buildTable(value.children, false)}
								<TableRow key={value.id + 4}>{bottomButtons}</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
				);
			});
	}

	function getRequestReasonText(reason) {
		if (isNullOrUndefined(reason) || reason === 0) {
			return 'Not Provided';
		}
		switch (reason) {
			case 1:
				return 'To reduce the financial burden of purchasing school uniform';
			case 2:
				return 'To reduce the financial burden of purchasing school uniform';
			default:
				return 'Not Set';
		}
	}

	function buildAcceptNoEditsModal() {
		if (isNullOrUndefined(selectedProductRequest)) {
			return null;
		}
		return (
			<Dialog
				open={showAcceptNoEdits}
				onClose={() => {
					ClosesAcceptRequest();
				}}
			>
				<DialogTitle>Accepting with no Edits</DialogTitle>
				<Divider />
				<DialogContent>
					<div>
						Are you sure that you want to accept the request for {selectedProductRequest?.requesterName}?{' '}
					</div>
					{buildWarning()}
				</DialogContent>
				<Divider />
				<DialogActions>
					<Button disabled={adding} onClick={SubmitAcceptRequest}>
						{adding ? 'Saving' : 'Confirm'}
					</Button>
					<Button
						onClick={() => {
							ClosesAcceptRequest();
						}}
					>
						Close
					</Button>
				</DialogActions>
			</Dialog>
		);
	}

	function buildEditModal() {
		if (isNullOrUndefined(editValue)) {
			return null;
		}
		return (
			<Dialog
				open={showEdit}
				onClose={() => {
					CloseEdit();
				}}
				fullWidth={true}
				maxWidth="md"
			>
				<DialogTitle>Accepting with Edits</DialogTitle>
				<Divider />
				<DialogContent>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell></TableCell>
								<TableCell></TableCell>
								<TableCell></TableCell>
								<TableCell></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>{buildTable(editValue.children, true)}</TableBody>
					</Table>
					{buildWarning()}
				</DialogContent>
				<Divider />
				<DialogActions>
					<Button disabled={adding} onClick={SubmitEditRequest}>
						{adding ? 'Saving' : 'Accept'}
					</Button>
					<Button
						onClick={() => {
							CloseEdit();
						}}
					>
						Close
					</Button>
				</DialogActions>
			</Dialog>
		);
	}

	function buildDeclineModal() {
		if (isNullOrUndefined(selectedProductRequest)) {
			return null;
		}
		return (
			<Dialog
				open={showDecline}
				onClose={() => {
					CloseDeclineRequest();
				}}
			>
				<DialogTitle>Decline</DialogTitle>
				<Divider />
				<DialogContent>
					<div>
						Are you sure that you want to decline the request for {selectedProductRequest?.requesterName}?{' '}
					</div>
					{buildWarning()}
				</DialogContent>
				<Divider />
				<DialogActions>
					<Button disabled={adding} onClick={SubmitDeclineRequest}>
						{adding ? 'Saving' : 'Decline'}
					</Button>
					<Button
						onClick={() => {
							CloseDeclineRequest();
						}}
					>
						Close
					</Button>
				</DialogActions>
			</Dialog>
		);
	}

	function base64ToArrayBuffer(base64) {
		var binaryString = window.atob(base64);
		var binaryLen = binaryString.length;
		var bytes = new Uint8Array(binaryLen);
		for (var i = 0; i < binaryLen; i++) {
			var ascii = binaryString.charCodeAt(i);
			bytes[i] = ascii;
		}
		return bytes;
	}

	function buildDownloadFileModal() {
		if (isNullOrUndefined(excelSheetFile)) {
			return null;
		}
		const blob = new Blob([base64ToArrayBuffer(excelSheetFile)], {
			type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		});
		const link = window.URL.createObjectURL(blob);
		return (
			<Dialog
				open={true}
				onClose={() => {
					setExcelSheetFile(null);
				}}
			>
				<DialogTitle>Products</DialogTitle>
				<DialogContent>
					<Typography gutterBottom>Click download to receive a spreadsheet of requested products.</Typography>
					{buildWarning()}
				</DialogContent>
				<DialogActions
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						padding: 16,
					}}
				>
					<Button
						onClick={() => {
							setExcelSheetFile(null);
						}}
					>
						Close
					</Button>
					<Button
						disabled={adding}
						href={link}
						target="_blank"
						rel="noopener noreferrer"
						variant="contained"
						className={classes.submitButton}
						download={
							(isNullOrWhitespace(searchFilter) ? '' : searchFilter + '-') +
							`${moment().format('DD-MM-YYYY')}.xlsx`
						}
					>
						Download
					</Button>
				</DialogActions>
			</Dialog>
		);
	}

	function buildDownloadMailMergeModal() {
		if (isNullOrUndefined(mailMergeData)) {
			return null;
		}
		const blob = new Blob([mailMergeData], { type: 'text/csv' });
		const link = window.URL.createObjectURL(blob);
		return (
			<Dialog
				open={true}
				onClose={() => {
					setMailMergeData(null);
				}}
			>
				<DialogTitle>Mail Merge</DialogTitle>
				<DialogContent>
					<Typography gutterBottom>Click download to receive a mail merge spreadsheet.</Typography>
					{buildWarning()}
				</DialogContent>
				<DialogActions
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						padding: 16,
					}}
				>
					<Button
						onClick={() => {
							setMailMergeData(null);
						}}
					>
						Close
					</Button>
					<Button
						disabled={adding}
						href={link}
						target="_blank"
						rel="noopener noreferrer"
						variant="contained"
						className={classes.submitButton}
						download={
							(isNullOrWhitespace(searchFilter) ? '' : searchFilter + '-') +
							`MailMerge-${moment().format('DD-MM-YYYY')}.csv`
						}
					>
						Download
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
	function buildWarning() {
		if (isNullOrUndefined(warningText)) return null;
		return <Alert severity="warning">{warningText}</Alert>;
	}

	return (
		<Container style={{}}>
			<LoadingOverlay loading={loading} />
			<Typography variant="h3" align="center" gutterBottom>
				Product Requests
			</Typography>
			{buildEditModal()}
			{buildDeclineModal()}
			{buildAcceptNoEditsModal()}
			{buildDownloadFileModal()}
			{buildDownloadMailMergeModal()}
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<FormControl variant="filled" className={classes.formControl}>
						<InputLabel id="Request-State-label">Request State *</InputLabel>
						<Select
							id="Request-State"
							labelId="Request-State-label"
							value={selectedState}
							onChange={event => setSelectedState(event.target.value)}
							name="RequestState"
							variant="outlined"
							fullWidth={true}
						>
							<MenuItem value={0}>WaitingApproval</MenuItem>
							<MenuItem value={1}>Accepted</MenuItem>
							<MenuItem value={2}>Fulfilled</MenuItem>
							<MenuItem value={3}>Declined</MenuItem>
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={6}>
					<TextField
						value={searchFilter}
						onChange={e => {
							setSearchFilter(e.target.value);
						}}
						variant="outlined"
						label="Search"
						fullWidth
					/>
				</Grid>
				<Grid item xs={3} style={{ display: 'flex', alignItems: 'center' }}>
					<IconButton style={{ height: 42, width: 42, marginRight: 8 }} onClick={() => moveDates(-1)}>
						<ChevronLeft />
					</IconButton>
					<MuiPickersUtilsProvider utils={MomentUtils}>
						<DateTimePicker
							value={startDate}
							onChange={date => {
								setWarningText(null);
								setStartDate(date);
							}}
							variant="inline"
							inputVariant="outlined"
							label="Start Date"
							fullWidth
							format="DD-MMM-YYYY - HH:mm"
						/>
					</MuiPickersUtilsProvider>
				</Grid>
				<Grid item xs={3} style={{ display: 'flex', alignItems: 'center' }}>
					<MuiPickersUtilsProvider utils={MomentUtils}>
						<DateTimePicker
							value={endDate}
							onChange={date => {
								setWarningText(null);
								setEndDate(date);
							}}
							variant="inline"
							inputVariant="outlined"
							label="End Date"
							fullWidth
							format="DD-MMM-YYYY - HH:mm"
						/>
					</MuiPickersUtilsProvider>
					<IconButton style={{ height: 42, width: 42, marginLeft: 8 }} onClick={() => moveDates(1)}>
						<ChevronRight />
					</IconButton>
				</Grid>
				<Grid item xs={1}>
					<Tooltip title={<div className={classes.tooltip}>Search</div>}>
						<Button
							variant="contained"
							onClick={() => {
								loadProductRequests(selectedState, searchFilter);
							}}
							className={classes.searchButton}
						>
							<Search />
						</Button>
					</Tooltip>
				</Grid>
				<Grid item xs={1}>
					<Tooltip title={<div className={classes.tooltip}>Generate Product Spreadsheet</div>}>
						<Button variant="contained" onClick={downloadFile} className={classes.searchButton}>
							<GetApp />
						</Button>
					</Tooltip>
				</Grid>
				<Grid item xs={1}>
					<Tooltip title={<div className={classes.tooltip}>Generate Mail Merge Spreadsheet</div>}>
						<Button variant="contained" onClick={generateMailMergeData} className={classes.searchButton}>
							<Email />
						</Button>
					</Tooltip>
				</Grid>
				{warningText ? (
					<Grid item xs={12}>
						{buildWarning()}
					</Grid>
				) : null}
				<Grid item xs={12}>
					<FormControlLabel
						style={{ margin: 0 }}
						control={<Switch checked={sortAscending} onChange={e => setSortAscending(e.target.checked)} />}
						label="Sort by Ascending Order"
					/>
				</Grid>
				<Grid item xs={12}>
					{productRequests.length === 0 ? buildNoResults() : buildItems()}
				</Grid>
			</Grid>
		</Container>
	);
}

ProductRequestView.propTypes = {};
