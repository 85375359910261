import Axios from 'axios';
import UserController from './UserController';
import { APIRoute, APIPath } from '../helpers/Constants';
import { generateResponse } from '../helpers/Utils';

const HomePageTextController = {
	getHomePageText,
	editHomePageText,
};

async function getHomePageText() {
	return Axios.get(APIRoute + APIPath.GET_HOME_PAGE_TEXT)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function editHomePageText(schoolName, buttonText) {
	const userHeaders = await UserController.getUserHeaders();
	const data = {
		Text: schoolName,
		ButtonText: buttonText,
	};
	return Axios.post(APIRoute + APIPath.EDIT_HOME_PAGE_TEXT, data, userHeaders)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

export default HomePageTextController;
