const APIDomain = 'https://uniformexchangeform-api.azurewebsites.net';
// const APIDomain = 'https://localhost:5001';
export const APIRoute = APIDomain + '/api/';

export const SIGNALR_Paths = {
	Notifications: APIDomain + '/webhooks/notification',
};

export const versionName = 'WebApp';
export const versionNum = 25;

export const ClaimType = {
	Role: 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role',
	Name: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name',
	UserName: 'username',
};

// API paths
export const APIPath = {
	// Users
	PASSWORD_REQUIREMENTS: 'User/PasswordRequirements',
	LOGIN: 'User/LogIn',
	LOGIN_PASSWORD_RESET: 'User/LogInWithPasswordReset',
	LOGIN_GOOGLE: 'User/LoginWithGoogle',
	LOGOUT: 'User/LogOut',
	REGISTER: 'User/Register',
	REGISTER_GOOGLE: 'User/RegisterWithGoogle',
	EMAIL_CONFIRMATION: 'User/EmailConfirmation',
	FORGOT_PASSWORD: 'User/ForgotPassword',
	RESET_PASSWORD: 'User/ResetPassword',
	CHANGE_PASSWORD: 'User/ChangePassword',
	GET_USER_INFO: 'User/GetUsersInfo',
	REQUEST_EMAIL_CHANGE: 'User/RequestChangeEmail',
	CONFIRM_EMAIL_CHANGE: 'User/ConfirmChangeEmail',
	REQUEST_EMAIL_CONFIRMATION: 'User/RequestEmailConfirmation',
	FINISH_EXTERNAL_LOGIN: 'User/CheckLocalUser',
	FINISH_EXTERNAL_SIGNUP: 'User/CreateLocalUser',
	TWO_FACTOR_GET_KEY: 'User/GetAuthenticatorCode',
	TWO_FACTOR_ADD: 'User/AddAuthentication',
	TWO_FACTOR_REMOVE: 'User/RemoveAuthentication',
	ADD_DEVICE: 'User/AddDevice',
	UPDATE_USER_ALTERNATE_LOGINS: 'User/UpdateUsersAlternateLogins',
	VERIFY_TWO_FACTOR: 'User/VerifyTwoFactor',
	GET_MOTD: 'User/GetMOTD',
	SEEN_MOTD: 'User/SeenMOTD',

	// Notifications
	GET_UNREAD_NOTIF_COUNT: 'Notifications/GetUnreadCount',
	GET_TOP_NOTIFS: 'Notifications/GetTop',
	GET_ALL_NOTIFS: 'Notifications/GetAll',
	MARK_NOTIF_AS_READ: 'Notifications/MarkAsRead/',
	MARK_ALL_NOTIFS_AS_READ: 'Notifications/MarkAllAsRead',

	// File
	FILE_UPlOAD: 'File/Upload',
	FILE_GET_USERS: 'File/GetUsersFiles',

	// Version
	GET_VERSION: 'Version',

	//School
	GET_SCHOOLS: 'School',
	ADD_SCHOOL: 'School',
	EDIT_SCHOOL: 'School/Edit/',
	DELETE_SCHOOL: 'School/',

	//DeliveryPoint
	GET_DELIVERYPOINTS: 'DeliveryPoint',
	ADD_DELIVERYPOINT: 'DeliveryPoint',
	EDIT_DELIVERYPOINT: 'DeliveryPoint/Edit/',
	DELETE_DELIVERYPOINT: 'DeliveryPoint/',

	//VariationType
	GET_VARIATIONTYPES: 'VariationType',
	ADD_VARIATIONTYPE: 'VariationType',
	EDIT_VARIATIONTYPE: 'VariationType/Edit/',
	DELETE_VARIATIONTYPE: 'VariationType/',

	//Variation
	GET_VARIATION: 'Variation',
	ADD_VARIATION: 'Variation',
	EDIT_VARIATION: 'Variation/Edit/',
	DELETE_VARIATION: 'Variation/',

	//Product
	GET_SCHOOLS_PRODUCTS: 'Product/BySchool/',
	GET_PRODUCTS: 'Product',
	GET_PRODUCT: 'Product/Get/',
	ADD_PRODUCT: 'Product/Add',
	EDIT_PRODUCT: 'Product/Edit',
	DELETE_PRODUCT: 'Product/',

	//ProductRequest
	CREATE_PRODUCT_REQUEST: 'ProductRequest/Create',
	GET_PRODUCT_REQUESTS: 'ProductRequest',
	GET_PRODUCT_EXCEL_REQUESTS: 'ProductRequest/GenerateExcel',
	GET_FILTERED_PRODUCT_REQUESTS: 'ProductRequest/FilteredRequests',
	ACCEPT_PRODUCT_REQUEST_NO_CHANGES: 'ProductRequest/AcceptNoChanges/',
	ACCEPT_PRODUCT_REQUEST_WITH_CHANGES: 'ProductRequest/AcceptWithChanges',
	DECLINE_PRODUCT_REQUEST: 'ProductRequest/Decline/',
	FULFILL_PRODUCT_REQUEST: 'ProductRequest/Fulfill/',

	//ProductCategory
	GET_PRODUCT_CATEGORY: 'ProductCatagories',
	ADD_PRODUCT_CATEGORY: 'ProductCatagories',
	EDIT_PRODUCT_CATEGORY: 'ProductCatagories/Edit/',
	DELETE_PRODUCT_CATEGORY: 'ProductCatagories/',

	//HomePageText
	GET_HOME_PAGE_TEXT: 'HomePageText',
	EDIT_HOME_PAGE_TEXT: 'HomePageText',

	//EmailText
	GET_EMAIL_TEXT: 'EmailText',
	EDIT_EMAIL_TEXT: 'EmailText',

	//Additional Info
	ADDITIONAL_INFO_SETTINGS: 'AdditionalInfo/AdditionalInfoSettings',
	ADMIN_ADDITIONAL_INFO_SETTINGS: 'AdditionalInfo/Admin/AdditionalInfoSettings',

	//Additional Info
	ADDITIONAL_INFO_SETTINGS: 'AdditionalInfo/AdditionalInfoSettings',
	ADMIN_ADDITIONAL_INFO_SETTINGS: 'AdditionalInfo/Admin/AdditionalInfoSettings',

	// Settings
	GET_SETTINGS: 'Settings',
	UPDATE_SETTINGS: 'Settings',
};

export const Role = {
	Admin: 'Admin',
};
