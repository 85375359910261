import Axios from 'axios';
import { APIRoute, APIPath } from '../helpers/Constants';
import { generateResponse, isNullOrUndefined } from '../helpers/Utils';
import { IndexedDB } from '../helpers/IndexedDB';

const UserController = {
	getUserHeaders,
	getToken,
	register,
	registerGoogle,
	login,
	loginWithPasswordReset,
	loginGoogle,
	logout,
	finishExternalLogin,
	getPasswordRequirements,
	forgotPassword,
	resetPassword,
	changePassword,
	addDeviceToken,
	getMOTD,
	seenMOTD,
	requestEmailConfirmation,
	checkForEmailConfirmation,
	requestEmailChange,
	confirmEmailChange,
	getUserInfo,
	getAuthenticatorCode,
	addTwoFactor,
	removeTwoFactor,
	verifyTwoFactor,
	fetchCachedUserData,
};

async function getUserHeaders(contentType = null) {
	return await IndexedDB.fetch('authToken')
		.then(response => {
			if (isNullOrUndefined(contentType)) {
				return {
					headers: { Authorization: 'Bearer ' + response.data?.content },
				};
			} else {
				return {
					headers: {
						'Content-Type': contentType,
						Authorization: 'Bearer ' + response.data?.content,
					},
				};
			}
		})
		.catch(() => {
			return null;
		});
}

async function getToken() {
	return await IndexedDB.fetch('authToken')
		.then(response => {
			return response.data?.content;
		})
		.catch(() => {
			return null;
		});
}

async function register(firstName, lastName, email, password, passwordConfirmation) {
	const registerModel = {
		firstName,
		lastName,
		email,
		password,
		passwordConfirmation,
	};
	return Axios.post(APIRoute + APIPath.REGISTER, registerModel)
		.then(async response => {
			if (response.status === 200) {
				return await cacheUserData(response.data).then(cacheResponse => {
					const output = cacheResponse.hasError ? cacheResponse.data : response.data;
					return generateResponse(cacheResponse.hasError, output);
				});
			} else {
				return generateResponse(true, response.data);
			}
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function registerGoogle(jwt) {
	const registerModel = {
		jwt,
	};
	return Axios.post(APIRoute + APIPath.REGISTER_GOOGLE, registerModel)
		.then(async response => {
			if (response.status === 200) {
				return await cacheUserData(response.data).then(cacheResponse => {
					const output = cacheResponse.hasError ? cacheResponse.data : response.data;
					return generateResponse(cacheResponse.hasError, output);
				});
			} else {
				return generateResponse(true, response.data);
			}
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function login(email, password, twoFactorToken = null) {
	const loginModel = {
		email,
		password,
		twoFactorToken,
	};
	return Axios.post(APIRoute + APIPath.LOGIN, loginModel)
		.then(async response => {
			if (response.status === 200) {
				return await cacheUserData(response.data).then(cacheResponse => {
					const output = cacheResponse.hasError ? cacheResponse.data : response.data;
					return generateResponse(cacheResponse.hasError, output);
				});
			} else {
				return generateResponse(true, response.data);
			}
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function loginGoogle(jwt, twoFactorToken = null) {
	const loginModel = {
		jwt,
		twoFactorToken,
	};
	return Axios.post(APIRoute + APIPath.LOGIN_GOOGLE, loginModel)
		.then(async response => {
			if (response.status === 200) {
				return await cacheUserData(response.data).then(cacheResponse => {
					const output = cacheResponse.hasError ? cacheResponse.data : response.data;
					return generateResponse(cacheResponse.hasError, output);
				});
			} else {
				return generateResponse(true, response.data);
			}
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function loginWithPasswordReset(email, password, twoFactorToken, newPassword, newConfirmationPassword) {
	const resetModel = {
		email,
		password,
		twoFactorToken,
		newPassword,
		newConfirmationPassword,
	};
	return Axios.post(APIRoute + APIPath.LOGIN_PASSWORD_RESET, resetModel)
		.then(async response => {
			if (response.status === 200) {
				return await cacheUserData(response.data).then(cacheResponse => {
					const output = cacheResponse.hasError ? cacheResponse.data : response.data;
					return generateResponse(cacheResponse.hasError, output);
				});
			} else {
				return generateResponse(true, response.data);
			}
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function logout() {
	const userHeaders = await getUserHeaders();
	return Axios.post(APIRoute + APIPath.LOGOUT, null, userHeaders)
		.then(async () => {
			return await clearCachedUserData();
		})
		.catch(async reason => {
			await clearCachedUserData();
			return generateResponse(true, reason);
		});
}

async function finishExternalLogin() {
	const userHeaders = await getUserHeaders();
	return Axios.get(APIRoute + APIPath.FINISH_EXTERNAL_LOGIN, userHeaders)
		.then(response => {
			if (!response.data.exists) {
				return generateResponse('Unable to find account, please register first');
			} else if (!response.data) {
				return generateResponse(true, 'Failed to connect to service');
			}
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function getPasswordRequirements() {
	return Axios.get(APIRoute + APIPath.PASSWORD_REQUIREMENTS)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function forgotPassword(email) {
	const forgotModel = { email };
	const userHeaders = await getUserHeaders();
	return Axios.post(APIRoute + APIPath.FORGOT_PASSWORD, forgotModel, userHeaders)
		.then(() => {
			return generateResponse(false, null);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function resetPassword(userId, code, password, passwordConfirmation) {
	const resetModel = {
		userId,
		code,
		password,
		passwordConfirmation,
	};
	const userHeaders = await getUserHeaders();
	return Axios.post(APIRoute + APIPath.RESET_PASSWORD, resetModel, userHeaders)
		.then(() => {
			return generateResponse(false, null);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function changePassword(currentPassword, newPassword, newPasswordConfirmation) {
	const changeModel = {
		currentPassword,
		newPassword,
		newPasswordConfirmation,
	};
	const userHeaders = await getUserHeaders();
	return Axios.post(APIRoute + APIPath.CHANGE_PASSWORD, changeModel, userHeaders)
		.then(() => {
			return generateResponse(false, null);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function addDeviceToken(token) {
	const userHeaders = await getUserHeaders();
	return Axios.post(APIRoute + APIPath.ADD_DEVICE + '?deviceToken=' + token, null, userHeaders)
		.then(() => {
			console.log('Device token updated.');
			return true;
		})
		.catch(reason => {
			console.log('Device token update failed!', reason);
			return false;
		});
}

async function getMOTD() {
	const userHeaders = await getUserHeaders();
	return Axios.get(APIRoute + APIPath.GET_MOTD, userHeaders)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function seenMOTD() {
	const userHeaders = await getUserHeaders();
	return Axios.get(APIRoute + APIPath.SEEN_MOTD, userHeaders)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function requestEmailConfirmation() {
	const userHeaders = await getUserHeaders();
	return Axios.post(APIRoute + APIPath.REQUEST_EMAIL_CONFIRMATION, null, userHeaders)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function checkForEmailConfirmation(userId, code) {
	const data = { userId, code };
	return Axios.post(APIRoute + APIPath.EMAIL_CONFIRMATION, data)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function requestEmailChange(newEmail, password) {
	const data = { newEmail, password };
	const userHeaders = await getUserHeaders();
	return Axios.post(APIRoute + APIPath.REQUEST_EMAIL_CHANGE, data, userHeaders)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function confirmEmailChange(id) {
	const data = { id };
	const userHeaders = await getUserHeaders();
	return Axios.post(APIRoute + APIPath.CONFIRM_EMAIL_CHANGE, data, userHeaders)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function getUserInfo() {
	const userHeaders = await getUserHeaders();
	return Axios.get(APIRoute + APIPath.GET_USER_INFO, userHeaders)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function getAuthenticatorCode() {
	const userHeaders = await getUserHeaders();
	return Axios.get(APIRoute + APIPath.TWO_FACTOR_GET_KEY, userHeaders)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function addTwoFactor(secret, token) {
	const addModel = { secret, token };
	const userHeaders = await getUserHeaders();
	return Axios.post(APIRoute + APIPath.TWO_FACTOR_ADD, addModel, userHeaders)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function removeTwoFactor(token) {
	const userHeaders = await getUserHeaders();
	return Axios.post(APIRoute + APIPath.TWO_FACTOR_REMOVE, { token }, userHeaders)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function verifyTwoFactor(twoFactorToken) {
	const data = { twoFactorToken };
	const userHeaders = await getUserHeaders();
	return Axios.post(APIRoute + APIPath.VERIFY_TWO_FACTOR, data, userHeaders)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function cacheUserData(userData) {
	const { userName, role, token } = userData;
	try {
		await IndexedDB.add({ name: 'userName', content: userName }).then(response => {
			if (response.hasError) {
				throw response;
			}
		});
		await IndexedDB.add({ name: 'role', content: role }).then(response => {
			if (response.hasError) {
				throw response;
			}
		});
		await IndexedDB.add({ name: 'authToken', content: token }).then(response => {
			if (response.hasError) {
				throw response;
			}
		});
		return generateResponse(false, userData);
	} catch (e) {
		return e;
	}
}

async function fetchCachedUserData() {
	try {
		var userName, role, token;
		await IndexedDB.fetch('userName').then(response => {
			if (response.hasError) {
				throw response;
			}
			userName = response.data?.content;
		});
		await IndexedDB.fetch('role').then(response => {
			if (response.hasError) {
				throw response;
			}
			role = response.data?.content;
		});
		await IndexedDB.fetch('authToken').then(response => {
			if (response.hasError) {
				throw response;
			}
			token = response.data?.content;
		});
		return generateResponse(false, { userName, role, token });
	} catch (e) {
		return e;
	}
}

async function clearCachedUserData() {
	try {
		await IndexedDB.remove('userName').then(response => {
			if (response.hasError) {
				throw response;
			}
		});
		await IndexedDB.remove('role').then(response => {
			if (response.hasError) {
				throw response;
			}
		});
		await IndexedDB.remove('authToken').then(response => {
			if (response.hasError) {
				throw response;
			}
		});
		return generateResponse(false, null);
	} catch (e) {
		return e;
	}
}

export default UserController;
