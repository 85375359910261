import React from 'react';
import { Container, Typography } from '@material-ui/core';

export default function RequestSent() {
	return (
		<Container>
			<Typography variant="h2" align="center" gutterBottom>
				Thank you your request has been submitted
			</Typography>
			<Typography variant="h6" align="center" gutterBottom>
				We will email you when your request is ready, please allow 2 weeks.
			</Typography>
		</Container>
	);
}
