import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { LogOut } from '../../stores/Actions/Authentication';
import UserController from '../../controllers/UserController';

function Logout(props) {
	UserController.logout();
	window.gapi.load('auth2', () => {
		const auth2 = window.gapi.auth2.init({
			client_id: '523243064179-givrom5hco81i6204ijiipssme5cnc5f.apps.googleusercontent.com',
		});
		if (auth2.isSignedIn.get()) {
			auth2.signOut();
		}
	});

	props.LogOut();
	props.PushHistory('/');
	return null;
}

const mapStateToProps = state => ({
	Auth: state.Authentication,
});

const mapDispatchToProps = dispatch => ({
	PushHistory: data => dispatch(push(data)),
	LogOut: () => dispatch(LogOut()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Logout);

Logout.propTypes = {
	Auth: PropTypes.object,
	PushHistory: PropTypes.func,
	LogOut: PropTypes.func,
};
