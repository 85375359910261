import React from 'react';
import PropTypes from 'prop-types';
import VariationController from '../../controllers/VariationController';
import SchoolsController from '../../controllers/SchoolController';
import ProductCategoryController from '../../controllers/ProductCategoryController';
import {
	Container,
	Typography,
	Grid,
	TextField,
	Select,
	MenuItem,
	InputLabel,
	FormControl,
	makeStyles,
	FormControlLabel,
	Button,
	Divider,
	Checkbox,
} from '@material-ui/core';
import { LoadingOverlay } from '../../components/Common/LoadingOverlay';
import { Alert } from '@material-ui/lab';
import { isNullOrUndefined, getQueryStringParam } from '../../helpers/Utils';
import ProductController from '../../controllers/ProductController';
import { PhotoCamera, Add } from '@material-ui/icons';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(() => ({
	formControl: {
		width: '100%',
	},
	displayColour: {
		height: '50px',
		width: '50px',
		border: 'solid 1px black',
		borderRadius: '5px',
	},
	inlineTableHeader: {
		display: 'flex',
	},
	addImage: {
		width: 130,
		position: 'relative',
		display: 'flex',
		alignItems: 'center',

		'& .icon': {
			height: 30,
			width: 30,
		},
		'& input': {
			height: 30,
			width: '100%',
			position: 'absolute',
			opacity: 0,
			cursor: 'pointer',
		},

		'& span': {
			paddingLeft: '5px',
			fontWeight: 'bold',
		},
	},
	imageElement: {
		padding: '10px',
		border: 'solid 1px black',
		borderRadius: '5px',
		position: 'relative',
		backgroundColor: '#FFFFFF',
		'& img': {
			maxWidth: '100%',
			marginBottom: '10px',
		},
		'& .MuiFormControl-root': {
			marginTop: '5px',
			marginBottom: '5px',
		},
	},
	imageDeleteButton: {
		top: -15,
		right: -15,
		position: 'absolute',
		height: 30,
		width: 30,
		minWidth: 30,
		color: '#FF0000',
		backgroundColor: '#FFFFFF',
		borderRadius: '30px',
		border: 'solid 2px black',
		padding: '0px',
	},
	section: {
		backgroundColor: 'rgba(0, 0, 0, 0.1)',
		borderRadius: '25px',
		margin: '10px 0px',
	},
}));

export default function ProductEditor(props) {
	const [loading, setLoading] = React.useState(true);
	const [warningText, setWarningText] = React.useState(null);
	const [variations, setVariations] = React.useState([]);
	const [ProductCategorys, setProductCategories] = React.useState([]);
	const [schools, setSchools] = React.useState([]);

	const [editProductId, setEditProductId] = React.useState(null);

	const [defaultStockCount, setDefaultStockCount] = React.useState(0);

	const [name, setName] = React.useState('');
	const [selectedProductCategory, setSelectedProductCategory] = React.useState('');
	const [isAvailable, setIsAvailable] = React.useState(true);
	const [selectedSchools, setSelectedSchools] = React.useState([]);
	const [images, setImages] = React.useState([]);

	const [stocks, setStocks] = React.useState([]);

	const [adding, SetAdding] = React.useState(false);

	const [, setImageIndex] = React.useState(-1);

	const classes = useStyles();

	// initialise
	React.useEffect(() => {
		async function init() {
			const variationResponse = await VariationController.getVariations();
			if (variationResponse.hasError) {
				HandleError(variationResponse.data);
				setLoading(false);
				return;
			}
			setVariations(variationResponse.data);

			const productCategoryResponse = await ProductCategoryController.getProductCategories();
			if (productCategoryResponse.hasError) {
				HandleError(productCategoryResponse.data);
				setLoading(false);
				return;
			}
			setProductCategories(productCategoryResponse.data);

			const schoolResponse = await SchoolsController.getSchools();
			if (schoolResponse.hasError) {
				HandleError(schoolResponse.data);
				setLoading(false);
				return;
			}
			setSchools(schoolResponse.data);
			//Edit check
			const productId = getQueryStringParam('productId', props.location);
			if (!isNullOrUndefined(productId) && productId.length !== 0) {
				const productResponse = await ProductController.getProduct(productId);
				if (productResponse.hasError) {
					HandleError(productResponse.data);
					setLoading(false);
					return;
				}
				setEditProductId(productId);
				setName(productResponse.data.name);
				setSelectedProductCategory(productResponse.data.productCategoryId);
				setIsAvailable(productResponse.data.isAvailable);
				//TODO Map data correctly
				setSelectedSchools(productResponse.data.schoolIds);
				setImages(
					productResponse.data.images.map(value => {
						return {
							id: value.id,
							variationIds: value.variations.map(variation => {
								return variation.id;
							}),
							imagePath: value.imagePath,
							orderIndex: value.orderIndex,
						};
					})
				);

				setStocks(
					productResponse.data.stocks.map(value => {
						return {
							variationIds: value.variations.map(variation => {
								return variation.id;
							}),
							stockCount: value.amount,
							isEdited: false,
							originalStock: value.amount,
							id: value.id,
						};
					})
				);
			}

			setLoading(false);
		}
		init();
	}, []);

	async function SubmitChanges() {
		SetAdding(true);
		if (name.trim().length === 0) {
			SetAdding(false);
			setWarningText('Please set the product name');
			return;
		}
		if (schools.length === 0) {
			SetAdding(false);
			setWarningText('Please select at least one school');
			return;
		}
		if (
			stocks.some(value => {
				return value.variationIds.length === 0;
			})
		) {
			SetAdding(false);
			setWarningText('All Stocks must have at least one selected options');
			return;
		}
		const postImages = images.map(value => {
			return {
				Id: value.id,
				File: value.file,
				OrderIndex: value.orderIndex,
				VariationIds: value.variationIds,
			};
		});

		const postStocks = stocks.map(value => {
			return {
				VariationIds: value.variationIds,
				StockCountId: value.id,
				StockCount: value.stockCount,
				IsEdited: value.isEdited,
				OriginalStock: value.originalStock,
			};
		});

		let response;

		if (isNullOrUndefined(editProductId)) {
			response = await ProductController.addProduct(
				name,
				selectedProductCategory,
				isAvailable,
				selectedSchools,
				postImages,
				postStocks
			);
		} else {
			response = await ProductController.editProduct(
				editProductId,
				name,
				selectedProductCategory,
				isAvailable,
				selectedSchools,
				postImages,
				postStocks
			);
		}

		if (response.hasError) {
			SetAdding(false);
			HandleError(response.data);
			return;
		}

		SetAdding(false);
		props.history.push('/ProductList?Id=' + response.data);
	}

	function HandleError(response) {
		if (isNullOrUndefined(response)) {
			return 'Failed to connect to server. Please check your internet connection';
		}
		if (isNullOrUndefined(response.response) || isNullOrUndefined(response.response.data)) {
			setWarningText(response.message);
			return;
		}
		setWarningText(response.response.data);
	}

	function handleInput(event) {
		const name = event.target.name;
		const value = event.target.value;

		switch (name) {
			case 'Name':
				setName(value);
				break;
			case 'IsAvailable':
				setIsAvailable(event.target.checked);
				break;
			case 'SchoolSelection':
				setSelectedSchools(value);
				break;
			case 'ProductCategory':
				setSelectedProductCategory(value);
				break;
			default:
				return;
		}
	}

	function handleIndexedInput(event, index) {
		const name = event.target.name;
		const value = event.target.value;

		switch (name) {
			case 'ImageOrder':
				images[index].orderIndex = value;
				setImages([...images]);
				break;
			case 'ImageVariation':
				images[index].variationIds = value;
				setImages([...images]);
				break;
			case 'StockCount':
				stocks[index].stockCount = value;
				stocks[index].isEdited = true;
				setStocks([...stocks]);
				break;
			case 'StockVariation':
				stocks[index].variationIds = value;
				stocks[index].isEdited = true;
				setStocks([...stocks]);
				break;
			default:
				return;
		}
	}

	function handleImageSelect(event) {
		setImageIndex(-1);
		if (event.target.files) {
			for (let i = 0; i < event.target.files.length; i++) {
				const file = event.target.files[i];
				var reader = new FileReader();
				reader.onload = ev => {
					if (ev.loaded) {
						const image = {
							imagePath: ev.srcElement.result,
							orderIndex: images.length,
							variationIds: [],
							file: file,
						};
						images.push(image);
						setImageIndex(i);
					}
				};
				reader.readAsDataURL(file);
			}
		}
	}

	function deleteImage(index) {
		const currentImages = images;
		currentImages.splice(index, 1);
		setImages([...currentImages]);
	}

	function addNewStock() {
		const newStock = {
			variationIds: [],
			stockCount: defaultStockCount,
			isEdited: true,
			originalStock: 0,
		};
		setStocks([...stocks, newStock]);
	}

	function addAllStockVariations() {
		const variationMap = [];
		//Setup 2D array of variations
		let highestIndex = 0;
		for (let i = 0; i < variations.length; i++) {
			if (variations[i].variationTypeOrderIndex > highestIndex) {
				highestIndex = variations[i].variationTypeOrderIndex;
			}
		}
		for (let i = 0; i <= highestIndex; i++) {
			variationMap.push([]);
		}
		for (let i = 0; i < variations.length; i++) {
			variationMap[variations[i].variationTypeOrderIndex].push(variations[i]);
		}
		const totalCombinations = [];
		for (let i = 0; i < variationMap[0].length; i++) {
			totalCombinations.push(...populateTree(variationMap, 1, [[variationMap[0][i].id]]));
		}

		setStocks([
			...stocks,
			...totalCombinations
				.filter(value => {
					//Remove any stock variations that are already added
					if (
						stocks.some(stock => {
							if (
								stock.variationIds.length === value.length &&
								stock.variationIds.every(id => {
									return value.some(newid => {
										return id === newid;
									});
								})
							) {
								return true;
							}
							return false;
						})
					) {
						return null;
					}
					return value;
				})
				.map(value => {
					return {
						variationIds: value,
						stockCount: defaultStockCount,
						isEdited: true,
						originalStock: 0,
					};
				}),
		]);
	}

	function populateTree(variations, index, currentCombinations) {
		const toAdd = [];
		for (let i = 0; i < variations[index].length; i++) {
			const currentVariation = variations[index][i].id;
			for (let j = 0; j < currentCombinations.length; j++) {
				toAdd.push([...currentCombinations[j], currentVariation]);
			}
		}
		currentCombinations = [...currentCombinations, ...toAdd];
		if (variations.length > index + 1) {
			return populateTree(variations, index + 1, currentCombinations);
		}
		return currentCombinations;
	}

	function deleteStock(index) {
		const currentStock = stocks;
		currentStock.splice(index, 1);
		setStocks([...currentStock]);
	}

	function buildImageSection() {
		if (images.length === 0) {
			return (
				<Grid item xs={12}>
					<Typography variant="h6" align="center" gutterBottom>
						No Images Added
					</Typography>
				</Grid>
			);
		}
		return images
			.sort((a, b) => {
				return a.orderIndex < b.orderIndex ? -1 : 1;
			})
			.map((value, index) => {
				return (
					<Grid item md={4} sm={6} xs={12} key={'Image ' + index}>
						<div className={classes.imageElement}>
							<img src={value.imagePath} />
							<TextField
								id={'ImageOrder' + index}
								label="Image Order *"
								value={value.orderIndex}
								onChange={event => {
									handleIndexedInput(event, index);
								}}
								variant="outlined"
								name="ImageOrder"
								fullWidth={true}
							/>
							<FormControl variant="filled" className={classes.formControl}>
								<InputLabel id={'Image-Variation-label' + index}>Attached Variations *</InputLabel>
								<Select
									id={'Image-Variation' + index}
									labelId={'Image-Variation-label' + index}
									value={value.variationIds}
									onChange={event => {
										handleIndexedInput(event, index);
									}}
									name="ImageVariation"
									variant="outlined"
									fullWidth={true}
									multiple={true}
								>
									{variations.map(e => {
										return (
											<MenuItem value={e.id} key={e.id}>
												{e.name}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
							<Button
								onClick={() => {
									deleteImage(index);
								}}
								className={classes.imageDeleteButton}
							>
								X
							</Button>
						</div>
					</Grid>
				);
			});
	}

	function buildStockSection() {
		return stocks.map((value, index) => {
			const selectedVariations = variations
				.map(variation => {
					if (value.variationIds.includes(variation.id)) {
						return variation;
					}
					return;
				})
				.filter(value => {
					return !isNullOrUndefined(value) ? value : null;
				});

			return (
				<Grid item md={4} sm={6} xs={12} key={'Stock' + index}>
					<div className={classes.imageElement}>
						<FormControl variant="filled" className={classes.formControl}>
							<InputLabel id={'Image-Variation-label' + index}>Attached Variations *</InputLabel>
							<Select
								id={'Stock-Variation' + index}
								labelId={'Stock-Variation-label' + index}
								value={value.variationIds}
								onChange={event => {
									handleIndexedInput(event, index);
								}}
								name="StockVariation"
								variant="outlined"
								fullWidth={true}
								multiple={true}
							>
								{variations.map(e => {
									if (
										!selectedVariations.some(variation => {
											return (
												e.variationTypeId == variation.variationTypeId && e.id !== variation.id
											);
										})
									) {
										return (
											<MenuItem value={e.id} key={e.id}>
												{e.name}
											</MenuItem>
										);
									}
								})}
							</Select>
						</FormControl>
						<TextField
							id={'Stock-Count' + index}
							label="Stock Count *"
							value={value.stockCount}
							onChange={event => {
								handleIndexedInput(event, index);
							}}
							variant="outlined"
							name="StockCount"
							fullWidth={true}
						/>
						<Button
							onClick={() => {
								deleteStock(index);
							}}
							className={classes.imageDeleteButton}
						>
							X
						</Button>
					</div>
				</Grid>
			);
		});
	}

	function buildWarning() {
		if (isNullOrUndefined(warningText)) return null;
		return <Alert severity="warning">{warningText}</Alert>;
	}

	return (
		<Container>
			<LoadingOverlay loading={loading} />

			<Typography variant="h3" align="center" gutterBottom>
				Variation Editing
			</Typography>

			<Grid container spacing={2}>
				<Grid item xs={12}>
					<TextField
						id="Product-Name"
						label="Product Name *"
						value={name}
						onChange={handleInput}
						variant="outlined"
						name="Name"
						fullWidth={true}
					/>
				</Grid>
				<Grid item xs={12}>
					<FormControl variant="filled" className={classes.formControl}>
						<InputLabel id="Product-Category-label">Product Category *</InputLabel>
						<Select
							id="Product-Category-Selection"
							labelId="Product-Category-label"
							value={selectedProductCategory}
							onChange={handleInput}
							name="ProductCategory"
							variant="outlined"
							fullWidth={true}
						>
							{ProductCategorys.map(value => {
								return (
									<MenuItem value={value.id} key={value.id}>
										{value.name}
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={12}>
					<FormControl variant="filled" className={classes.formControl}>
						<FormControlLabel
							control={
								<Checkbox
									label="Is Available *"
									checked={isAvailable}
									onChange={handleInput}
									color="primary"
									variant="outlined"
									name="IsAvailable"
								/>
							}
							label="Is Available"
						/>
					</FormControl>
				</Grid>
				<Grid item xs={12}>
					<FormControl variant="filled" className={classes.formControl}>
						<InputLabel id="School-Selection-label">Schools *</InputLabel>
						<Select
							id="School-Selection"
							labelId="School-Selection-label"
							value={selectedSchools}
							onChange={handleInput}
							name="SchoolSelection"
							variant="outlined"
							fullWidth={true}
							multiple={true}
						>
							{schools
								.sort((a, b) => a.name.localeCompare(b.name))
								.map(value => {
									return (
										<MenuItem value={value.id} key={value.id}>
											{value.name}
										</MenuItem>
									);
								})}
						</Select>
					</FormControl>
				</Grid>
				<Divider />
				<Grid item container xs={12} spacing={2} className={classes.section}>
					<Grid item xs={12}>
						<Typography variant="h4" align="center" gutterBottom>
							Images
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<div className={classes.addImage}>
							<PhotoCamera className="icon" />
							<span>Upload Image</span>
							<input type="file" multiple accept="image/*" onChange={handleImageSelect} />
						</div>
					</Grid>

					{buildImageSection()}
				</Grid>
				<Divider />
				<Grid item container xs={12} spacing={2} className={classes.section}>
					<Grid item xs={12}>
						<Typography variant="h4" align="center" gutterBottom>
							Stocks
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Button onClick={addNewStock}>
							<Add /> Add stock
						</Button>
						<Button onClick={addAllStockVariations}>
							<Add /> Add all variations
						</Button>
						<TextField
							id="New-Stock-Default-Amount"
							label="New stock default amount *"
							value={defaultStockCount}
							onChange={e => {
								setDefaultStockCount(e.target.value);
							}}
							variant="outlined"
							type="number"
						/>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="body1" align="center" gutterBottom>
							Warning: Removing stocks will also remove any requests for that variation. If you want to
							mark it out of stock set the stock count to 0.
						</Typography>
					</Grid>
					{buildStockSection()}
				</Grid>
				<Grid item xs={12}>
					{buildWarning()}
				</Grid>
				<Button onClick={() => SubmitChanges()} disabled={adding}>
					{adding ? 'Saving' : 'Save'}
				</Button>
				<Button component={Link} to={'/ProductList'}>
					Close
				</Button>
			</Grid>
		</Container>
	);
}

ProductEditor.propTypes = {
	location: PropTypes.object,
	history: PropTypes.object,
};
