import React from 'react';
import * as signalR from '@microsoft/signalr';
import { Link, useLocation } from 'react-router-dom';
import { Role, SIGNALR_Paths } from '../../helpers/Constants';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { Badge, Divider, Paper } from '@material-ui/core';
import NotificationsController from '../../controllers/NotificationsController';
import moment from 'moment';
import { isNullOrUndefined } from '../../helpers/Utils';
import UserController from '../../controllers/UserController';
import { AddNotif, AddNotifs, MarkAsRead, MarkAllAsRead } from '../../stores/Actions/Notifications';
import logo from '../../images/uex-logo.png';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
	},
	drawer: {
		flexShrink: 0,
	},
	appBar: {
		marginLeft: drawerWidth,
		[theme.breakpoints.up('sm')]: {
			width: `100%`,
		},
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	toolbar: theme.mixins.toolbar,
	drawerPaper: {
		width: drawerWidth,
		[theme.breakpoints.down('xs')]: {
			marginTop: '0',
		},
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
		marginTop: '84px',
	},
	avatarButton: {
		position: 'absolute',
		marginRight: '24px',
		marginTop: '12px',
		minWidth: 'unset',
		borderRadius: '64px',
		padding: 0,
		right: 0,
		top: 0,
		[theme.breakpoints.down('xs')]: {
			marginTop: '8px',
		},
	},
	notifButton: {
		position: 'absolute',
		right: 76,
		top: 9,
		[theme.breakpoints.down('xs')]: {
			top: 7,
		},
	},
	notifWrapper: {
		maxHeight: 500,
		overflowY: 'auto',
	},
	notif: {
		position: 'relative',
		padding: '12px 16px',
		width: 260,
	},
	notifViewAll: {
		position: 'absolute',
		top: 14,
		right: 16,
	},
	checkoutButton: {
		position: 'absolute',
		right: 0,
		top: 0,
		marginRight: '24px',
		marginTop: '12px',
		backgroundColor: '#FFFFFF',
		'&:hover': {
			backgroundColor: '#AAAAAA',
			color: '#FFFFFF',
		},
	},
	logo: {
		height: 63,
		padding: 6,
	},
}));

/**
 * TODO: refactor to seperate SignalR concerns?
 */
function NavMenu(props) {
	const { PushHistory, AddNotif, AddNotifs, MarkAsRead, MarkAllAsRead, Notifications, container } = props;
	const { userName, role, isAuthenticated } = props.LogIn;

	const classes = useStyles();
	const theme = useTheme();
	const [mobileOpen, setMobileOpen] = React.useState(false);
	const [userMenuOpen, setUserMenuOpen] = React.useState(false);
	const [notifTrayOpen, setNotifTrayOpen] = React.useState(false);
	const [profileAnchorEl, setProfileAnchorEl] = React.useState(null);
	const [notifAnchorEl, setNotifAnchorEl] = React.useState(null);
	const [totalUnread, setTotalUnread] = React.useState(0);
	const [redirectUrl, setRedirectUrl] = React.useState(null);
	const [hubConnection, setHubConnection] = React.useState(null);
	const [showCheckout, setShowCheckout] = React.useState(false);

	const location = useLocation();

	// initialise data
	React.useEffect(() => {
		async function init() {
			const totalResponse = await NotificationsController.getUnreadCount();
			if (!totalResponse.hasError) {
				setTotalUnread(totalResponse.data);
			}
			const notifsResponse = await NotificationsController.getTop();
			if (!notifsResponse.hasError) {
				AddNotifs(notifsResponse.data);
			}
		}
		if (isAuthenticated) {
			init();
		}
	}, [AddNotifs, isAuthenticated]);

	// intialise signalR
	React.useEffect(() => {
		if (isAuthenticated && hubConnection === null) {
			const options = {
				accessTokenFactory: UserController.getToken,
			};
			const connection = new signalR.HubConnectionBuilder()
				.withUrl(SIGNALR_Paths.Notifications, options)
				.withAutomaticReconnect()
				.build();

			connection.on('MarkAsRead', MarkAsRead);
			connection.on('MarkAllAsRead', MarkAllAsRead);
			connection.on('NewNotification', AddNotif);
			connection.start().catch(err => {
				console.error(err);
			});
			setHubConnection(connection);
		}
	}, [AddNotif, MarkAsRead, MarkAllAsRead, hubConnection, isAuthenticated]);

	// redirect
	React.useEffect(() => {
		if (!isNullOrUndefined(redirectUrl)) {
			PushHistory(redirectUrl);
			setRedirectUrl(null);
		}
	}, [redirectUrl, PushHistory]);

	// notif count
	React.useEffect(() => {
		async function recalcUnread() {
			let unread = 0;
			const totalResponse = await NotificationsController.getUnreadCount();
			if (!totalResponse.hasError) {
				unread = totalResponse.data;
			}
			if (unread === 0) {
				unread = Notifications.filter(e => !e.markedAsRead).length;
			}
			setTotalUnread(unread);
		}
		if (isAuthenticated) {
			recalcUnread();
		}
	}, [Notifications, isAuthenticated]);

	//ChekoutButtonDesplay
	React.useEffect(() => {
		setShowCheckout(window.location.pathname === '/Products');
	}, [location]);

	function handleDrawerToggle() {
		setMobileOpen(!mobileOpen);
		handleUserMenuClose();
		handleNotifTrayClose();
	}

	function handleUserMenuToggle(event) {
		setUserMenuOpen(!userMenuOpen);
		setProfileAnchorEl(event.currentTarget);
		handleNotifTrayClose();
	}

	function handleUserMenuClose() {
		setUserMenuOpen(false);
		setProfileAnchorEl(null);
	}

	function handleNotifTrayToggle(event) {
		if (!notifTrayOpen) {
			markUnreadAsRead();
		}
		setNotifTrayOpen(!notifTrayOpen);
		setNotifAnchorEl(event.currentTarget);
		handleUserMenuClose();
	}

	function handleNotifTrayClose() {
		setNotifTrayOpen(false);
		setNotifAnchorEl(null);
	}

	function viewAllNotifs() {
		handleNotifTrayClose();
		setRedirectUrl('/Notifications');
		NotificationsController.markAllAsRead();
		MarkAllAsRead();
		setTotalUnread(0);
	}

	function markUnreadAsRead() {
		const unreadNotifs = Notifications.filter(e => !e.markedAsRead);
		unreadNotifs.forEach(e => {
			NotificationsController.markAsRead(e.id);
		});
		MarkAllAsRead();
		setTotalUnread(totalUnread - unreadNotifs.length);
	}

	function buildDrawerOption(name, route, key = null) {
		return (
			<ListItem button component={Link} to={route} key={key ?? name} onClick={handleDrawerToggle}>
				<ListItemText primary={name} />
			</ListItem>
		);
	}

	function buildDrawerOptions() {
		const drawerOptions = [buildDrawerOption('Home', '/')];
		if (role && role.includes(Role.Admin)) {
			drawerOptions.push(buildDrawerOption('Home Page Text', '/HomePageTextEditor'));
			drawerOptions.push(buildDrawerOption('Urgency Question', '/AdditionalInfoEditor'));
			drawerOptions.push(buildDrawerOption('Email Text', '/EmailTextEditor'));
			drawerOptions.push(buildDrawerOption('Schools', '/Schools'));
			drawerOptions.push(buildDrawerOption('Delivery Points', '/DeliveryPoint'));
			drawerOptions.push(buildDrawerOption('ProductCategory', '/ProductCategoryEditor'));
			drawerOptions.push(buildDrawerOption('Variation Type', '/VariationType'));
			drawerOptions.push(buildDrawerOption('Variation', '/Variation'));
			drawerOptions.push(buildDrawerOption('Products', '/ProductList'));
			drawerOptions.push(buildDrawerOption('Product Requests', '/ProductRequests'));
			drawerOptions.push(buildDrawerOption('Settings', '/Settings'));
		}
		if (!userName) {
			drawerOptions.push(buildDrawerOption('Login', '/Login'));
			drawerOptions.push(buildDrawerOption('Register', '/Register'));
		}
		return <List>{drawerOptions}</List>;
	}

	function buildProfileOptions() {
		return userName ? (
			<>
				<Button
					className={classes.avatarButton}
					onClick={handleUserMenuToggle}
					aria-controls="simple-menu"
					aria-haspopup="true"
				>
					<Avatar>{userName.charAt(0).toUpperCase()}</Avatar>
				</Button>
				<Menu
					id="user-menu"
					anchorEl={profileAnchorEl}
					keepMounted
					open={Boolean(userMenuOpen)}
					onClose={handleUserMenuClose}
				>
					<MenuItem onClick={handleUserMenuClose} component={Link} to="/UserManagement" key="userManagement">
						Profile
					</MenuItem>
					<MenuItem id="logoutBtn" onClick={handleUserMenuClose} component={Link} to="/Logout" key="logout">
						Logout
					</MenuItem>
				</Menu>
			</>
		) : null;
	}

	function buildNotificationTray() {
		return userName ? (
			<>
				<IconButton
					className={classes.notifButton}
					onClick={handleNotifTrayToggle}
					aria-controls="simple-menu"
					aria-haspopup="true"
					color="inherit"
				>
					<Badge badgeContent={totalUnread} color="secondary">
						<NotificationsIcon />
					</Badge>
				</IconButton>
				<Menu
					id="notif-tray"
					anchorEl={notifAnchorEl}
					keepMounted
					open={Boolean(notifTrayOpen)}
					onClose={handleNotifTrayClose}
					className={classes.notifWrapper}
				>
					{[
						<Paper elevation={0} key="title-paper" className={classes.notif}>
							<Typography variant="h5">Notifications</Typography>
							<Button
								onClick={() => viewAllNotifs()}
								size="small"
								variant="outlined"
								className={classes.notifViewAll}
								disabled={Notifications.length === 0}
							>
								View All
							</Button>
						</Paper>,
						<Divider key="title-divider" />,
						buildNotifications(),
					]}
				</Menu>
			</>
		) : null;
	}

	function buildNotifications() {
		if (Notifications.length === 0) {
			return (
				<Paper elevation={0} key="no-notifs" className={classes.notif}>
					<Typography variant="body1" color="textSecondary">
						No notifications
					</Typography>
				</Paper>
			);
		}
		return <div key="notifs">{Notifications.map((e, i) => buildNotification(e, i))}</div>;
	}

	function buildNotification(notif, index) {
		const isLastIndex = index === Notifications.length - 1;
		const { title, message, created } = notif;
		return (
			<div key={index}>
				<Paper elevation={0} className={classes.notif}>
					<Typography variant="body1" gutterBottom>
						{title}
					</Typography>
					<Typography variant="body2" gutterBottom>
						{message}
					</Typography>
					<Typography variant="caption" color="textSecondary">
						{moment.utc(created).local().format('D MMMM YYYY')}
					</Typography>
				</Paper>
				{!isLastIndex ? <Divider /> : null}
			</div>
		);
	}

	return (
		<div className={classes.root}>
			<CssBaseline />
			<AppBar position="fixed" className={classes.appBar}>
				<Toolbar>
					{isAuthenticated ? (
						<IconButton
							color="inherit"
							aria-label="Open drawer"
							edge="start"
							onClick={handleDrawerToggle}
							className={classes.menuButton}
						>
							<MenuIcon />
						</IconButton>
					) : null}
					<img className={classes.logo} src={logo} alt="Uniform Exchange" />
					{buildNotificationTray()}
					{buildProfileOptions()}
				</Toolbar>
			</AppBar>
			{isAuthenticated ? (
				<nav className={classes.drawer} aria-label="Mailbox folders">
					<Drawer
						container={container}
						variant="temporary"
						anchor={theme.direction === 'rtl' ? 'right' : 'left'}
						open={mobileOpen}
						onClose={handleDrawerToggle}
						classes={{ paper: classes.drawerPaper }}
						ModalProps={{ keepMounted: true }}
					>
						{buildDrawerOptions()}
					</Drawer>
				</nav>
			) : null}
			<main className={classes.content}>{props.children}</main>
		</div>
	);
}

const mapStateToProps = state => ({
	LogIn: state.Authentication,
	Notifications: state.Notifications,
});

const mapDispatchToProps = dispatch => ({
	PushHistory: data => dispatch(push(data)),
	AddNotif: item => dispatch(AddNotif(item)),
	AddNotifs: items => dispatch(AddNotifs(items)),
	MarkAsRead: id => dispatch(MarkAsRead(id)),
	MarkAllAsRead: () => dispatch(MarkAllAsRead()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NavMenu);

NavMenu.propTypes = {
	container: PropTypes.object,
	children: PropTypes.any,
	LogIn: PropTypes.object,
	PushHistory: PropTypes.func,
	AddNotif: PropTypes.func,
	AddNotifs: PropTypes.func,
	MarkAsRead: PropTypes.func,
	MarkAllAsRead: PropTypes.func,
	Notifications: PropTypes.array,
};
