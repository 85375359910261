import React from 'react';
import SchoolController from '../../controllers/SchoolController';
import {
	Container,
	Typography,
	Grid,
	Table,
	TableHead,
	TableRow,
	TableCell,
	Button,
	TableBody,
	Dialog,
	DialogTitle,
	Divider,
	DialogContent,
	TextField,
	DialogActions,
} from '@material-ui/core';
import { LoadingOverlay } from '../../components/Common/LoadingOverlay';
import { Alert } from '@material-ui/lab';
import { AddCircle, Delete, Edit } from '@material-ui/icons';
import { isNullOrUndefined } from '../../helpers/Utils';

export default function SchoolEditor() {
	const [loading, setLoading] = React.useState(true);
	const [warningText, setWarningText] = React.useState(null);
	const [schools, setSchools] = React.useState([]);

	const [showAddModal, setShowAddModal] = React.useState(false);
	const [newSchoolName, setNewSchoolName] = React.useState('');
	const [adding, SetAdding] = React.useState(false);

	const [showDelete, setShowDelete] = React.useState(false);
	const [showEdit, setShowEdit] = React.useState(false);
	const [editValue, setEditValue] = React.useState(null);
	// initialise
	React.useEffect(() => {
		async function init() {
			const response = await SchoolController.getSchools();
			if (response.hasError) {
				HandleError(response.data.response);
				return;
			}
			setSchools(response.data);
			setLoading(false);
		}
		init();
	}, [setSchools]);

	function handleInput(event) {
		const name = event.target.name;
		const value = event.target.value;

		switch (name) {
			case 'schoolName':
				setNewSchoolName(value);
				break;
			default:
				return;
		}
	}

	async function AddSchool() {
		SetAdding(true);
		const response = await SchoolController.addSchool(newSchoolName);
		if (response.hasError) {
			SetAdding(false);
			HandleError(response.data.response);
			return;
		}
		SetAdding(false);
		setSchools([...schools, response.data]);
		setShowAddModal(false);
		setNewSchoolName('');
		setWarningText(null);
	}

	async function SaveSchool() {
		SetAdding(true);
		const response = await SchoolController.editSchool(editValue.id, newSchoolName);
		if (response.hasError) {
			SetAdding(false);
			HandleError(response.data.response);
			return;
		}
		SetAdding(false);
		editValue.name = newSchoolName;
		setShowEdit(false);
		setNewSchoolName('');
		setWarningText(null);
	}

	async function ConfirmDeleteSchool() {
		SetAdding(true);
		const response = await SchoolController.deleteSchool(editValue.id);
		if (response.hasError) {
			SetAdding(false);
			HandleError(response.data.response);
			return;
		}
		SetAdding(false);
		const currentSchools = schools;
		currentSchools.splice(currentSchools.indexOf(editValue), 1);
		setSchools([...currentSchools]);
		setShowDelete(false);
		setWarningText(null);
	}

	function HandleError(response) {
		if (isNullOrUndefined(response)) {
			return 'Failed to connect to server. Please check your internet connection';
		}
		if (isNullOrUndefined(response.response) || isNullOrUndefined(response.response.data)) {
			setWarningText(response.message);
			return;
		}
		setWarningText(response.response.data);
	}

	function EditSchool(value) {
		setShowEdit(true);
		setEditValue(value);
		setNewSchoolName(value.name);
	}

	function CloseEdit() {
		setShowEdit(false);
		setEditValue(null);
		setNewSchoolName('');
		setWarningText(null);
	}

	function DeleteSchool(value) {
		setShowDelete(true);
		setEditValue(value);
		setNewSchoolName(value.name);
	}

	function CloseDelete() {
		setShowDelete(false);
		setEditValue(null);
		setWarningText(null);
	}

	function buildTable() {
		return (
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Name</TableCell>
						<TableCell></TableCell>
						<TableCell align="right">
							<Button
								onClick={() => {
									setShowAddModal(true);
								}}
							>
								<AddCircle />
							</Button>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{schools
						.sort((a, b) => {
							return a.name < b.name ? -1 : 1;
						})
						.map(value => {
							return (
								<TableRow key={value.id}>
									<TableCell>{value.name}</TableCell>
									<TableCell align="right">
										<Button
											onClick={() => {
												EditSchool(value);
											}}
										>
											<Edit />
										</Button>
									</TableCell>
									<TableCell align="right">
										<Button
											onClick={() => {
												DeleteSchool(value);
											}}
										>
											<Delete />
										</Button>
									</TableCell>
								</TableRow>
							);
						})}
				</TableBody>
			</Table>
		);
	}

	function buildAddModal() {
		return (
			<Dialog
				open={showAddModal}
				onClose={() => {
					setShowAddModal(false);
				}}
			>
				<DialogTitle>Add School</DialogTitle>
				<Divider />
				<DialogContent>
					<TextField
						id="School-Name"
						label="School Name *"
						value={newSchoolName}
						onChange={handleInput}
						variant="outlined"
						name="schoolName"
					/>
					{buildWarning()}
				</DialogContent>
				<Divider />
				<DialogActions>
					<Button onClick={() => AddSchool()} disabled={adding}>
						{adding ? 'Adding' : 'Add'}
					</Button>
					<Button
						onClick={() => {
							setShowAddModal(false);
						}}
					>
						Close
					</Button>
				</DialogActions>
			</Dialog>
		);
	}

	function buildEditModal() {
		return (
			<Dialog
				open={showEdit}
				onClose={() => {
					CloseEdit();
				}}
			>
				<DialogTitle>Edit School</DialogTitle>
				<Divider />
				<DialogContent>
					<TextField
						id="School-Name"
						label="School Name *"
						value={newSchoolName}
						onChange={handleInput}
						variant="outlined"
						name="schoolName"
					/>
					{buildWarning()}
				</DialogContent>
				<Divider />
				<DialogActions>
					<Button onClick={() => SaveSchool()} disabled={adding}>
						{adding ? 'Saving' : 'Save'}
					</Button>
					<Button
						onClick={() => {
							CloseEdit();
						}}
					>
						Close
					</Button>
				</DialogActions>
			</Dialog>
		);
	}

	function buildDeleteModal() {
		return (
			<Dialog
				open={showDelete}
				onClose={() => {
					CloseDelete();
				}}
			>
				<DialogTitle>Delete School</DialogTitle>
				<Divider />
				<DialogContent>
					<p>You are permanently deleting {editValue?.name}</p>
					{buildWarning()}
				</DialogContent>
				<Divider />
				<DialogActions>
					<Button onClick={() => ConfirmDeleteSchool()} disabled={adding}>
						{adding ? 'Deleting' : 'Delete'}
					</Button>
					<Button
						onClick={() => {
							CloseDelete();
						}}
					>
						Close
					</Button>
				</DialogActions>
			</Dialog>
		);
	}

	function buildWarning() {
		if (isNullOrUndefined(warningText)) return null;
		return <Alert severity="warning">{warningText}</Alert>;
	}

	return (
		<Container maxWidth="xs">
			<LoadingOverlay loading={loading} />

			<Typography variant="h3" align="center" gutterBottom>
				School Editing
			</Typography>
			{buildAddModal()}
			{buildEditModal()}
			{buildDeleteModal()}
			<Grid item xs={12}>
				{buildWarning()}
			</Grid>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					{buildTable()}
				</Grid>
			</Grid>
		</Container>
	);
}

SchoolEditor.propTypes = {};
