import React from 'react';
import VariationController from '../../controllers/VariationController';
import VariationTypeController from '../../controllers/VariationTypeController';
import {
	Container,
	Typography,
	Grid,
	Table,
	TableHead,
	TableRow,
	TableCell,
	Button,
	TableBody,
	Dialog,
	DialogTitle,
	Divider,
	DialogContent,
	TextField,
	DialogActions,
	Select,
	MenuItem,
	InputLabel,
	FormControl,
	makeStyles,
} from '@material-ui/core';
import { LoadingOverlay } from '../../components/Common/LoadingOverlay';
import { Alert } from '@material-ui/lab';
import { AddCircle, Delete, Edit, ArrowUpward, ArrowDownward } from '@material-ui/icons';
import { isNullOrUndefined } from '../../helpers/Utils';
import { SketchPicker } from 'react-color';

const useStyles = makeStyles(() => ({
	formControl: {
		width: '100%',
	},
	displayColour: {
		height: '50px',
		width: '50px',
		border: 'solid 1px black',
		borderRadius: '5px',
	},
	inlineTableHeader: {
		display: 'flex',
	},
}));

export default function VariationEditor() {
	const [loading, setLoading] = React.useState(true);
	const [warningText, setWarningText] = React.useState(null);
	const [Variations, setVariations] = React.useState([]);
	const [VariationTypes, setVariationTypes] = React.useState([]);
	const [sortBy, setSortBy] = React.useState('variationTypeName');
	const [sortDes, setSortDes] = React.useState(false);

	const [showAddModal, setShowAddModal] = React.useState(false);
	const [newVariationName, setNewVariationName] = React.useState('');
	const [newVariationType, setNewVariationType] = React.useState('');
	const [newVariationOrderIndex, setNewVariationOrderIndex] = React.useState(0);
	const [newUIObject, setNewUIObject] = React.useState('');
	const [displayColourPicker, setDisplayColourPicker] = React.useState('');
	const [adding, SetAdding] = React.useState(false);

	const [showDelete, setShowDelete] = React.useState(false);
	const [showEdit, setShowEdit] = React.useState(false);
	const [editValue, setEditValue] = React.useState(null);

	const classes = useStyles();

	// initialise
	React.useEffect(() => {
		async function init() {
			const typesResponse = await VariationTypeController.getVariationTypes();
			if (typesResponse.hasError) {
				HandleError(typesResponse.data.response);
				setLoading(false);
				return;
			}
			setVariationTypes(typesResponse.data);

			const variationResponse = await VariationController.getVariations();
			if (variationResponse.hasError) {
				HandleError(variationResponse.data.response);
			} else {
				for (let i = 0; i < variationResponse.data.length; i++) {
					variationResponse.data[i].variationTypeName = typesResponse.data.find(value => {
						return value.id === variationResponse.data[i].variationTypeId;
					})?.name;
				}

				setVariations(variationResponse.data);
			}
			setLoading(false);
		}
		init();
	}, [setVariations, setVariationTypes]);

	function handleInput(event) {
		const name = event.target.name;
		const value = event.target.value;

		switch (name) {
			case 'VariationName':
				setNewVariationName(value);
				break;
			case 'VariationType':
				setNewVariationType(value);
				break;
			case 'VariationOrderIndex':
				setNewVariationOrderIndex(value);
				break;
			default:
				return;
		}
	}

	async function AddVariation() {
		SetAdding(true);
		if (newVariationType === '') {
			setWarningText('Please select a variation type');
			return;
		}
		setWarningText(null);
		const response = await VariationController.addVariation(
			newVariationName,
			newVariationType,
			newUIObject,
			newVariationOrderIndex
		);
		if (response.hasError) {
			SetAdding(false);
			HandleError(response.data.response);
			return;
		}
		SetAdding(false);
		response.data.variationTypeName = getVariationTypeName(newVariationType);
		setVariations([...Variations, response.data]);
		setShowAddModal(false);
		setNewVariationName('');
		setNewVariationType('');
		setNewVariationOrderIndex(0);
		setWarningText(null);
	}

	async function SaveVariation() {
		SetAdding(true);
		const response = await VariationController.editVariation(
			editValue.id,
			newVariationName,
			newUIObject,
			newVariationOrderIndex
		);
		if (response.hasError) {
			SetAdding(false);
			HandleError(response.data.response);
			return;
		}
		SetAdding(false);
		editValue.name = newVariationName;
		editValue.orderIndex = newVariationOrderIndex;
		editValue.uiObject = newUIObject;

		setShowEdit(false);
		setNewVariationName('');
		setNewVariationType('');
		setNewVariationOrderIndex(0);
		setNewUIObject('');
		setWarningText(null);
	}

	async function ConfirmDeleteVariation() {
		SetAdding(true);
		const response = await VariationController.deleteVariation(editValue.id);
		if (response.hasError) {
			SetAdding(false);
			HandleError(response.data.response);
			return;
		}
		SetAdding(false);
		const currentVariations = Variations;
		currentVariations.splice(currentVariations.indexOf(editValue), 1);
		setVariations([...currentVariations]);
		setShowDelete(false);
		setWarningText(null);
	}

	function HandleError(response) {
		if (isNullOrUndefined(response)) {
			return 'Failed to connect to server. Please check your internet connection';
		}
		if (isNullOrUndefined(response.response) || isNullOrUndefined(response.response.data)) {
			setWarningText(response.message);
			return;
		}
		setWarningText(response.response.data);
	}

	function EditVariation(value) {
		setShowEdit(true);
		setEditValue(value);
		setNewVariationName(value.name);
		setNewVariationType(value.variationTypeId);
		setNewUIObject(value.uiObject);
	}

	function CloseEdit() {
		setShowEdit(false);
		setEditValue(null);
		setNewVariationName('');
		setNewVariationType('');
		setNewUIObject('');
		setWarningText(null);
	}

	function DeleteVariation(value) {
		setShowDelete(true);
		setEditValue(value);
		setNewVariationName(value.name);
		setNewVariationType('');
		setNewUIObject('');
	}

	function CloseDelete() {
		setShowDelete(false);
		setEditValue(null);
		setWarningText(null);
	}

	function HandleTableHeaderClick(headerValue) {
		if (sortBy === headerValue) {
			setSortDes(!sortDes);
			return;
		}
		setSortBy(headerValue);
		setSortDes(false);
	}

	function getVariationTypeName(id) {
		const variationType = VariationTypes.find(value => {
			return value.id === id;
		});
		if (!isNullOrUndefined(variationType)) {
			return variationType.name;
		}
		return null;
	}

	function handleColourChange(colour) {
		setNewUIObject(colour.hex);
	}

	function buildUIObjectEditor() {
		const variationName = getVariationTypeName(newVariationType);
		if (isNullOrUndefined(variationName)) {
			return null;
		}
		let editorObject;
		switch (variationName) {
			case 'Colour':
				editorObject = isNullOrUndefined(newUIObject) || !newUIObject.includes('#') ? '#FFFFFF' : newUIObject;
				return (
					<Grid item xs={12}>
						<Button
							style={{ backgroundColor: editorObject }}
							className={classes.displayColour}
							onClick={() => {
								setDisplayColourPicker(!displayColourPicker);
							}}
						></Button>
						{displayColourPicker ? (
							<SketchPicker color={editorObject} onChange={handleColourChange} disableAlpha={true} />
						) : null}
					</Grid>
				);

			default:
				break;
		}
		return null;
	}

	function buildUIObjectDisplay(variationName, UIObject) {
		if (isNullOrUndefined(variationName)) {
			return null;
		}
		let editorObject;
		switch (variationName) {
			case 'Colour':
				editorObject = isNullOrUndefined(UIObject) || !UIObject.includes('#') ? '#FFFFFF' : UIObject;
				return <div style={{ backgroundColor: editorObject }} className={classes.displayColour}></div>;

			default:
				break;
		}
		return null;
	}

	function descendingComparator(a, b, orderBy) {
		if (b[orderBy] < a[orderBy]) {
			return -1;
		}
		if (b[orderBy] > a[orderBy]) {
			return 1;
		}
		return a.orderIndex < b.orderIndex ? 1 : -1;
	}

	function buildSortIcon(headerName) {
		if (sortBy !== headerName) {
			return null;
		}
		if (sortDes) {
			return <ArrowUpward />;
		}
		return <ArrowDownward />;
	}

	function buildTable() {
		const sortMod = sortDes ? 1 : -1;
		return (
			<Table>
				<TableHead>
					<TableRow>
						<TableCell
							onClick={() => {
								HandleTableHeaderClick('name');
							}}
						>
							<div className={classes.inlineTableHeader}> {buildSortIcon('name')} Name</div>
						</TableCell>
						<TableCell
							onClick={() => {
								HandleTableHeaderClick('variationTypeName');
							}}
						>
							<div className={classes.inlineTableHeader}>{buildSortIcon('variationTypeName')} Type </div>
						</TableCell>
						<TableCell>Order Index</TableCell>
						<TableCell></TableCell>
						<TableCell></TableCell>
						<TableCell align="right">
							<Button
								onClick={() => {
									setShowAddModal(true);
								}}
							>
								<AddCircle />
							</Button>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{Variations.sort((a, b) => {
						return descendingComparator(a, b, sortBy) * sortMod;
					}).map(value => {
						return (
							<TableRow key={value.id}>
								<TableCell>{value.name}</TableCell>
								<TableCell>{value.variationTypeName}</TableCell>
								<TableCell>{value.orderIndex}</TableCell>
								<TableCell>{buildUIObjectDisplay(value.variationTypeName, value.uiObject)}</TableCell>
								<TableCell align="right">
									<Button
										onClick={() => {
											EditVariation(value);
										}}
									>
										<Edit />
									</Button>
								</TableCell>
								<TableCell align="right">
									<Button
										onClick={() => {
											DeleteVariation(value);
										}}
									>
										<Delete />
									</Button>
								</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
		);
	}

	function buildAddModal() {
		return (
			<Dialog
				open={showAddModal}
				onClose={() => {
					setShowAddModal(false);
				}}
			>
				<DialogTitle>Add Variation</DialogTitle>
				<Divider />
				<DialogContent>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<TextField
								id="Variation-Name"
								label="Variation Name *"
								value={newVariationName}
								onChange={handleInput}
								variant="outlined"
								name="VariationName"
								fullWidth={true}
							/>
						</Grid>
						<Grid item xs={12}>
							<FormControl variant="filled" className={classes.formControl}>
								<InputLabel id="Variation-Type-label">Variation Type *</InputLabel>
								<Select
									id="Variation-Type"
									labelId="Variation-Type-label"
									value={newVariationType}
									onChange={handleInput}
									name="VariationType"
									variant="outlined"
									fullWidth={true}
								>
									{VariationTypes.map(value => {
										return (
											<MenuItem value={value.id} key={value.id}>
												{value.name}{' '}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12}>
							<TextField
								id="Variation-Order-Index"
								label="Variation Order Index *"
								value={newVariationOrderIndex}
								onChange={handleInput}
								variant="outlined"
								name="VariationOrderIndex"
								type="number"
								fullWidth={true}
							/>
						</Grid>
						{buildUIObjectEditor()}
						<Grid item xs={12}>
							{buildWarning()}
						</Grid>
					</Grid>
				</DialogContent>
				<Divider />
				<DialogActions>
					<Button onClick={() => AddVariation()} disabled={adding}>
						{adding ? 'Adding' : 'Add'}
					</Button>
					<Button
						onClick={() => {
							setShowAddModal(false);
						}}
					>
						Close
					</Button>
				</DialogActions>
			</Dialog>
		);
	}

	function buildEditModal() {
		return (
			<Dialog
				open={showEdit}
				onClose={() => {
					CloseEdit();
				}}
			>
				<DialogTitle>Edit Variation</DialogTitle>
				<Divider />
				<DialogContent>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<TextField
								id="Variation-Name"
								label="Variation Name *"
								value={newVariationName}
								onChange={handleInput}
								variant="outlined"
								name="VariationName"
								fullWidth={true}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								id="Variation-Order-Index"
								label="Variation Order Index *"
								value={newVariationOrderIndex}
								onChange={handleInput}
								variant="outlined"
								name="VariationOrderIndex"
								type="number"
								fullWidth={true}
							/>
						</Grid>
						{buildUIObjectEditor()}
						<Grid item xs={12}>
							{buildWarning()}
						</Grid>
					</Grid>
				</DialogContent>
				<Divider />
				<DialogActions>
					<Button onClick={() => SaveVariation()} disabled={adding}>
						{adding ? 'Saving' : 'Save'}
					</Button>
					<Button
						onClick={() => {
							CloseEdit();
						}}
					>
						Close
					</Button>
				</DialogActions>
			</Dialog>
		);
	}

	function buildDeleteModal() {
		return (
			<Dialog
				open={showDelete}
				onClose={() => {
					CloseDelete();
				}}
			>
				<DialogTitle>Delete Variation</DialogTitle>
				<Divider />
				<DialogContent>
					<p>You are permanently deleting {editValue?.name}</p>
					{buildWarning()}
				</DialogContent>
				<Divider />
				<DialogActions>
					<Button onClick={() => ConfirmDeleteVariation()} disabled={adding}>
						{adding ? 'Deleting' : 'Delete'}
					</Button>
					<Button
						onClick={() => {
							CloseDelete();
						}}
					>
						Close
					</Button>
				</DialogActions>
			</Dialog>
		);
	}

	function buildWarning() {
		if (isNullOrUndefined(warningText)) return null;
		return <Alert severity="warning">{warningText}</Alert>;
	}

	return (
		<Container maxWidth="xs">
			<LoadingOverlay loading={loading} />

			<Typography variant="h3" align="center" gutterBottom>
				Variation Editing
			</Typography>
			{buildAddModal()}
			{buildEditModal()}
			{buildDeleteModal()}
			<Grid item xs={12}>
				{buildWarning()}
			</Grid>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					{buildTable()}
				</Grid>
			</Grid>
		</Container>
	);
}

VariationEditor.propTypes = {};
