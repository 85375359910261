import React from 'react';
import PropTypes from 'prop-types';
import AdditionalInfoController from '../../controllers/AdditionalInfoController';
import { Button, Container, Card, CardContent, CardActions, CardHeader, TextField } from '@material-ui/core';
import logo from '../../images/uex-logo.png';
import { isNullOrUndefined } from '../../helpers/Utils';
import { Alert } from '@material-ui/lab';
import { LoadingOverlay } from '../../components/Common/LoadingOverlay';

export default function AdditionalInfoEditor(props) {
	const [loading, setLoading] = React.useState(false);
	const [warningText, setWarningText] = React.useState(null);

	const [titleText, setTitleText] = React.useState('');
	const [descriptionText, setDescriptionText] = React.useState('');
	const [optionAUserText, setOptionAUserText] = React.useState('');
	const [optionBUserText, setOptionBUserText] = React.useState('');
	const [optionAAdminText, setOptionAAdminText] = React.useState('');
	const [optionBAdminText, setOptionBAdminText] = React.useState('');

	React.useEffect(() => {
		async function init() {
			setLoading(true);
			const additionalInfoResponse = await AdditionalInfoController.getAdminAdditionalInfoSettings();
			if (additionalInfoResponse.hasError) {
				HandleError(additionalInfoResponse.data);
				setLoading(false);
				return;
			}
			setDescriptionText(additionalInfoResponse.data.description);
			setTitleText(additionalInfoResponse.data.title);
			setOptionAUserText(additionalInfoResponse.data.optionA.userText);
			setOptionBUserText(additionalInfoResponse.data.optionB.userText);
			setOptionAAdminText(additionalInfoResponse.data.optionA.adminText);
			setOptionBAdminText(additionalInfoResponse.data.optionB.adminText);
			setLoading(false);
		}
		init();
	}, []);

	function HandleError(response) {
		if (isNullOrUndefined(response)) {
			return 'Failed to connect to server. Please check your internet connection';
		}
		if (isNullOrUndefined(response.response) || isNullOrUndefined(response.response.data)) {
			setWarningText(response.message);
			return;
		}
		setWarningText(response.response.data);
	}

	async function Save() {
		const additionalInfoResponse = await AdditionalInfoController.putAdditionalInfoSettings(
			titleText,
			descriptionText,
			optionAUserText,
			optionBUserText,
			optionAAdminText,
			optionBAdminText
		);
		if (additionalInfoResponse.hasError) {
			HandleError(additionalInfoResponse.data);
			setLoading(false);
			return;
		}
		props.history.push('/AdditionalInfo');
	}

	function buildWarning() {
		if (isNullOrUndefined(warningText)) return null;
		return <Alert severity="warning">{warningText}</Alert>;
	}

	return (
		<React.Fragment>
			<LoadingOverlay loading={loading} />
			<Container maxWidth="sm">
				<Card variant="outlined">
					<CardContent>
						User Text: This is the text which is displayed to users for selecting options.
						<br />
						<br />
						Admin Text: This is the corresponding text that will be displayed on the printed Admin form.
					</CardContent>
					<CardContent>
						<TextField
							label="Title Text"
							value={titleText}
							onChange={e => {
								setTitleText(e.target.value);
							}}
							variant="outlined"
							fullWidth={true}
						/>
					</CardContent>
					<CardContent>
						<TextField
							label="Description Text"
							value={descriptionText}
							onChange={e => {
								setDescriptionText(e.target.value);
							}}
							variant="outlined"
							fullWidth={true}
							multiline
							rowsMax={20}
						/>
					</CardContent>
					<CardContent>
						<TextField
							label="Option A User Text"
							value={optionAUserText}
							onChange={e => {
								setOptionAUserText(e.target.value);
							}}
							variant="outlined"
							fullWidth={true}
						/>
					</CardContent>
					<CardContent>
						<TextField
							label="Option B User Text"
							value={optionBUserText}
							onChange={e => {
								setOptionBUserText(e.target.value);
							}}
							variant="outlined"
							fullWidth={true}
						/>
					</CardContent>
					<CardContent>
						<TextField
							label="Option A Admin Text"
							value={optionAAdminText}
							onChange={e => {
								setOptionAAdminText(e.target.value);
							}}
							variant="outlined"
							fullWidth={true}
						/>
					</CardContent>
					<CardContent>
						<TextField
							label="Option B Admin Text"
							value={optionBAdminText}
							onChange={e => {
								setOptionBAdminText(e.target.value);
							}}
							variant="outlined"
							fullWidth={true}
						/>
					</CardContent>
					<CardActions>
						<Button color="primary" variant="contained" fullWidth onClick={Save}>
							Save
						</Button>
						{buildWarning()}
					</CardActions>
				</Card>
			</Container>
		</React.Fragment>
	);
}

AdditionalInfoEditor.propTypes = {
	history: PropTypes.object,
};
