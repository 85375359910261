import { isNullOrUndefined, generateResponse } from './Utils';

export const IndexedDB = {
	init,
	add,
	remove,
	fetch,
	put,
};

export const tableNames = {
	tokenData: 'tokenData',
	cartData: 'cartData',
};

var db;
var dbInitalised = false;
const dbName = 'KirklessUniform';
const dbVersion = 3;

const hasIndexedDB = () => 'indexedDB' in window;

async function init() {
	return new Promise(resolve => {
		if (!hasIndexedDB) {
			resolve(generateResponse(true, 'IndexedDB is not supported'));
			return;
		}
		const request = indexedDB.open(dbName, dbVersion);
		request.onerror = () => resolve(generateResponse(true, 'IndexedDB is not supported!'));
		request.onsuccess = event => {
			db = event.target.result;
			resolve(setupDatabase());
		};
		request.onupgradeneeded = event => {
			const db = event.target.result;
			try {
				request.transaction.objectStore(tableNames.tokenData);
			} catch {
				const objStore = db.createObjectStore(tableNames.tokenData, {
					keyPath: 'name',
				});
				objStore.createIndex('content', 'content', { unique: false });
			}

			try {
				const transaction = request.transaction.objectStore(tableNames.cartData);
				if (!transaction.indexNames.contains('requester')) {
					transaction.createIndex('requester', 'requester', { unique: false });
				}
			} catch {
				const checkoutCart = db.createObjectStore(tableNames.cartData, {
					keyPath: 'id',
				});
				checkoutCart.createIndex('cart', 'cart', { unique: false });
				checkoutCart.createIndex('children', 'children', { unique: false });
				checkoutCart.createIndex('requester', 'requester', { unique: false });
			}
		};
	}).then(output => {
		return generateResponse(output.hasError, output.data);
	});
}

async function setupDatabase() {
	return new Promise(resolve => {
		if (isNullOrUndefined(db)) {
			resolve(generateResponse(true, 'Cannot setup database when none is found!'));
			return;
		}
		db.onerror = event => console.error('Database error: ' + event.target.errorCode);
		dbInitalised = true;
		resolve(generateResponse(false, null));
	}).then(output => {
		return generateResponse(output.hasError, output.data);
	});
}

async function add(tokenObj, tableName = tableNames.tokenData) {
	return new Promise(resolve => {
		if (!dbInitalised) {
			resolve(generateResponse(true, 'Cannot save tokens before the database has initialised!'));
			return;
		}
		const request = db.transaction(tableName, 'readwrite').objectStore(tableName).add(tokenObj);
		request.onsuccess = () => resolve(generateResponse(false, tokenObj));
		request.onerror = () => resolve(generateResponse(true, 'Token add failed!'));
	}).then(output => {
		return generateResponse(output.hasError, output.data);
	});
}

async function remove(name, tableName = tableNames.tokenData) {
	return new Promise(resolve => {
		if (!dbInitalised) {
			resolve(generateResponse(true, 'Cannot remove tokens before the database has initialised!'));
			return;
		}
		const request = db.transaction(tableName, 'readwrite').objectStore(tableName).delete(name);
		request.onsuccess = () => resolve(generateResponse(false, name));
		request.onerror = () => resolve(generateResponse(true, 'Token delete failed!'));
	}).then(output => {
		return generateResponse(output.hasError, output.data);
	});
}

async function fetch(name, tableName = tableNames.tokenData) {
	return new Promise(resolve => {
		if (!dbInitalised) {
			resolve(generateResponse(true, 'Cannot fetch tokens before the database has initialised!'));
			return;
		}
		const request = db.transaction(tableName, 'readwrite').objectStore(tableName).get(name);
		request.onsuccess = event => resolve(generateResponse(false, event.target.result));
		request.onerror = () => resolve(generateResponse(true, 'Token fetch failed!'));
	}).then(output => {
		return generateResponse(output.hasError, output.data);
	});
}

async function put(data, tableName = tableNames.tokenData) {
	return new Promise(resolve => {
		if (!dbInitalised) {
			resolve(generateResponse(true, 'Cannot fetch tokens before the database has initialised!'));
			return;
		}
		const request = db.transaction(tableName, 'readwrite').objectStore(tableName).put(data);
		request.onsuccess = event => resolve(generateResponse(false, event.target.result));
		request.onerror = () => resolve(generateResponse(true, 'Token fetch failed!'));
	}).then(output => {
		return generateResponse(output.hasError, output.data);
	});
}
