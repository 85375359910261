import moment from 'moment';

const CART_INITIAL_STATE = {
	id: null,
	cart: [],
	children: [],
	requester: {
		email: '',
		requesterName: '',
		requesterMobileNumber: '',
		requesterRole: '',
		requesterCenter: '',
		deliveryPoint: '',
		isUrgent: false,
		requestedDate: moment.utc().toJSON(),
		contactDetails: '',
	},
};

const Cart = (state = CART_INITIAL_STATE, action) => {
	switch (action.type) {
		case 'UPDATE':
			return Object.assign({}, state, action.data);
		case 'CHECKOUT':
			return Object.assign({}, state, CART_INITIAL_STATE);
		default:
			return state;
	}
};

export default Cart;
