import Axios from 'axios';
import UserController from './UserController';
import { APIRoute, APIPath } from '../helpers/Constants';
import { generateResponse } from '../helpers/Utils';

const EmailTextController = {
	getEmailText,
	editEmailText,
};

async function getEmailText() {
	return Axios.get(APIRoute + APIPath.GET_EMAIL_TEXT)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function editEmailText(text) {
	const userHeaders = await UserController.getUserHeaders();
	const data = {
		Text: text,
	};
	return Axios.post(APIRoute + APIPath.EDIT_EMAIL_TEXT, data, userHeaders)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

export default EmailTextController;
