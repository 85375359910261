import React from 'react';
import PropTypes from 'prop-types';
import ProductController from '../../controllers/ProductController';
import {
	Container,
	Typography,
	Grid,
	Table,
	TableHead,
	TableRow,
	TableCell,
	Button,
	TableBody,
	Dialog,
	DialogTitle,
	Divider,
	DialogContent,
	DialogActions,
	makeStyles,
} from '@material-ui/core';
import { LoadingOverlay } from '../../components/Common/LoadingOverlay';
import { Alert } from '@material-ui/lab';
import { Delete, ArrowUpward, ArrowDownward, Add, Edit } from '@material-ui/icons';
import { isNullOrUndefined, getQueryStringParam } from '../../helpers/Utils';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(() => ({
	formControl: {
		width: '100%',
	},
	displayColour: {
		height: '50px',
		width: '50px',
		border: 'solid 1px black',
		borderRadius: '5px',
	},
	inlineTableHeader: {
		display: 'flex',
	},
}));

export default function ProductListView(props) {
	const [loading, setLoading] = React.useState(true);
	const [warningText, setWarningText] = React.useState(null);
	const [Products, setProducts] = React.useState([]);
	const [sortBy, setSortBy] = React.useState('name');
	const [sortDes, setSortDes] = React.useState(false);

	const [adding, SetAdding] = React.useState(false);

	const [showDelete, setShowDelete] = React.useState(false);
	const [editValue, setEditValue] = React.useState(null);

	const classes = useStyles();

	// initialise
	React.useEffect(() => {
		async function init() {
			const response = await ProductController.getProducts();
			if (response.hasError) {
				HandleError(response.data.response);
			} else {
				setProducts(response.data);
				const productId = getQueryStringParam('Id', props.location);
				if (!isNullOrUndefined(productId)) {
					setTimeout(() => {
						const element = document.getElementById(productId);
						element.scrollIntoView(true);
					}, 100);
				}
			}
			setLoading(false);
		}
		init();
	}, [setProducts]);

	async function ConfirmDeleteProduct() {
		SetAdding(true);
		const response = await ProductController.deleteProduct(editValue.id);
		if (response.hasError) {
			SetAdding(false);
			HandleError(response.data.response);
			return;
		}
		SetAdding(false);
		const currentProducts = Products;
		currentProducts.splice(currentProducts.indexOf(editValue), 1);
		setProducts([...currentProducts]);
		setShowDelete(false);
		setWarningText(null);
	}

	function HandleError(response) {
		if (isNullOrUndefined(response)) {
			return 'Failed to connect to server. Please check your internet connection';
		}
		if (isNullOrUndefined(response.response) || isNullOrUndefined(response.response.data)) {
			setWarningText(response.message);
			return;
		}
		setWarningText(response.response.data);
	}

	function DeleteProduct(value) {
		setShowDelete(true);
		setEditValue(value);
	}

	function CloseDelete() {
		setShowDelete(false);
		setEditValue(null);
		setWarningText(null);
	}

	function HandleTableHeaderClick(headerValue) {
		if (sortBy === headerValue) {
			setSortDes(!sortDes);
			return;
		}
		setSortBy(headerValue);
		setSortDes(false);
	}

	function descendingComparator(a, b, orderBy) {
		if (b[orderBy] < a[orderBy]) {
			return -1;
		}
		if (b[orderBy] > a[orderBy]) {
			return 1;
		}
		return 0;
	}

	function buildSortIcon(headerName) {
		if (sortBy !== headerName) {
			return null;
		}
		if (sortDes) {
			return <ArrowUpward />;
		}
		return <ArrowDownward />;
	}

	function buildTable() {
		const sortMod = sortDes ? 1 : -1;
		return (
			<Table>
				<TableHead>
					<TableRow>
						<TableCell
							onClick={() => {
								HandleTableHeaderClick('name');
							}}
						>
							<div className={classes.inlineTableHeader}> {buildSortIcon('name')} Name</div>
						</TableCell>
						<TableCell
							onClick={() => {
								HandleTableHeaderClick('isAvailable');
							}}
						>
							<div className={classes.inlineTableHeader}>
								{buildSortIcon('isAvailable')} Is Available{' '}
							</div>
						</TableCell>
						<TableCell
							onClick={() => {
								HandleTableHeaderClick('schools');
							}}
						>
							<div className={classes.inlineTableHeader}>{buildSortIcon('schools')} Schools </div>
						</TableCell>
						<TableCell
							onClick={() => {
								HandleTableHeaderClick('productCategoryName');
							}}
						>
							<div className={classes.inlineTableHeader}>
								{buildSortIcon('productCategoryName')} Product Category{' '}
							</div>
						</TableCell>
						<TableCell></TableCell>
						<TableCell>
							<Button component={Link} to={'/ProductEditor'}>
								<Add />
							</Button>{' '}
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{Products.sort((a, b) => {
						return descendingComparator(a, b, sortBy) * sortMod;
					}).map(value => {
						return (
							<TableRow key={value.id} id={value.id}>
								<TableCell>{value.name}</TableCell>
								<TableCell>{value.isAvailable ? 'Yes' : 'No'}</TableCell>
								<TableCell>{value.schools}</TableCell>
								<TableCell>{value.productCategoryName}</TableCell>
								<TableCell align="right">
									<Button component={Link} to={'/ProductEditor?productId=' + value.id}>
										<Edit />
									</Button>
								</TableCell>
								<TableCell align="right">
									<Button
										onClick={() => {
											DeleteProduct(value);
										}}
									>
										<Delete />
									</Button>
								</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
		);
	}

	function buildDeleteModal() {
		return (
			<Dialog
				open={showDelete}
				onClose={() => {
					CloseDelete();
				}}
			>
				<DialogTitle>Delete Product</DialogTitle>
				<Divider />
				<DialogContent>
					<p>You are permanently deleting {editValue?.name}</p>
					{buildWarning()}
				</DialogContent>
				<Divider />
				<DialogActions>
					<Button onClick={() => ConfirmDeleteProduct()} disabled={adding}>
						{adding ? 'Deleting' : 'Delete'}
					</Button>
					<Button
						onClick={() => {
							CloseDelete();
						}}
					>
						Close
					</Button>
				</DialogActions>
			</Dialog>
		);
	}

	function buildWarning() {
		if (isNullOrUndefined(warningText)) return null;
		return <Alert severity="warning">{warningText}</Alert>;
	}

	return (
		<Container>
			<LoadingOverlay loading={loading} />

			<Typography variant="h3" align="center" gutterBottom>
				Products
			</Typography>
			{buildDeleteModal()}
			<Grid item xs={12}>
				{buildWarning()}
			</Grid>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					{buildTable()}
				</Grid>
			</Grid>
		</Container>
	);
}

ProductListView.propTypes = {
	location: PropTypes.object,
};
