import React, { Component } from 'react';
import PropTypes from 'prop-types';
import UserController from '../controllers/UserController';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';
import { Role } from '../helpers/Constants';
import { PrivateRoute } from '../components/Common/PrivateRoute';
import { Layout } from '../domain/Layout/Layout';
import { LogIn, LogOut } from '../stores/Actions/Authentication';
import { Update } from '../stores/Actions/Cart';
import { IndexedDB } from '../helpers/IndexedDB';
import { isNullOrUndefined } from '../helpers/Utils';
import { CartController } from '../controllers/CartController';

// Pages
import Home from '../domain/Home/Home';
import Login from '../domain/Auth/Login';
import Logout from '../domain/Auth/Logout';
import Register from '../domain/Auth/Register';
import FileManager from '../domain/User/FileManager';
import Profile from '../domain/User/Profile';
import PasswordChange from '../domain/User/PasswordChange';
import EmailConfirm from '../domain/User/EmailConfirm';
import EmailChange from '../domain/User/EmailChange';
import EmailChangeConfirm from '../domain/User/EmailChangeConfirm';
import PasswordForgot from '../domain/User/PasswordForgot';
import PasswordReset from '../domain/User/PasswordReset';
import TwoFactorManager from '../domain/User/TwoFactorManager';
import TwoFactorVerify from '../domain/User/TwoFactorVerify';
import Admin from '../domain/Admin/Admin';
import NotFound from '../domain/Error/NotFound';
import NotificationList from '../domain/User/NotificationList';
import SchoolEditor from '../domain/Admin/SchoolEditor';
import DeliveryPointEditor from '../domain/Admin/DeliveryPointEditor';
import VariationTypeEditor from '../domain/Admin/VariationTypeEditor';
import VariationEditor from '../domain/Admin/VariationEditor';
import ProductListView from '../domain/Admin/ProductListView';
import ProductEditor from '../domain/Admin/ProductEditor';
import ChildEditor from '../domain/ProductRequest/ChildEditor';
import AdditionalInfo from '../domain/ProductRequest/AdditionalInfo';
import Products from '../domain/ProductRequest/Products';
import Checkout from '../domain/ProductRequest/Checkout';
import RequestSent from '../domain/ProductRequest/RequestSent';
import ProductRequestView from '../domain/Admin/ProductRequestView';
import ProductCategoryEditor from '../domain/Admin/ProductCategoryEditor';
import HomePageTextEditor from '../domain/Admin/HomePageTextEditor';
import AdditionalInfoEditor from '../domain/Admin/AdditionalInfoEditor';
import EmailTextEditor from '../domain/Admin/EmailTextEditor';
import Settings from '../domain/Admin/Settings';

class App extends Component {
	constructor(props) {
		super(props);
		this.initTokenStore(props);
	}

	initTokenStore = async props => {
		// initialise local data store
		const init = await IndexedDB.init();
		if (init.hasError) {
			console.warn(init.data);
			return;
		}
		// check for existing token
		const userData = await UserController.fetchCachedUserData();
		if (userData.hasError) {
			console.warn(userData.data);
			props.LogOut();
			return;
		}
		// save cached data if any
		const { userName, role } = userData.data;
		if (!isNullOrUndefined(userName)) {
			props.LogIn({
				userName,
				role,
				isLoggingIn: false,
				isAuthenticated: true,
			});
		} else {
			props.LogOut();
		}
		//Init cart data
		await CartController.GetCartData(props.UpdateCart);
	};

	render() {
		if (this.props.Auth.isLoggingIn) {
			return <div />;
		}
		return (
			<Layout>
				<Switch>
					<Route exact path="/" component={Home} />
					<Route path="/Login" component={Login} />
					<Route path="/Logout" component={Logout} />
					<Route path="/Register" component={Register} />
					<Route path="/Notifications" component={NotificationList} />
					<Route path="/FileManagement" component={FileManager} />
					<Route path="/UserManagement" component={Profile} />
					<Route path="/ChangePassword" component={PasswordChange} />
					<Route path="/EmailConfirmation" component={EmailConfirm} />
					<Route path="/ChangeEmail" component={EmailChange} />
					<Route path="/ChangeEmailConfirmation" component={EmailChangeConfirm} />
					<Route path="/ForgotPassword" component={PasswordForgot} />
					<Route path="/ResetPassword" component={PasswordReset} />
					<Route path="/ManageTwoFactor" component={TwoFactorManager} />
					<Route path="/VerifyTwoFactor" component={TwoFactorVerify} />
					<Route path="/Children" component={ChildEditor} />
					<Route path="/AdditionalInfo" component={AdditionalInfo} />
					<Route path="/Products" component={Products} />
					<Route path="/Checkout" component={Checkout} />
					<Route path="/RequestSent" component={RequestSent} />
					<PrivateRoute path="/Admin" role={this.props.Auth.role} roles={[Role.Admin]} component={Admin} />
					<PrivateRoute
						path="/Schools"
						role={this.props.Auth.role}
						roles={[Role.Admin]}
						component={SchoolEditor}
					/>
					<PrivateRoute
						path="/DeliveryPoint"
						role={this.props.Auth.role}
						roles={[Role.Admin]}
						component={DeliveryPointEditor}
					/>
					<PrivateRoute
						path="/VariationType"
						role={this.props.Auth.role}
						roles={[Role.Admin]}
						component={VariationTypeEditor}
					/>
					<PrivateRoute
						path="/Variation"
						role={this.props.Auth.role}
						roles={[Role.Admin]}
						component={VariationEditor}
					/>
					<PrivateRoute
						path="/ProductList"
						role={this.props.Auth.role}
						roles={[Role.Admin]}
						component={ProductListView}
					/>
					<PrivateRoute
						path="/ProductEditor"
						role={this.props.Auth.role}
						roles={[Role.Admin]}
						component={ProductEditor}
					/>
					<PrivateRoute
						path="/ProductRequests"
						role={this.props.Auth.role}
						roles={[Role.Admin]}
						component={ProductRequestView}
					/>
					<PrivateRoute
						path="/ProductCategoryEditor"
						role={this.props.Auth.role}
						roles={[Role.Admin]}
						component={ProductCategoryEditor}
					/>
					<PrivateRoute
						path="/HomePageTextEditor"
						role={this.props.Auth.role}
						roles={[Role.Admin]}
						component={HomePageTextEditor}
					/>
					<PrivateRoute
						path="/AdditionalInfoEditor"
						role={this.props.Auth.role}
						roles={[Role.Admin]}
						component={AdditionalInfoEditor}
					/>
					<PrivateRoute
						path="/EmailTextEditor"
						role={this.props.Auth.role}
						roles={[Role.Admin]}
						component={EmailTextEditor}
					/>
					<PrivateRoute
						path="/Settings"
						role={this.props.Auth.role}
						roles={[Role.Admin]}
						component={Settings}
					/>
					<Route path="" component={NotFound} />
				</Switch>
			</Layout>
		);
	}
}

const mapStateToProps = state => ({
	Auth: state.Authentication,
});

const mapDispatchToProps = dispatch => ({
	LogIn: data => dispatch(LogIn(data)),
	LogOut: () => dispatch(LogOut()),
	UpdateCart: data => dispatch(Update(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);

App.propTypes = {
	Auth: PropTypes.object,
	LogIn: PropTypes.func,
	LogOut: PropTypes.func,
	UpdateCart: PropTypes.func,
};
