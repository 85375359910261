import Axios from 'axios';
import UserController from './UserController';
import { APIRoute, APIPath } from '../helpers/Constants';
import { generateResponse } from '../helpers/Utils';

const ProductCategoryController = {
	getProductCategories,
	addProductCategory,
	editProductCategory,
	deleteProductCategory,
};

async function getProductCategories() {
	return Axios.get(APIRoute + APIPath.GET_PRODUCT_CATEGORY)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function addProductCategory(ProductCategoryName, OrderIndex) {
	const userHeaders = await UserController.getUserHeaders();
	const data = {
		Name: ProductCategoryName,
		OrderIndex: Number(OrderIndex),
	};
	return Axios.post(APIRoute + APIPath.ADD_PRODUCT_CATEGORY, data, userHeaders)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function editProductCategory(id, ProductCategoryName, OrderIndex) {
	const userHeaders = await UserController.getUserHeaders();
	const data = {
		Name: ProductCategoryName,
		OrderIndex: Number(OrderIndex),
	};
	return Axios.post(APIRoute + APIPath.EDIT_PRODUCT_CATEGORY + id, data, userHeaders)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function deleteProductCategory(id) {
	const userHeaders = await UserController.getUserHeaders();

	return Axios.delete(APIRoute + APIPath.DELETE_PRODUCT_CATEGORY + id, userHeaders)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

export default ProductCategoryController;
