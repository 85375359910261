import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SettingsController from '../../controllers/SettingsController.js';
import { Role } from '../../helpers/Constants.js';
import { Box, Typography } from '@material-ui/core';
import { push } from 'connected-react-router';

function SettingsChecker(props) {
	const [path, setPath] = useState('route');
	const [showBanner, setShowBanner] = useState(false);
	const { PushHistory } = props;
	const { role } = props.LogIn;
	const isAdmin = role && role.includes(Role.Admin);

	const forbiddenPaths = ['/Children', '/AdditionalInfo', '/Products', '/Checkout', '/RequestSent'];

	const isForbidden = forbiddenPaths.includes(window.location.pathname);

	useEffect(() => {
		async function init() {
			await fetchSettings();
		}
		init();
	}, []);

	const fetchSettings = async () => {
		const settingsResponse = await SettingsController.getSettings();
		if (!settingsResponse.hasError) {
			const { disableBookingForm } = settingsResponse.data;

			const bannerActive = isAdmin && disableBookingForm;

			setShowBanner(bannerActive);

			if (!isAdmin && disableBookingForm && isForbidden) {
				PushHistory('/');
			}

			return;
		}
	};

	const reFetchSettings = () => {
		setTimeout(() => {
			fetchSettings();
		}, 500);
	};

	useEffect(() => {
		const checkLocation = () => {
			if (window.location.pathname === path) return;
			setPath(window.location.pathname);
		};

		document.addEventListener('click', checkLocation);

		return () => {
			document.removeEventListener('click', checkLocation);
		};
	}, [path]);

	useEffect(() => {
		const saveSettingsBtn = document.getElementById('saveSettingsBtn');

		if (saveSettingsBtn == null) return;

		saveSettingsBtn.addEventListener('click', reFetchSettings);

		return () => {
			saveSettingsBtn.removeEventListener('click', reFetchSettings);
		};
	}, [path]);

	return (
		<Box
			style={{
				transition: 'all 0.3s',
				opacity: showBanner ? 1 : 0,
				position: 'fixed',
				top: 64,
				left: 0,
				right: 0,
				width: '100%',
				zIndex: 999,
				backgroundColor: 'red',
			}}
		>
			<Typography align="center" style={{ fontSize: 20, color: 'white' }}>
				Booking Form Disabled
			</Typography>
		</Box>
	);
}

const mapStateToProps = state => ({
	LogIn: state.Authentication,
});

const mapDispatchToProps = dispatch => ({
	PushHistory: data => dispatch(push(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsChecker);

SettingsChecker.propTypes = {
	LogIn: PropTypes.object,
	PushHistory: PropTypes.func,
};
