import Axios from 'axios';
import UserController from './UserController';
import { APIRoute, APIPath } from '../helpers/Constants';
import { generateResponse } from '../helpers/Utils';

const VariationTypeController = {
	getVariationTypes,
	addVariationType,
	editVariationType,
	deleteVariationType,
};

async function getVariationTypes() {
	return Axios.get(APIRoute + APIPath.GET_VARIATIONTYPES)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function addVariationType(VariationTypeName, OrderIndex) {
	const userHeaders = await UserController.getUserHeaders();
	const data = {
		Name: VariationTypeName,
		OrderIndex: Number(OrderIndex),
	};
	return Axios.post(APIRoute + APIPath.ADD_VARIATIONTYPE, data, userHeaders)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function editVariationType(id, VariationTypeName, OrderIndex) {
	const userHeaders = await UserController.getUserHeaders();
	const data = {
		Name: VariationTypeName,
		OrderIndex: Number(OrderIndex),
	};
	return Axios.post(APIRoute + APIPath.EDIT_VARIATIONTYPE + id, data, userHeaders)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function deleteVariationType(id) {
	const userHeaders = await UserController.getUserHeaders();

	return Axios.delete(APIRoute + APIPath.DELETE_VARIATIONTYPE + id, userHeaders)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

export default VariationTypeController;
