import Axios from 'axios';
import UserController from './UserController';
import { APIRoute, APIPath } from '../helpers/Constants';
import { generateResponse } from '../helpers/Utils';

const SchoolController = {
	getSchools,
	addSchool,
	editSchool,
	deleteSchool,
};

async function getSchools() {
	return Axios.get(APIRoute + APIPath.GET_SCHOOLS)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function addSchool(schoolName) {
	const userHeaders = await UserController.getUserHeaders();
	const data = {
		Name: schoolName,
	};
	return Axios.post(APIRoute + APIPath.ADD_SCHOOL, data, userHeaders)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function editSchool(id, schoolName) {
	const userHeaders = await UserController.getUserHeaders();
	const data = {
		Name: schoolName,
	};
	return Axios.post(APIRoute + APIPath.EDIT_SCHOOL + id, data, userHeaders)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function deleteSchool(id) {
	const userHeaders = await UserController.getUserHeaders();

	return Axios.delete(APIRoute + APIPath.DELETE_SCHOOL + id, userHeaders)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

export default SchoolController;
