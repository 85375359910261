import Axios from 'axios';
import UserController from './UserController';
import { APIRoute, APIPath } from '../helpers/Constants';
import { generateResponse } from '../helpers/Utils';

const DeliveryPointController = {
	getDeliveryPoints,
	addDeliveryPoint,
	editDeliveryPoint,
	deleteDeliveryPoint,
};

async function getDeliveryPoints() {
	return Axios.get(APIRoute + APIPath.GET_DELIVERYPOINTS)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function addDeliveryPoint(deliveryPointName) {
	const userHeaders = await UserController.getUserHeaders();
	const data = {
		Name: deliveryPointName,
	};
	return Axios.post(APIRoute + APIPath.ADD_DELIVERYPOINT, data, userHeaders)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function editDeliveryPoint(id, deliveryPointName) {
	const userHeaders = await UserController.getUserHeaders();
	const data = {
		Name: deliveryPointName,
	};
	return Axios.post(APIRoute + APIPath.EDIT_DELIVERYPOINT + id, data, userHeaders)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function deleteDeliveryPoint(id) {
	const userHeaders = await UserController.getUserHeaders();

	return Axios.delete(APIRoute + APIPath.DELETE_DELIVERYPOINT + id, userHeaders)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

export default DeliveryPointController;
