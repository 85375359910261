import React from 'react';
import PropTypes from 'prop-types';
import HomePageTextController from '../../controllers/HomePageTextController';
import { Button, Container, Card, CardContent, CardActions, CardHeader, TextField } from '@material-ui/core';
import logo from '../../images/uex-logo.png';
import { isNullOrUndefined } from '../../helpers/Utils';
import { Alert } from '@material-ui/lab';
import { LoadingOverlay } from '../../components/Common/LoadingOverlay';

export default function HomePageTextEditor(props) {
	const [loading, setLoading] = React.useState(false);
	const [warningText, setWarningText] = React.useState(null);
	const [descriptionText, setDescriptionText] = React.useState('');
	const [buttonText, setButtonText] = React.useState('');

	React.useEffect(() => {
		async function init() {
			setLoading(true);
			const homePageResponse = await HomePageTextController.getHomePageText();
			if (homePageResponse.hasError) {
				HandleError(homePageResponse.data);
				setLoading(false);
				return;
			}
			setDescriptionText(homePageResponse.data.text);
			setButtonText(homePageResponse.data.buttonText);
			setLoading(false);
		}
		init();
	}, []);

	function HandleError(response) {
		if (isNullOrUndefined(response)) {
			return 'Failed to connect to server. Please check your internet connection';
		}
		if (isNullOrUndefined(response.response) || isNullOrUndefined(response.response.data)) {
			setWarningText(response.message);
			return;
		}
		setWarningText(response.response.data);
	}

	async function Save() {
		const homePageResponse = await HomePageTextController.editHomePageText(descriptionText, buttonText);
		if (homePageResponse.hasError) {
			HandleError(homePageResponse.data);
			setLoading(false);
			return;
		}
		props.history.push('/');
	}

	function buildWarning() {
		if (isNullOrUndefined(warningText)) return null;
		return <Alert severity="warning">{warningText}</Alert>;
	}

	return (
		<React.Fragment>
			<LoadingOverlay loading={loading} />
			<Container maxWidth="sm">
				<Card variant="outlined">
					<CardContent>
						<TextField
							label="Welcome Text"
							value={descriptionText}
							onChange={e => {
								setDescriptionText(e.target.value);
							}}
							variant="outlined"
							fullWidth={true}
							multiline
							rowsMax={20}
						/>
					</CardContent>
					<CardContent>
						<TextField
							label="Button Text"
							value={buttonText}
							onChange={e => {
								setButtonText(e.target.value);
							}}
							variant="outlined"
							fullWidth={true}
						/>
					</CardContent>
					<CardActions>
						<Button color="primary" variant="contained" fullWidth onClick={Save}>
							Save
						</Button>
						{buildWarning()}
					</CardActions>
				</Card>
			</Container>
		</React.Fragment>
	);
}

HomePageTextEditor.propTypes = {
	history: PropTypes.object,
};
