import React, { useCallback } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import NotificationsController from '../../controllers/NotificationsController';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { makeStyles, Button, Grid, Typography, Container, Paper } from '@material-ui/core';
import { LoadingOverlay } from '../../components/Common/LoadingOverlay';
import { Alert } from '../../components/Common/Alert';

const useStyles = makeStyles(() => ({
	notif: {
		padding: '12px 16px',
	},
}));

function NotificationList(props) {
	const { PushHistory } = props;
	const { isAuthenticated } = props.Auth;
	const [loading, setLoading] = React.useState(true);
	const [notifications, setNotifications] = React.useState([]);
	const [warningText, setWarningText] = React.useState(null);
	const [page, setPage] = React.useState(0);
	const [moreToFind, setMoreToFind] = React.useState(false);
	const classes = useStyles();

	const fetchNotifications = useCallback(async () => {
		setWarningText(null);
		setLoading(true);
		const response = await NotificationsController.getAll(page);
		if (!response.hasError) {
			setNotifications([...notifications, ...response.data]);
			if (response.data.length < 100) {
				setMoreToFind(false);
			} else {
				setPage(page + 1);
			}
		} else {
			setWarningText(response.data);
			setMoreToFind(false);
		}
		setLoading(false);
	}, [notifications, page]);

	// initialise
	React.useEffect(() => {
		async function init() {
			if (!isAuthenticated) {
				PushHistory('/Login');
				return;
			}
			if (notifications.length === 0) {
				await fetchNotifications();
			}
		}
		init();
	}, [isAuthenticated, PushHistory, fetchNotifications, notifications]);

	function buildNotifications() {
		if (notifications.length === 0) {
			return (
				<Grid item xs={12} key="no-notifs">
					<Paper variant="outlined" square className={classes.notif}>
						<Typography variant="body1" color="textSecondary" align="center">
							No notifications
						</Typography>
					</Paper>
				</Grid>
			);
		}
		return notifications.map((e, i) => buildNotification(e, i));
	}

	function buildNotification(notif, index) {
		const { title, message, created } = notif;
		return (
			<Grid item xs={12} key={index}>
				<Paper variant="outlined" square className={classes.notif}>
					<Typography variant="body1" gutterBottom>
						{title}
					</Typography>
					<Typography variant="body2" gutterBottom>
						{message}
					</Typography>
					<Typography variant="caption" color="textSecondary">
						{moment.utc(created).local().format('D MMMM YYYY')}
					</Typography>
				</Paper>
			</Grid>
		);
	}

	function buildFindMoreButton() {
		return (
			<Grid item xs={12}>
				<Button fullWidth variant="contained" disabled={!moreToFind} onClick={() => fetchNotifications()}>
					Load More
				</Button>
			</Grid>
		);
	}

	return (
		<Container maxWidth="sm">
			<LoadingOverlay loading={loading} />

			<Typography variant="h3" align="center" gutterBottom>
				Notifications
			</Typography>

			<Grid container spacing={1}>
				{buildNotifications()}
				<Grid item xs={12}>
					<Alert header="Something went wrong!" text={warningText} />
				</Grid>
				{buildFindMoreButton()}
			</Grid>
		</Container>
	);
}

const mapStateToProps = state => ({
	Auth: state.Authentication,
});
const mapDispatchToProps = dispatch => ({
	PushHistory: data => dispatch(push(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationList);

NotificationList.propTypes = {
	Auth: PropTypes.object,
	PushHistory: PropTypes.func,
};
