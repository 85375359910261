import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Update, CheckOut } from '../../stores/Actions/Cart';
import { CartController } from '../../controllers/CartController';
import {
	Container,
	Typography,
	Button,
	TextField,
	Grid,
	makeStyles,
	FormControl,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	FormControlLabel,
	Checkbox,
	FormLabel,
	RadioGroup,
	Radio,
	InputLabel,
} from '@material-ui/core';
import { LoadingOverlay } from '../../components/Common/LoadingOverlay';
import { isNullOrUndefined } from '../../helpers/Utils';
import { Alert, Autocomplete } from '@material-ui/lab';
import moment from 'moment';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import ProductRequestController from '../../controllers/ProductRequestController';
import DeliveryPointController from '../../controllers/DeliveryPointController';
import { Role } from '../../helpers/Constants';
import SettingsController from '../../controllers/SettingsController';

const useStyles = makeStyles(() => ({
	formControl: {
		width: '100%',
	},
	displayColour: {
		height: 30,
		width: 30,
	},
	image: {
		width: '15vw',
		'& .image': {
			width: '10vw',
			height: '10vw',
		},
	},
	variationDisplay: {
		display: 'flex',
		alignItems: 'center',
		flexWrap: 'wrap',
		justifyContent: 'center',
		'& > div': {
			margin: 5,
		},
	},
	childCheckout: {
		display: 'flex',
		justifyContent: 'flex-end',
		flexWrap: 'wrap',
		'& .MuiFormControl-root': {
			width: '75px',
		},
		'& .remove-btn': {
			backgroundColor: '#FF0000',
		},
		'& .center-val': {
			'& .MuiInputBase-root': {
				borderRadius: 0,
			},
		},
	},
}));

function Checkout(props) {
	const classes = useStyles();
	const [emailConfirmation, setEmailConfirmation] = React.useState('');
	const [loading, setLoading] = React.useState(false);
	const [warningText, setWarningText] = React.useState(null);
	const [fieldsDisabled, setFieldsDisabled] = React.useState(null);

	const isCartEmpty = props.Cart.children.every(
		e => isNullOrUndefined(e.cart) || e.cart?.length === 0 || e.cart?.every(r => r.amount === 0)
	);
	const children = props.Cart.children;
	const requester = props.Cart.requester;
	const { role } = props.LogIn;
	const isAdmin = role && role.includes(Role.Admin);

	const [deliveryPoints, setDeliveryPoints] = React.useState([]);

	React.useEffect(() => {
		async function init() {
			setLoading(true);
			const deliveryPointResponse = await DeliveryPointController.getDeliveryPoints();
			if (deliveryPointResponse.hasError) {
				HandleError(deliveryPointResponse.data);
				setLoading(false);
				return;
			}
			setDeliveryPoints(deliveryPointResponse.data);

			const settingsResponse = await SettingsController.getSettings();
			if (settingsResponse.hasError) {
				HandleError(settingsResponse.data);
				setLoading(false);
				return;
			}

			const disableFields = !isAdmin && settingsResponse.data.disableBookingForm;
			setFieldsDisabled(disableFields);

			setLoading(false);
		}
		init();
	}, []);

	async function SubmitRequest() {
		if (requester.requesterName.trim().length === 0) {
			setWarningText('Please fill in the requester name');
			return;
		}

		if (requester.email?.toLowerCase() !== emailConfirmation.toLowerCase()) {
			setWarningText('The email and email confirmation must be the same');
			return;
		}

		if (requester.requesterRole.trim().length === 0) {
			setWarningText('Please fill in the requester role');
			return;
		}
		if (requester.deliveryPoint.trim().length === 0) {
			setWarningText('Please fill in the delivery point');
			return;
		}
		if (requester.isUrgent && moment.utc().isSameOrAfter(requester.requestedDate, 'date')) {
			setWarningText('Please fill in the urgent delivery date must be after today');
			return;
		}
		if (isNullOrUndefined(requester.requestReason) || requester.requestReason === 0) {
			setWarningText('Please select a reason for using Uniform Exchange at the top of the form');
			return;
		}
		setLoading(true);
		const response = await ProductRequestController.postProductRequest(
			requester.email,
			requester.requesterName,
			requester.requesterMobileNumber,
			requester.requesterRole,
			requester.requesterCenter,
			requester.deliveryPoint,
			requester.isUrgent,
			requester.requestedDate,
			requester.contactDetails,
			requester.requestReason,
			children
		);
		if (response.hasError) {
			HandleError(response.data);
			setLoading(false);
			return;
		}
		await CartController.CheckedOut();
		props.CheckOut();
		props.history.push('/RequestSent');
	}

	function HandleError(response) {
		if (isNullOrUndefined(response)) {
			return 'Failed to connect to server. Please check your internet connection';
		}
		if (isNullOrUndefined(response.response) || isNullOrUndefined(response.response.data)) {
			setWarningText(response.message);
			return;
		}
		setWarningText(response.response.data);
	}

	function handelInput(event, index) {
		const name = event.target.name;
		const value = event.target.value;

		//We Just update the react store here to not hammer the DB

		switch (name) {
			case 'Notes':
				children[index].Notes = value;
				props.UpdateCart(props.Cart);
				break;
			default:
				return;
		}
	}

	function handleInput(event) {
		const name = event.target.name;
		const value = event.target.value;

		switch (name) {
			case 'Email':
				props.Cart.requester.email = value;
				props.UpdateCart(props.Cart);
				break;
			case 'RequesterName':
				props.Cart.requester.requesterName = value;
				props.UpdateCart(props.Cart);
				break;
			case 'RequesterMobileNumber':
				props.Cart.requester.requesterMobileNumber = value;
				props.UpdateCart(props.Cart);
				break;
			case 'RequesterRole':
				props.Cart.requester.requesterRole = value;
				props.UpdateCart(props.Cart);
				break;
			case 'RequesterCenter':
				props.Cart.requester.requesterCenter = value;
				props.UpdateCart(props.Cart);
				break;
			case 'DeliveryPoint':
				props.Cart.requester.deliveryPoint = value;
				props.UpdateCart(props.Cart);
				break;
			case 'ContactDetails':
				props.Cart.requester.contactDetails = value;
				props.UpdateCart(props.Cart);
				break;
			case 'IsUrgent':
				props.Cart.requester.isUrgent = event.target.checked;
				props.UpdateCart(props.Cart);
				break;
			case 'requestReason':
				props.Cart.requester.requestReason = value;
				props.UpdateCart(props.Cart);
				break;
			default:
				return;
		}
	}

	function SaveRequesterInfo() {
		CartController.UpdateCart(props.Cart, props.UpdateCart);
		props.history.push('/Products');
	}

	function handelCartUpdate(cartItem, amount) {
		amount = Number(amount);
		if (amount < 0) {
			amount = 0;
		}
		cartItem.amount = amount;
		CartController.UpdateCart(props.Cart, props.UpdateCart);
	}

	function buildWarning() {
		if (isNullOrUndefined(warningText)) return null;
		return <Alert severity="warning">{warningText}</Alert>;
	}

	function buildUIObjectDisplay(variation) {
		if (isNullOrUndefined(variation.variationTypeName)) {
			return variation.name;
		}
		switch (variation.variationTypeName) {
			case 'Colour':
				if (isNullOrUndefined(variation.uiObject) || !variation.uiObject.includes('#')) {
					return variation.name;
				}
				return <div style={{ backgroundColor: variation.uiObject }} className={classes.displayColour}></div>;
			default:
				return variation.name;
		}
	}

	function sortProductsByCategory(a, b) {
		return (
			a?.product?.productCategoryOrderIndex - b?.product?.productCategoryOrderIndex ||
			a?.product?.name.localeCompare(b?.product?.name, 'en', {
				sensitivity: 'base',
			})
		);
	}

	function buildTable() {
		return (
			<Table>
				<TableHead>
					<TableRow>
						<TableCell></TableCell>
						<TableCell></TableCell>
						<TableCell></TableCell>
						<TableCell></TableCell>
						<TableCell></TableCell>
						<TableCell></TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{children.map((child, childIndex) => {
						const childHeader = (
							<TableRow key={'child-' + childIndex}>
								<TableCell colSpan={6}>
									<Typography variant="h4" align="center" gutterBottom>
										{child.FirstName} - {child.SchoolFullName}
									</Typography>
								</TableCell>
							</TableRow>
						);
						const products = isNullOrUndefined(child.cart)
							? []
							: child.cart.sort(sortProductsByCategory).map((cartItem, cartItemIndex) => {
									return (
										<TableRow key={'child-' + childIndex + 'cart-' + cartItemIndex}>
											<TableCell>{cartItem.product.name} </TableCell>
											<TableCell>{cartItem.product.productCategoryName} </TableCell>
											<TableCell className={classes.image}>
												{cartItem.product.images.length === 0 ? null : (
													<img
														src={cartItem.product.images[0].imagePath}
														className={classes.image}
													/>
												)}
											</TableCell>
											<TableCell>
												{' '}
												<div className={classes.variationDisplay}>
													{' '}
													{cartItem.stock.variations.map((variation, variationIndex) => {
														return (
															<div
																key={
																	'child-' +
																	childIndex +
																	'cart-' +
																	cartItemIndex +
																	'vair-' +
																	variationIndex
																}
															>
																{buildUIObjectDisplay(variation)}
															</div>
														);
													})}{' '}
												</div>{' '}
											</TableCell>
											<TableCell>
												<div className={classes.childCheckout}>
													<Button
														variant="contained"
														color="secondary"
														onClick={() => {
															handelCartUpdate(cartItem, cartItem.amount == 1 ? 0 : 1);
														}}
														className={cartItem.amount == 1 ? 'remove-btn' : 'add-btn'}
													>
														{cartItem.amount == 1 ? 'Remove from cart' : 'Add to cart'}
													</Button>
												</div>
											</TableCell>
											<TableCell align="right"></TableCell>
										</TableRow>
									);
							  });
						const childNotes = (
							<TableRow key={'childNotes' + childIndex}>
								<TableCell colSpan={6}>
									<TextField
										id={'Notes' + childIndex}
										label="Additional Information"
										value={child.Notes}
										onChange={e => {
											handelInput(e, childIndex);
										}}
										variant="outlined"
										name="Notes"
										fullWidth={true}
										multiline
										rowsMax={4}
										helperText="Specify alternative items or colours your child could use or additional information relevant to the request."
									/>
								</TableCell>
							</TableRow>
						);

						return [childHeader, ...products, childNotes];
					})}
				</TableBody>
			</Table>
		);
	}

	function buildCheckoutForum() {
		return [
			<Grid style={{ pointerEvents: fieldsDisabled ? 'none' : 'auto' }} item xs={12} key={'RequestReason'}>
				<FormControl component="fieldset">
					<FormLabel component="legend">Why are you using Uniform Exchange today?</FormLabel>
					<RadioGroup
						aria-label="Request Reason"
						name="requestReason"
						value={requester.requestReason}
						onChange={handleInput}
					>
						<FormControlLabel
							value="1"
							control={<Radio />}
							label="To reduce the financial burden of purchasing school uniform"
						/>
						<FormControlLabel
							value="2"
							control={<Radio />}
							label="To reduce waste and use a sustainable option"
						/>
					</RadioGroup>
				</FormControl>
			</Grid>,
			<Grid item xs={12} key={'RequesterEmail'}>
				<TextField
					id={'RequesterEmail'}
					label="Requester Email *"
					value={requester.email}
					onChange={handleInput}
					variant="outlined"
					name="Email"
					fullWidth={true}
				/>
			</Grid>,
			<Grid item xs={12} key={'RequesterEmailConfirmation'}>
				<TextField
					id={'RequesterEmail'}
					label="Requester Email Confirmation *"
					value={emailConfirmation}
					onChange={e => {
						setEmailConfirmation(e.target.value);
					}}
					variant="outlined"
					name="Email Confirmation"
					fullWidth={true}
					error={requester.email?.toLowerCase() !== emailConfirmation.toLowerCase()}
					helperText={
						requester.email?.toLowerCase() !== emailConfirmation.toLowerCase()
							? 'Please enter the same email as above'
							: undefined
					}
				/>
			</Grid>,
			<Grid item xs={12} key={'RequesterName'}>
				<TextField
					id={'RequesterName'}
					label="Requester Name *"
					value={requester.requesterName}
					onChange={handleInput}
					variant="outlined"
					name="RequesterName"
					fullWidth={true}
				/>
			</Grid>,
			<Grid item xs={12} key={'RequesterMobileNumber'}>
				<TextField
					id={'RequesterMobileNumber'}
					label="Requester Mobile Number"
					value={requester.requesterMobileNumber}
					onChange={handleInput}
					variant="outlined"
					name="RequesterMobileNumber"
					fullWidth={true}
				/>
			</Grid>,
			<Grid item xs={12} key={'RequesterRole'}>
				<TextField
					id={'RequesterRole'}
					label="Requester Role *"
					value={requester.requesterRole}
					onChange={handleInput}
					variant="outlined"
					name="RequesterRole"
					fullWidth={true}
				/>
			</Grid>,
			<Grid item xs={12} key={'RequesterCenter'}>
				<TextField
					id={'RequesterCenter'}
					label="Requester Workplace"
					value={requester.requesterCenter}
					onChange={handleInput}
					variant="outlined"
					name="RequesterCenter"
					fullWidth={true}
					helperText="Only complete if requesting through an agency or school on behalf of a family"
				/>
			</Grid>,
			<Grid item xs={12} key={'DeliveryPoint'}>
				<FormControl variant="outlined" fullWidth>
					<InputLabel id="DeliveryPoint">Delivery Point</InputLabel>
					<Autocomplete
						options={deliveryPoints.sort((a, b) => (a.name < b.name ? -1 : 1)).map(e => e.name)}
						value={requester.deliveryPoint}
						fullWidth
						renderInput={params => (
							<TextField
								{...params}
								InputLabelProps={{
									shrink: true,
								}}
								variant="outlined"
							/>
						)}
						getOptionLabel={e =>
							deliveryPoints ? deliveryPoints?.find(el => el.name === e)?.name ?? '' : []
						}
						onChange={(_, v) => {
							props.Cart.requester.deliveryPoint = v;
							props.UpdateCart(props.Cart);
						}}
					/>
				</FormControl>
			</Grid>,
			<Grid item xs={12} key={'ContactDetails'}>
				<TextField
					id={'ContactDetails'}
					label="Family Contact Details *"
					value={requester.contactDetails}
					onChange={handleInput}
					variant="outlined"
					name="ContactDetails"
					fullWidth={true}
					multiline={true}
					rows={5}
					helperText="Name, Address, Email & Mobile (Due to the closure of the libraries a 3rd party may need to deliver the uniform parcel and your address will be shared with them)."
				/>
			</Grid>,
			<Grid item xs={12} key={'IsUrgent'}>
				<FormControl variant="filled" className={classes.formControl}>
					<FormControlLabel
						control={
							<Checkbox
								label="Is Urgent"
								checked={requester.isUrgent}
								onChange={handleInput}
								color="primary"
								variant="outlined"
								name="IsUrgent"
							/>
						}
						label="Is Urgent"
					/>
				</FormControl>
			</Grid>,
			requester.isUrgent === true ? (
				<Grid item xs={12} key={'UrgentPicker'}>
					<MuiPickersUtilsProvider utils={MomentUtils}>
						<div>Requested Date:</div>
						<DatePicker
							value={moment(requester.requestedDate)}
							onChange={value => {
								props.Cart.requester.requestedDate = value.toJSON();
								props.UpdateCart(props.Cart);
							}}
						/>
					</MuiPickersUtilsProvider>
				</Grid>
			) : null,
			<Button
				onClick={SubmitRequest}
				key="SubmitButton"
				color="primary"
				variant="contained"
				disabled={isCartEmpty || fieldsDisabled}
				fullWidth
			>
				Submit
			</Button>,
		];
	}

	return (
		<Container>
			<LoadingOverlay loading={loading} />
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography variant="h3" align="center" gutterBottom>
						Submit
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Button onClick={SaveRequesterInfo} color="primary" variant="contained" fullWidth>
						Go back
					</Button>
				</Grid>
				<Grid item xs={12}>
					{buildTable()}
				</Grid>

				{buildCheckoutForum()}

				<Grid item xs={12}>
					{buildWarning()}
				</Grid>
			</Grid>
		</Container>
	);
}

const mapStateToProps = state => ({
	Cart: state.Cart,
	LogIn: state.Authentication,
});

const mapDispatchToProps = dispatch => ({
	UpdateCart: data => dispatch(Update(data)),
	CheckOut: () => dispatch(CheckOut()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);

Checkout.propTypes = {
	Cart: PropTypes.object,
	UpdateCart: PropTypes.func,
	history: PropTypes.object,
	CheckOut: PropTypes.func,
	LogIn: PropTypes.object,
};
