import { IndexedDB, tableNames } from '../helpers/IndexedDB';
import { isNullOrUndefined } from '../helpers/Utils';
import moment from 'moment';

export const CartController = {
	GetCartData,
	UpdateCart,
	CheckedOut,
};

async function GetCartData(storeCallback) {
	const response = await IndexedDB.fetch('current', tableNames.cartData);
	if (response.hasError || isNullOrUndefined(response.data)) {
		return {
			id: 'current',
			cart: [],
			children: [],
			requester: {
				email: '',
				requesterName: '',
				requesterMobileNumber: '',
				requesterRole: '',
				requesterCenter: '',
				deliveryPoint: '',
				isUrgent: '',
				requestedDate: moment.utc().toJSON(),
				contactDetails: '',
			},
		};
	}
	const data = {
		id: response.data.id,
		cart: JSON.parse(response.data.cart),
		children: JSON.parse(response.data.children),
		requester: {
			email: '',
			requesterName: '',
			requesterRole: '',
			requesterCenter: '',
			deliveryPoint: '',
			isUrgent: '',
			requestedDate: moment.utc().toJSON(),
			contactDetails: '',
		},
	};
	if (!isNullOrUndefined(response.data.requester) && response.data.requester.length != 0) {
		data.requester = JSON.parse(response.data.requester);
	}
	storeCallback(data);
	return data;
}

async function UpdateCart(data, storeCallback) {
	const tokenData = {
		id: data.id,
		cart: JSON.stringify(data.cart),
		children: JSON.stringify(data.children),
		requester: JSON.stringify(data.requester),
	};
	if (isNullOrUndefined(data.id)) {
		data.id = 'current';
	}
	storeCallback(data);
	if (isNullOrUndefined(tokenData.id)) {
		tokenData.id = 'current';
		return await IndexedDB.add(tokenData, tableNames.cartData);
	}
	return await IndexedDB.put(tokenData, tableNames.cartData);
}

async function CheckedOut() {
	await IndexedDB.remove('current', tableNames.cartData);
}
