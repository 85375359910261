import Axios from 'axios';
import UserController from './UserController';
import { APIRoute, APIPath } from '../helpers/Constants';
import { generateResponse } from '../helpers/Utils';

const FileController = {
	getUsersFiles,
	uploadFile,
};

async function getUsersFiles() {
	const userHeaders = await UserController.getUserHeaders();
	return Axios.get(APIRoute + APIPath.FILE_GET_USERS, userHeaders)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function uploadFile(file) {
	const userHeaders = await UserController.getUserHeaders('multipart/form-data');
	const formData = new FormData();
	formData.append('file', file);
	return Axios.post(APIRoute + APIPath.FILE_UPlOAD, formData, userHeaders)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

export default FileController;
