import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { isNullOrUndefined } from '../../helpers/Utils';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles(() => ({
	root: {
		margin: 5,
		'& .react-swipeable-view-container': {
			'& img': {
				height: props => props.height,
				display: 'block',
				overflow: 'hidden',
				width: '100%',
				objectFit: 'contain',
			},
			'& svg': {
				height: props => props.height,
				display: 'block',
				overflow: 'hidden',
				width: '100%',
				objectFit: 'contain',
			},
		},
		'& .MuiMobileStepper-positionStatic': {
			display: 'none',
		},
	},
	imagePicker: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		padding: 10,
		overflowX: 'scroll',
		'& img': {
			height: props => props.height * 0.15,
			display: 'block',
			overflow: 'hidden',
			width: '100%',
			objectFit: 'contain',
		},
		'& svg': {
			height: props => props.height * 0.15,
			display: 'block',
			overflow: 'hidden',
			width: '100%',
			objectFit: 'contain',
		},
		'& .imageSelector': {
			width: '25%',
			padding: 2,
		},
		'& .selected': {
			border: 'solid 2px #000000',
		},
	},
	clickableItem: {
		width: '100%',
		height: '100%',
		display: 'block',
		cursor: 'pointer',
	},
}));

function Carousel(props) {
	const height = isNullOrUndefined(props.imageHeight) ? 255 : props.imageHeight;
	const classes = useStyles({ height: height });
	const [activeStep, setActiveStep] = React.useState(0);
	const maxSteps = props.items.length;
	const onImageClick = props.onImageClick;

	const handleStepChange = step => {
		setActiveStep(step);
	};

	return (
		<div className={classes.root}>
			<AutoPlaySwipeableViews
				axis="x"
				index={activeStep}
				onChangeIndex={handleStepChange}
				enableMouseEvents
				interval={5000}
			>
				{props.items.map((value, index) => {
					return isNullOrUndefined(onImageClick) ? (
						value
					) : (
						<a key={'display' + index} className={classes.clickableItem} onClick={onImageClick}>
							{value}
						</a>
					);
				})}
			</AutoPlaySwipeableViews>
			<MobileStepper
				steps={maxSteps}
				position="static"
				variant="dots"
				activeStep={activeStep}
				nextButton={null}
				backButton={null}
			/>
			{props.items.length > 1 ? (
				<div className={classes.imagePicker}>
					{props.items.map((value, index) => {
						const selected = index === activeStep ? ' selected' : '';
						return (
							<div
								key={'picker' + index}
								className={'imageSelector' + selected}
								onClick={() => handleStepChange(index)}
							>
								{value}
							</div>
						);
					})}
				</div>
			) : null}
		</div>
	);
}

export default Carousel;

Carousel.propTypes = {
	items: PropTypes.array.isRequired,
	imageHeight: PropTypes.number,
	onImageClick: PropTypes.func,
};
