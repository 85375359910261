import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import queryString from 'query-string';
import { Alert } from '../../components/Common/Alert';
import { isNullOrUndefined } from 'util';
import UserController from '../../controllers/UserController';
import { checkPassword } from '../../helpers/Utils';
import { Button, Container, TextField, Typography, Grid } from '@material-ui/core';

function PasswordReset(props) {
	const [password, setPassword] = React.useState('');
	const [passwordConfirmation, setPasswordConfirmation] = React.useState('');
	const [warningText, setWarningText] = React.useState(null);
	const [passwordRequirements, setPasswordRequirements] = React.useState(null);
	const [passwordError, setPasswordError] = React.useState(null);
	const [redirectUrl, setRedirectUrl] = React.useState(null);
	const [submitting, setSubmitting] = React.useState(false);
	const values = queryString.parse(props.location.search);

	// initialise
	React.useEffect(() => {
		async function init() {
			if (props.Auth.isAuthenticated) {
				props.PushHistory('/');
			}
			const pwordReqResponse = await UserController.getPasswordRequirements();
			if (!pwordReqResponse.hasError) {
				setPasswordRequirements(pwordReqResponse.data);
			} else {
				setWarningText('Failed to fetch password requirements');
			}
		}
		init();
	}, [props]);

	// redirect
	React.useEffect(() => {
		if (isNullOrUndefined(redirectUrl)) {
			return;
		}
		props.PushHistory(redirectUrl);
	}, [redirectUrl, props]);

	async function handleSubmit(event) {
		event.preventDefault();
		setSubmitting(true);
		setWarningText(null);

		const { userId, emailCode } = values;
		const response = await UserController.resetPassword(userId, emailCode, password, passwordConfirmation);
		if (response.hasError) {
			setWarningText(response.data);
			setSubmitting(false);
		} else {
			setRedirectUrl('/Login');
		}
	}

	function handleInput(event) {
		const name = event.target.name;
		const value = event.target.value;

		switch (name) {
			case 'password':
				setPassword(value);
				break;
			case 'passwordConfirmation':
				setPasswordConfirmation(value);
				break;
			default:
				break;
		}
	}

	async function checkPasswordIsValid() {
		setPasswordError(checkPassword(passwordRequirements, password));
	}

	return (
		<Container maxWidth="xs">
			<form onSubmit={handleSubmit}>
				<Typography align="center" variant="h3" gutterBottom>
					Reset Password
				</Typography>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<TextField
							id="password-input"
							label="Password"
							type="password"
							value={password}
							onChange={handleInput}
							name="password"
							onBlur={() => checkPasswordIsValid()}
							helperText={passwordError}
							fullWidth
							required
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							id="confirm-password-input"
							label="Confirm Password"
							type="password"
							value={passwordConfirmation}
							onChange={handleInput}
							name="passwordConfirmation"
							fullWidth
							required
						/>
					</Grid>
					<Grid item xs={12}>
						<Alert header="Something went wrong!" text={warningText} />
					</Grid>
					<Grid item xs={12}>
						<Button fullWidth disabled={submitting} type="submit">
							Submit
						</Button>
					</Grid>
				</Grid>
			</form>
		</Container>
	);
}

const mapStateToProps = state => ({
	Auth: state.Authentication,
});

const mapDispatchToProps = dispatch => ({
	PushHistory: data => dispatch(push(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);

PasswordReset.propTypes = {
	Auth: PropTypes.object,
	PushHistory: PropTypes.func,
	location: PropTypes.object,
};
