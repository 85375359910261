import React from 'react';
import VariationTypeController from '../../controllers/VariationTypeController';
import {
	Container,
	Typography,
	Grid,
	Table,
	TableHead,
	TableRow,
	TableCell,
	Button,
	TableBody,
	Dialog,
	DialogTitle,
	Divider,
	DialogContent,
	TextField,
	DialogActions,
} from '@material-ui/core';
import { LoadingOverlay } from '../../components/Common/LoadingOverlay';
import { Alert } from '@material-ui/lab';
import { AddCircle, Delete, Edit } from '@material-ui/icons';
import { isNullOrUndefined } from '../../helpers/Utils';

export default function VariationTypeEditor() {
	const [loading, setLoading] = React.useState(true);
	const [warningText, setWarningText] = React.useState(null);
	const [VariationTypes, setVariationTypes] = React.useState([]);

	const [showAddModal, setShowAddModal] = React.useState(false);
	const [newVariationTypeName, setNewVariationTypeName] = React.useState('');
	const [newVariationTypeIndex, setNewVariationTypeIndex] = React.useState(0);
	const [adding, SetAdding] = React.useState(false);

	const [showDelete, setShowDelete] = React.useState(false);
	const [showEdit, setShowEdit] = React.useState(false);
	const [editValue, setEditValue] = React.useState(null);
	// initialise
	React.useEffect(() => {
		async function init() {
			const response = await VariationTypeController.getVariationTypes();
			if (response.hasError) {
				HandleError(response.data.response);
			} else {
				setVariationTypes(response.data);
			}
			setLoading(false);
		}
		init();
	}, [setVariationTypes]);

	function handleInput(event) {
		const name = event.target.name;
		const value = event.target.value;

		switch (name) {
			case 'VariationTypeName':
				setNewVariationTypeName(value);
				break;
			case 'VariationTypeIndex':
				setNewVariationTypeIndex(value);
				break;
			default:
				return;
		}
	}

	async function AddVariationType() {
		SetAdding(true);
		const response = await VariationTypeController.addVariationType(newVariationTypeName, newVariationTypeIndex);
		if (response.hasError) {
			SetAdding(false);
			HandleError(response.data.response);
			return;
		}
		SetAdding(false);
		setVariationTypes([...VariationTypes, response.data]);
		setShowAddModal(false);
		setNewVariationTypeName('');
		setNewVariationTypeIndex(0);
		setWarningText(null);
	}

	async function SaveVariationType() {
		SetAdding(true);
		const response = await VariationTypeController.editVariationType(
			editValue.id,
			newVariationTypeName,
			newVariationTypeIndex
		);
		if (response.hasError) {
			SetAdding(false);
			HandleError(response.data.response);
			return;
		}
		SetAdding(false);
		editValue.name = newVariationTypeName;
		editValue.orderIndex = newVariationTypeIndex;
		setShowEdit(false);
		setNewVariationTypeName('');
		setNewVariationTypeIndex(0);
		setWarningText(null);
	}

	async function ConfirmDeleteVariationType() {
		SetAdding(true);
		const response = await VariationTypeController.deleteVariationType(editValue.id);
		if (response.hasError) {
			SetAdding(false);
			HandleError(response.data.response);
			return;
		}
		SetAdding(false);
		const currentVariationTypes = VariationTypes;
		currentVariationTypes.splice(currentVariationTypes.indexOf(editValue), 1);
		setVariationTypes([...currentVariationTypes]);
		setShowDelete(false);
		setWarningText(null);
	}

	function HandleError(response) {
		if (isNullOrUndefined(response)) {
			return 'Failed to connect to server. Please check your internet connection';
		}
		if (isNullOrUndefined(response.response) || isNullOrUndefined(response.response.data)) {
			setWarningText(response.message);
			return;
		}
		setWarningText(response.response.data);
	}

	function EditVariationType(value) {
		setShowEdit(true);
		setEditValue(value);
		setNewVariationTypeName(value.name);
		setNewVariationTypeIndex(value.orderIndex);
	}

	function CloseEdit() {
		setShowEdit(false);
		setEditValue(null);
		setNewVariationTypeName('');
		setNewVariationTypeIndex(0);
		setWarningText(null);
	}

	function DeleteVariationType(value) {
		setShowDelete(true);
		setEditValue(value);
		setNewVariationTypeName(value.name);
	}

	function CloseDelete() {
		setShowDelete(false);
		setEditValue(null);
		setWarningText(null);
	}

	function buildTable() {
		return (
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Name</TableCell>
						<TableCell>Order Index</TableCell>
						<TableCell></TableCell>
						<TableCell align="right">
							<Button
								onClick={() => {
									setShowAddModal(true);
								}}
							>
								<AddCircle />
							</Button>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{VariationTypes.sort((a, b) => {
						return a.orderIndex < b.orderIndex ? -1 : 1;
					}).map(value => {
						return (
							<TableRow key={value.id}>
								<TableCell>{value.name}</TableCell>
								<TableCell>{value.orderIndex}</TableCell>
								<TableCell align="right">
									<Button
										onClick={() => {
											EditVariationType(value);
										}}
									>
										<Edit />
									</Button>
								</TableCell>
								<TableCell align="right">
									<Button
										onClick={() => {
											DeleteVariationType(value);
										}}
									>
										<Delete />
									</Button>
								</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
		);
	}

	function buildAddModal() {
		return (
			<Dialog
				open={showAddModal}
				onClose={() => {
					setShowAddModal(false);
				}}
			>
				<DialogTitle>Add Variation Type</DialogTitle>
				<Divider />
				<DialogContent>
					<TextField
						id="VariationType-Name"
						label="VariationType Name *"
						value={newVariationTypeName}
						onChange={handleInput}
						variant="outlined"
						name="VariationTypeName"
					/>
					<TextField
						id="VariationType-Index"
						label="VariationType Index *"
						value={newVariationTypeIndex}
						onChange={handleInput}
						variant="outlined"
						name="VariationTypeIndex"
					/>
					{buildWarning()}
				</DialogContent>
				<Divider />
				<DialogActions>
					<Button onClick={() => AddVariationType()} disabled={adding}>
						{adding ? 'Adding' : 'Add'}
					</Button>
					<Button
						onClick={() => {
							setShowAddModal(false);
						}}
					>
						Close
					</Button>
				</DialogActions>
			</Dialog>
		);
	}

	function buildEditModal() {
		return (
			<Dialog
				open={showEdit}
				onClose={() => {
					CloseEdit();
				}}
			>
				<DialogTitle>Edit VariationType</DialogTitle>
				<Divider />
				<DialogContent>
					<TextField
						id="VariationType-Name"
						label="VariationType Name *"
						value={newVariationTypeName}
						onChange={handleInput}
						variant="outlined"
						name="VariationTypeName"
					/>
					<TextField
						id="VariationType-Index"
						label="VariationType Index *"
						value={newVariationTypeIndex}
						onChange={handleInput}
						variant="outlined"
						name="VariationTypeIndex"
					/>
					{buildWarning()}
				</DialogContent>
				<Divider />
				<DialogActions>
					<Button onClick={() => SaveVariationType()} disabled={adding}>
						{adding ? 'Saving' : 'Save'}
					</Button>
					<Button
						onClick={() => {
							CloseEdit();
						}}
					>
						Close
					</Button>
				</DialogActions>
			</Dialog>
		);
	}

	function buildDeleteModal() {
		return (
			<Dialog
				open={showDelete}
				onClose={() => {
					CloseDelete();
				}}
			>
				<DialogTitle>Delete Variation Type</DialogTitle>
				<Divider />
				<DialogContent>
					<p>You are permanently deleting {editValue?.name}</p>
					{buildWarning()}
				</DialogContent>
				<Divider />
				<DialogActions>
					<Button onClick={() => ConfirmDeleteVariationType()} disabled={adding}>
						{adding ? 'Deleting' : 'Delete'}
					</Button>
					<Button
						onClick={() => {
							CloseDelete();
						}}
					>
						Close
					</Button>
				</DialogActions>
			</Dialog>
		);
	}

	function buildWarning() {
		if (isNullOrUndefined(warningText)) return null;
		return <Alert severity="warning">{warningText}</Alert>;
	}

	return (
		<Container maxWidth="xs">
			<LoadingOverlay loading={loading} />

			<Typography variant="h3" align="center" gutterBottom>
				Variation Type Editing
			</Typography>
			{buildAddModal()}
			{buildEditModal()}
			{buildDeleteModal()}
			<Grid item xs={12}>
				{buildWarning()}
			</Grid>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					{buildTable()}
				</Grid>
			</Grid>
		</Container>
	);
}

VariationTypeEditor.propTypes = {};
