export const askForPermissionToReceiveNotifications = async () => {
	try {
		Notification.requestPermission().then(permission => {
			if (permission === 'granted') {
				console.log('Notification permission granted.');
				saveDeviceToken();
			} else {
				console.log('Unable to get permission to notify.');
			}
		});
	} catch (error) {
		console.error(error);
	}
};

async function saveDeviceToken() {
	// TODO: reimplement with new solution that doesn't leverage firebase
	// const messaging = firebase.messaging();
	// messaging.getToken().then(async (currentToken) => {
	//     await UserController.saveDeviceToken(currentToken);
	// }).catch((err) => {
	//     console.log('An error occurred while retrieving the device token. ', err);
	// });
	// messaging.onMessage((payload) => {
	//     console.log(payload);
	//     // NOTE - no implementation when in-app currently.
	//     // Best practice indicates that push notifs should
	//     // not be displayed when a user is in-app, need to
	//     // discuss what should happen here...
	// });
}
