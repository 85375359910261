import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Update } from '../../stores/Actions/Cart';
import { CartController } from '../../controllers/CartController';
import {
	Container,
	Typography,
	Button,
	TextField,
	Grid,
	makeStyles,
	Dialog,
	DialogTitle,
	Divider,
	DialogContent,
	DialogActions,
	Card,
	CardContent,
} from '@material-ui/core';
import { LoadingOverlay } from '../../components/Common/LoadingOverlay';
import { isNullOrUndefined } from '../../helpers/Utils';
import ProductController from '../../controllers/ProductController';
import { Alert } from '@material-ui/lab';
import Carousel from '../../components/Dissplay/Carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const useStyles = makeStyles(() => ({
	root: {
		marginTop: 20,
	},
	formControl: {
		width: '100%',
	},
	displayColour: {
		height: 30,
		width: 30,
	},
	product: {
		'& .image': {
			width: '100%',
			height: '200px',
		},
	},
	carousel: {
		'& .slide': {
			backgroundColor: '#FFFFFF',
		},
	},
	selected: {
		border: '3px solid rgba(0, 0, 0, 1)',
	},
	childCheckout: {
		display: 'inline-block',
		margin: 10,
		border: 'solid 2px',
		borderRadius: '10px',
		padding: '6px',
		'& > span': {
			fontWeight: 'bolder',
			marginRight: '15px',
		},
		'& .MuiFormControl-root': {
			width: '75px',
		},

		'& .add-btn': {},
		'& .remove-btn': {
			'& .MuiButton-label:after': {
				content: '"Added To Cart"',
				paddingRight: '1rem',
				paddingLeft: '1rem',
			},
			'&:hover': {
				'& .MuiButton-label:after': {
					content: '"Remove From Cart"',
					paddingRight: 0,
					paddingLeft: 0,
				},
				backgroundColor: '#FF0000',
			},
		},
		'& .center-val': {
			'& .MuiInputBase-root': {
				borderRadius: 0,
			},
		},
	},
	checkoutButton: {
		position: 'fixed',
		right: 0,
		top: '65px',
		margin: '0px 10px',
		backgroundColor: '#FFFFFF',
		width: 'calc(100% - 20px)',
		zIndex: 100000,
		'&:hover': {
			backgroundColor: '#2064A4',
			color: '#FFFFFF',
		},
	},
	clickableText: {
		cursor: 'pointer',
		height: '100%',
		width: 'calc(100% - 16px)',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		'& .MuiCardContent-root': {
			width: '100%',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'flex-start',
			paddingBottom: 16,
		},
		'&:hover': {
			backgroundColor: '#DDD',
		},
	},
	productItem: {
		position: 'relative',
		height: 100,
		'& a': {
			padding: '0px 10px',
		},
		'& .img-wrapper': {
			marginRight: 20,
			height: 'calc(100% - 16px)',
			width: 100,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			'& img': {
				backgroundSize: 'cover',
				backgroundPosition: 'center',
				height: '100%',
				width: 100,
			},
			'& svg': {
				height: 50,
				width: 50,
			},
		},
		'& h6': {
			position: 'inline-block',
		},
	},
	category: {
		marginTop: 20,
		fontWeight: 300,
	},
	bottomCheckoutButton: {
		marginTop: 16,
	},
}));

function Products(props) {
	const classes = useStyles();
	const [loading, setLoading] = React.useState(true);
	const [warningText, setWarningText] = React.useState(null);
	const [products, setProducts] = React.useState([]);
	const [longestVariation, setLongestVariation] = React.useState(0);
	const [selectedProduct, setSelectedProduct] = React.useState(null);
	const [editedCart, setEditedCart] = React.useState(null);

	const isCartEmpty = props.Cart.children.every(
		e => isNullOrUndefined(e.cart) || e.cart?.length === 0 || e.cart?.every(r => r.amount === 0)
	);

	React.useEffect(() => {
		async function loadProducts() {
			setLoading(true);
			const schoolNames = props.Cart.children.map(value => {
				return value.SchoolFullName;
			});

			const productResponse = await ProductController.getSchoolsProducts(schoolNames);
			if (productResponse.hasError) {
				HandleError(productResponse.data);
				setLoading(false);
				return;
			}
			let longestVariation = 0;
			productResponse.data.forEach(product => {
				if (product.productVariations.length > longestVariation) {
					longestVariation = product.productVariations.length;
				}
			});
			setLongestVariation(longestVariation);
			setProducts(productResponse.data);

			setLoading(false);
		}
		if (props.Cart.children.length != 0 && products.length == 0) loadProducts();
	}, [props.Cart, products]);

	function HandleError(response) {
		if (isNullOrUndefined(response)) {
			return 'Failed to connect to server. Please check your internet connection';
		}
		if (isNullOrUndefined(response.response) || isNullOrUndefined(response.response.data)) {
			setWarningText(response.message);
			return;
		}
		setWarningText(response.response.data);
	}

	function handelVariationSelect(productIndex, variationIndex, variationId) {
		const product = products[productIndex];
		if (isNullOrUndefined(product.selectedVariation)) {
			product.selectedVariation = {};
		}
		if (product.selectedVariation[variationIndex] === variationId) {
			product.selectedVariation[variationIndex] = null;
		} else {
			product.selectedVariation[variationIndex] = variationId;
		}
		setProducts([...products]);
	}

	function handelProductSelect(id) {
		setSelectedProduct(id);
		setEditedCart(JSON.parse(JSON.stringify(props.Cart)));
	}

	function handelProductClose() {
		setSelectedProduct(null);
		setEditedCart(null);
	}

	function handelProductSave() {
		setSelectedProduct(null);
		props.Cart.children = editedCart.children;
		CartController.UpdateCart(editedCart, props.UpdateCart);
		setEditedCart(null);
	}

	function handelCartUpdate(childIIndex, stock, product, amount) {
		amount = Number(amount);
		if (amount < 0) {
			amount = 0;
		}

		const child = editedCart.children[childIIndex];
		const stockId = stock.stockId;
		const productId = product.id;
		if (isNullOrUndefined(child.cart)) {
			child.cart = [];
		}
		const cartItem = child.cart.find(value => {
			return value.stockId == stockId && value.productId == productId;
		});
		if (isNullOrUndefined(cartItem)) {
			if (amount !== 0) {
				child.cart.push({
					amount: amount,
					stockId: stockId,
					productId: productId,
					product: product,
					stock: stock,
				});
			}
		} else if (amount === 0) {
			child.cart.splice(child.cart.indexOf(cartItem), 1);
		} else {
			cartItem.amount = amount;
		}
		CartController.UpdateCart(props.Cart, props.UpdateCart);
	}

	function buildWarning() {
		if (isNullOrUndefined(warningText)) return null;
		return <Alert severity="warning">{warningText}</Alert>;
	}

	function buildUIObjectDisplay(variation) {
		if (isNullOrUndefined(variation.variationTypeName)) {
			return variation.name;
		}
		switch (variation.variationTypeName) {
			case 'Colour':
				if (isNullOrUndefined(variation.uiObject) || !variation.uiObject.includes('#')) {
					return variation.name;
				}
				return <div style={{ backgroundColor: variation.uiObject }} className={classes.displayColour}></div>;
			default:
				return variation.name;
		}
	}

	function buildProductVariationSelectors(product, productIndex) {
		const renderObjects = [];
		for (let i = 0; i < longestVariation; i++) {
			const levelVariations = [];
			for (let j = 0; j < product.productVariations.length; j++) {
				const variation = product.productVariations[j].variations.find(variation => {
					return variation.variationTypeOrderIndex === i;
				});
				if (
					!isNullOrUndefined(variation) &&
					!levelVariations.some(e => {
						return e.id === variation.id;
					})
				) {
					//TODO: Check that the above selected variation
					levelVariations.push(variation);
				}
			}
			const sorted = levelVariations.sort((a, b) => {
				return a.orderIndex - b.orderIndex;
			});
			if (levelVariations.length !== 0) {
				renderObjects.push(
					<div key={product.id + i}>
						<div>{levelVariations[0].variationTypeName}</div>
						{sorted.map(value => {
							const selected =
								!isNullOrUndefined(product.selectedVariation) &&
								product.selectedVariation[i] === value.id;
							return (
								<Button
									variant="outlined"
									key={product.id + value.id}
									className={selected ? classes.selected : ''}
									onClick={() => {
										handelVariationSelect(productIndex, i, value.id);
									}}
								>
									{' '}
									{buildUIObjectDisplay(value)}
								</Button>
							);
						})}
					</div>
				);
			}
		}
		return renderObjects;
	}

	function buildCheckoutArea(product) {
		if (isNullOrUndefined(product.selectedVariation)) {
			return (
				<Typography variant="h6" align="center" gutterBottom>
					Select Product variation
				</Typography>
			);
		}

		const validStock = product.productVariations.find(value => {
			return !value.variations.some(variation => {
				return product.selectedVariation[variation.variationTypeOrderIndex] !== variation.id;
			});
		});
		if (isNullOrUndefined(validStock)) {
			return null;
		}
		if (!validStock.isAvailable) {
			return (
				<Typography variant="h5" align="center" gutterBottom>
					Out Of Stock
				</Typography>
			);
		}
		return editedCart?.children.map((child, index) => {
			let count = 0;
			if (!isNullOrUndefined(child.cart)) {
				const cartItem = child.cart.find(cartItem => {
					return cartItem.stockId == validStock.stockId;
				});
				if (!isNullOrUndefined(cartItem)) {
					count = cartItem.amount;
				}
			}
			return (
				<div key={'checkout' + validStock.id + index} className={classes.childCheckout}>
					<span>{child.FirstName}</span>
					{count == 0 ? (
						<Button
							variant="contained"
							onClick={() => {
								handelCartUpdate(index, validStock, product, 1);
							}}
							className="add-btn"
							color="primary"
						>
							Add To Cart
						</Button>
					) : (
						<Button
							variant="contained"
							onClick={() => {
								handelCartUpdate(index, validStock, product, 0);
							}}
							className="remove-btn"
							color="secondary"
						></Button>
					)}
				</div>
			);
		});
	}

	function buildProducts() {
		if (products.length === 0) {
			return (
				<Grid item xs={12}>
					<Typography variant="h6" align="center" gutterBottom>
						No products to show
					</Typography>
				</Grid>
			);
		}
		let lastProductName = '';
		return products.map(value => {
			const image =
				value.images.length === 0
					? null
					: value.images.map(image => {
							return <img key={image.id} src={image.imagePath} />;
					  });
			const prodOverview =
				lastProductName == value.productCategoryName ? null : (
					<Grid item xs={12} key={value.productCategoryName}>
						<Typography variant="h3" align="left" gutterBottom className={classes.category}>
							{value.productCategoryName}
						</Typography>
					</Grid>
				);
			lastProductName = value.productCategoryName;
			return [
				prodOverview,
				<Grid item xs={12} sm={6} md={4} className={classes.productItem} key={'ProdPreview' + value.id}>
					<Card onClick={() => handelProductSelect(value.id)} className={classes.clickableText}>
						<CardContent>
							{isNullOrUndefined(image) ? null : <div className="img-wrapper">{image[0]}</div>}
							<Typography variant="h6" align="left" gutterBottom>
								{value.name}
							</Typography>
						</CardContent>
					</Card>
				</Grid>,
			];
		});
	}

	function buildProductModal() {
		if (isNullOrUndefined(selectedProduct)) {
			return null;
		}
		const product = products.find(value => {
			return value.id === selectedProduct;
		});
		const index = products.indexOf(product);
		const image =
			product.images.length === 0
				? []
				: product.images.map(image => {
						return <img key={image.id} src={image.imagePath} />;
				  });
		return (
			<Dialog open={true} onClose={handelProductClose} fullWidth={true} maxWidth="md">
				<DialogTitle disableTypography={true}>
					<Typography variant="h4" align="center">
						{product.name}{' '}
					</Typography>
				</DialogTitle>
				<Divider />
				<DialogContent>
					<Carousel items={image} key={product.id + 'modal'} />
					{buildProductVariationSelectors(product, index)}
					{buildCheckoutArea(product)}
				</DialogContent>
				<Divider />
				<DialogActions>
					<Button onClick={handelProductSave}>Save</Button>
					<Button onClick={handelProductClose}>Cancel</Button>
				</DialogActions>
			</Dialog>
		);
	}

	return (
		<Container maxWidth="lg" key="RootContiner">
			<LoadingOverlay loading={loading} />
			{buildProductModal()}
			<Grid container spacing={2} className={classes.root}>
				<Grid item xs={12}>
					<Typography variant="h3" align="center" gutterBottom>
						Products
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Button
						component={Link}
						to={'/Children'}
						color="primary"
						variant="contained"
						style={{ height: '100%' }}
						fullWidth
					>
						Edit Children
					</Button>
				</Grid>
				<Grid item xs={12}>
					<Divider />
				</Grid>
				<Grid item xs={12}>
					<Typography variant="h4" align="center">
						Please select each item you require for your children
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Divider />
				</Grid>
				{buildProducts()}
				<Grid item xs={12}>
					{buildWarning()}
				</Grid>
			</Grid>
			<Button
				key="CheckoutButton"
				fullWidth={true}
				component={Link}
				to={'/Checkout'}
				disabled={isCartEmpty}
				variant="contained"
				color="secondary"
				className={classes.bottomCheckoutButton}
			>
				Continue
			</Button>
		</Container>
	);
}

const mapStateToProps = state => ({
	Cart: state.Cart,
});

const mapDispatchToProps = dispatch => ({
	UpdateCart: data => dispatch(Update(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Products);

Products.propTypes = {
	Cart: PropTypes.object,
	UpdateCart: PropTypes.func,
	history: PropTypes.object,
};
