import React from 'react';
import { Link } from 'react-router-dom';
import AdditionalInfoController from '../../controllers/AdditionalInfoController';
import {
	Button,
	Container,
	Card,
	CardContent,
	CardActions,
	CardHeader,
	RadioGroup,
	Radio,
	FormControlLabel,
	FormControl,
} from '@material-ui/core';
import logo from '../../images/uex-logo.png';
import SettingsController from '../../controllers/SettingsController.js';
import { isNullOrUndefined } from '../../helpers/Utils';
import { Alert } from '@material-ui/lab';
import { LoadingOverlay } from '../../components/Common/LoadingOverlay';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';

function AdditionalInfo(props) {
	const [loading, setLoading] = React.useState(false);
	const [warningText, setWarningText] = React.useState(null);

	const [titleText, setTitleText] = React.useState(null);
	const [descriptionText, setDescriptionText] = React.useState([]);
	const [optionA, setOptionA] = React.useState({});
	const [optionB, setOptionB] = React.useState({});

	const { PushHistory } = props;

	React.useEffect(() => {
		async function init() {
			setLoading(true);
			await fetchSettings();

			const additionalInfoResponse = await AdditionalInfoController.getAdditionalInfoSettings();
			if (additionalInfoResponse.hasError) {
				HandleError(additionalInfoResponse.data);
				setLoading(false);
				return;
			}
			setDescriptionText(additionalInfoResponse.data.description.split('\n'));
			setTitleText(additionalInfoResponse.data.title);
			setOptionA(additionalInfoResponse.data.optionA);
			setOptionB(additionalInfoResponse.data.optionB);
			setLoading(false);
		}
		init();
	}, []);

	const fetchSettings = async () => {
		const settingsResponse = await SettingsController.getSettings();
		if (!settingsResponse.hasError) {
			const { disableAdditionalInfoPage } = settingsResponse.data;

			if (disableAdditionalInfoPage) {
				PushHistory('/Children');
			}

			return;
		}
	};

	function HandleError(response) {
		if (isNullOrUndefined(response)) {
			return 'Failed to connect to server. Please check your internet connection';
		}
		if (isNullOrUndefined(response.response) || isNullOrUndefined(response.response.data)) {
			setWarningText(response.message);
			return;
		}
		setWarningText(response.response.data);
	}

	function buildWarning() {
		if (isNullOrUndefined(warningText)) return null;
		return <Alert severity="warning">{warningText}</Alert>;
	}

	return (
		<React.Fragment>
			<LoadingOverlay loading={loading} />
			<Container maxWidth="sm">
				<Card variant="outlined">
					<CardContent style={{ textAlign: 'center' }}>
						<img src={logo} alt="Uniform Exchange" style={{ maxWidth: 200 }} />
					</CardContent>
					<CardHeader title={titleText} />
					<CardContent>
						{buildWarning()}
						{descriptionText.map((value, index) => {
							return [value, <br key={index} />];
						})}
						<FormControl component="fieldset">
							<RadioGroup
								aria-label={titleText}
								name="additionalInfo"
								onChange={e => localStorage.setItem('selectedAdditionalInfo', e.target.value)}
							>
								<FormControlLabel value={optionA.id} control={<Radio />} label={optionA.userText} />
								<br />
								<FormControlLabel value={optionB.id} control={<Radio />} label={optionB.userText} />
							</RadioGroup>
						</FormControl>
					</CardContent>
					<CardActions>
						<Button
							component={Link}
							to={'/Children'}
							color="primary"
							variant="contained"
							fullWidth
							disableElevation
						>
							{'Continue'}
						</Button>
					</CardActions>
				</Card>
			</Container>
		</React.Fragment>
	);
}

const mapDispatchToProps = dispatch => ({
	PushHistory: data => dispatch(push(data)),
});

export default connect(null, mapDispatchToProps)(AdditionalInfo);

AdditionalInfo.propTypes = {
	PushHistory: PropTypes.func,
};
