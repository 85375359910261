import React from 'react';
import PropTypes from 'prop-types';
import UserController from '../../controllers/UserController';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Alert } from '../../components/Common/Alert';
import { Button, Grid, TextField, Typography, Container } from '@material-ui/core';
import { isNullOrUndefined } from '../../helpers/Utils';

function TwoFactorVerify(props) {
	const [code, setCode] = React.useState('');
	const [verifying, setVerifying] = React.useState(false);
	const [warningText, setWarningText] = React.useState(null);
	const [redirectUrl, setRedirectUrl] = React.useState(null);

	// redirect
	React.useEffect(() => {
		if (isNullOrUndefined(redirectUrl)) {
			return;
		}
		props.PushHistory(redirectUrl);
	}, [redirectUrl, props]);

	async function verifyCode() {
		setVerifying(true);
		setWarningText(null);
		const response = await UserController.verifyTwoFactor(code);
		if (response.hasError) {
			setWarningText(response.data);
			setVerifying(false);
		} else {
			setRedirectUrl('/');
		}
	}

	return (
		<Container maxWidth="xs">
			<Typography variant="h4" align="center" gutterBottom>
				Two Factor Code Required
			</Typography>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<TextField
						name="code"
						label="Enter 2FA code"
						onChange={e => {
							setCode(e.target.value);
						}}
						value={code}
						type="text"
						fullWidth
					/>
				</Grid>
				<Grid item xs={12}>
					<Alert header="Something went wrong!" text={warningText} />
				</Grid>
				<Grid item xs={12}>
					<Button fullWidth disabled={verifying} onClick={verifyCode} content="Submit" />
				</Grid>
			</Grid>
		</Container>
	);
}

const mapStateToProps = state => ({
	Auth: state.Authentication,
});
const mapDispatchToProps = dispatch => ({
	PushHistory: data => dispatch(push(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TwoFactorVerify);

TwoFactorVerify.propTypes = {
	Auth: PropTypes.object,
	PushHistory: PropTypes.func,
};
