import Axios from 'axios';
import UserController from './UserController';
import { APIRoute, APIPath } from '../helpers/Constants';
import { generateResponse, isNullOrUndefined, flattenArrayForForm } from '../helpers/Utils';

const ProductController = {
	getSchoolsProducts,
	getProducts,
	getProduct,
	addProduct,
	editProduct,
	deleteProduct,
};

async function getSchoolsProducts(schools) {
	return Axios.post(APIRoute + APIPath.GET_SCHOOLS_PRODUCTS, { Names: schools })
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function getProducts() {
	const userHeaders = await UserController.getUserHeaders();
	return Axios.get(APIRoute + APIPath.GET_PRODUCTS, userHeaders)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function getProduct(id) {
	const userHeaders = await UserController.getUserHeaders();
	return Axios.get(APIRoute + APIPath.GET_PRODUCT + id, userHeaders)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function addProduct(ProductName, ProductCategoryId, IsAvailable, SchoolIds, Images, Stocks) {
	const userHeaders = await UserController.getUserHeaders('multipart/form-data');
	const formData = new FormData();
	formData.append('Name', ProductName);
	formData.append('ProductCategoryId', ProductCategoryId);
	formData.append('IsAvailable', IsAvailable);
	SchoolIds.map(value => {
		formData.append('SchoolIds', value);
	});

	Images.map((value, index) => {
		flattenArrayForForm(formData, 'Images', value, index);
		if (!isNullOrUndefined(value.File)) {
			formData.append('Files', value.File, index);
		}
	});

	Stocks.map((value, index) => {
		flattenArrayForForm(formData, 'Stocks', value, index);
	});

	return Axios.post(APIRoute + APIPath.ADD_PRODUCT, formData, userHeaders)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function editProduct(Id, ProductName, ProductCategoryId, IsAvailable, SchoolIds, Images, Stocks) {
	const userHeaders = await UserController.getUserHeaders('multipart/form-data');
	const formData = new FormData();
	formData.append('Id', Id);
	formData.append('Name', ProductName);
	formData.append('ProductCategoryId', ProductCategoryId);
	formData.append('IsAvailable', IsAvailable);
	SchoolIds.map(value => {
		formData.append('SchoolIds', value);
	});

	Images.map((value, index) => {
		flattenArrayForForm(formData, 'Images', value, index);
		if (!isNullOrUndefined(value.File)) {
			formData.append('Files', value.File, index);
		}
	});

	Stocks.map((value, index) => {
		flattenArrayForForm(formData, 'Stocks', value, index);
	});

	return Axios.post(APIRoute + APIPath.EDIT_PRODUCT, formData, userHeaders)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function deleteProduct(id) {
	const userHeaders = await UserController.getUserHeaders();

	return Axios.delete(APIRoute + APIPath.DELETE_PRODUCT + id, userHeaders)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

export default ProductController;
