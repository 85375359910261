import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Update } from '../../stores/Actions/Cart';
import SchoolsController from '../../controllers/SchoolController';
import { CartController } from '../../controllers/CartController';
import {
	Container,
	Typography,
	Button,
	TextField,
	Grid,
	makeStyles,
	Card,
	CardHeader,
	CardContent,
	Divider,
} from '@material-ui/core';
import { Autocomplete, Alert } from '@material-ui/lab';
import { isNullOrUndefined } from '../../helpers/Utils';
import { LoadingOverlay } from '../../components/Common/LoadingOverlay';

const useStyles = makeStyles(() => ({
	orDivider: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	childrenWrapper: {
		'& .MuiCard-root': {
			marginBottom: 16,
		},
		'& .MuiCard-root:last-child': {
			marginBottom: 0,
		},
	},
	addChild: {
		marginBottom: 20,
	},
}));

function ChildEditor(props) {
	const children = props.Cart.children;
	const [canContinue, setCanContinue] = React.useState(true);
	const [loading, setLoading] = React.useState(true);
	const [warningText, setWarningText] = React.useState(null);
	const [schools, setSchools] = React.useState([]);

	const classes = useStyles();

	React.useEffect(() => {
		async function init() {
			setLoading(true);
			const schoolResponse = await SchoolsController.getSchools();
			if (schoolResponse.hasError) {
				HandleError(schoolResponse.data);
				setLoading(false);
				return;
			}
			setSchools(schoolResponse.data);
			setLoading(false);
		}
		init();
	}, []);

	function HandleError(response) {
		if (isNullOrUndefined(response)) {
			return 'Failed to connect to server. Please check your internet connection';
		}
		if (isNullOrUndefined(response.response) || isNullOrUndefined(response.response.data)) {
			setWarningText(response.message);
			return;
		}
		setWarningText(response.response.data);
	}

	function handelInput(event, index) {
		const name = event.target.name;
		const value = event.target.value;

		//We Just update the react store here to not hammer the DB

		switch (name) {
			case 'FirstName':
				children[index].FirstName = value;
				props.UpdateCart(props.Cart);
				break;
			case 'LastNameOrRef':
				children[index].LastNameOrRef = value;
				props.UpdateCart(props.Cart);
				break;
			case 'FirstPostcode':
				children[index].FirstPostcode = value;
				props.UpdateCart(props.Cart);
				break;
			case 'Age':
				children[index].Age = value;
				props.UpdateCart(props.Cart);
				break;
			case 'Gender':
				children[index].Gender = value;
				props.UpdateCart(props.Cart);
				break;
			case 'Build':
				children[index].Build = value;
				props.UpdateCart(props.Cart);
				break;
			case 'Height':
				children[index].Height = value;
				props.UpdateCart(props.Cart);
				break;
			case 'SchoolFullName':
				children[index].SchoolFullName = value;
				props.UpdateCart(props.Cart);
				break;
			case 'SchoolYear':
				children[index].SchoolYear = value;
				props.UpdateCart(props.Cart);
				break;
			case 'SchoolHouse':
				children[index].SchoolHouse = value;
				props.UpdateCart(props.Cart);
				break;
			case 'ShoeSize':
				children[index].ShoeSize = value;
				props.UpdateCart(props.Cart);
				break;
			case 'TopSize':
				children[index].TopSize = value;
				props.UpdateCart(props.Cart);
				break;
			case 'TrouserSize':
				children[index].TrouserSize = value;
				props.UpdateCart(props.Cart);
				break;
			case 'Notes':
				children[index].Notes = value;
				props.UpdateCart(props.Cart);
				break;
			default:
				return;
		}
	}

	function addChild() {
		children.push({
			FirstName: '',
			LastNameOrRef: '',
			FirstPostcode: '',
			Age: 0,
			Gender: '',
			Build: '',
			Height: '',
			SchoolFullName: '',
			SchoolYear: '',
			SchoolHouse: '',
			ShoeSize: '',
			TopSize: '',
			TrouserSize: '',
			Notes: '',
		});
		CartController.UpdateCart(props.Cart, props.UpdateCart);
	}

	function removeChild(index) {
		children.splice(index, 1);
		CartController.UpdateCart(props.Cart, props.UpdateCart);
	}

	function SaveChildren() {
		CartController.UpdateCart(props.Cart, props.UpdateCart);
		props.history.push('/Products');
	}

	function buildChildren() {
		const allValid =
			!children.some(value => {
				for (const key in value) {
					if (typeof value[key] === 'string') {
						if (value[key].trim().length === 0) {
							if (key == 'Height' || key == 'Notes') {
								continue;
							}
							return true;
						} //Because of text fields it is a string not a number that is stored
						else if (key === 'Age') {
							if (Number(value[key]) <= 0) {
								return true;
							} else {
								continue;
							}
						} else {
							continue;
						}
					}
					if (key === 'cart') {
						continue;
					}
					return true;
				}

				return false;
			}) && children.length !== 0;

		if (allValid !== canContinue) {
			setCanContinue(allValid);
		}

		return children.map((value, index) => {
			return (
				<Card key={index}>
					<CardContent>
						<Grid container spacing={0} key={index}>
							<Grid item lg={11} md={10} sm={9} xs={8}>
								<Typography variant="h5" align="left" gutterBottom>
									<b>
										{value.FirstName.trim().length === 0 ? 'Child ' + (index + 1) : value.FirstName}
									</b>
								</Typography>
							</Grid>
							<Grid item lg={1} md={2} sm={3} xs={4}>
								<Button
									variant="contained"
									onClick={() => {
										removeChild(index);
									}}
									color="primary"
									fullWidth
								>
									X
								</Button>
							</Grid>
						</Grid>
					</CardContent>
					<Divider />
					<CardContent>
						<Grid container spacing={2} key={index + 'lower'}>
							<Grid item xs={12}>
								<TextField
									id={'FirstName' + index}
									label="First Name *"
									value={value.FirstName}
									onChange={e => {
										handelInput(e, index);
									}}
									variant="outlined"
									name="FirstName"
									fullWidth={true}
									helperText="The first name is for your reference only and will not be stored in our database"
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									id={'LastNameOrRef' + index}
									label="Last Name Or Reference *"
									value={value.LastNameOrRef}
									onChange={e => {
										handelInput(e, index);
									}}
									variant="outlined"
									name="LastNameOrRef"
									fullWidth={true}
								/>
							</Grid>
							<Grid item xs={12}>
								<Autocomplete
									value={value.SchoolFullName}
									onChange={(event, newValue) => {
										if (typeof newValue === 'string') {
											children[index].SchoolFullName = newValue;
										} else if (newValue && newValue.inputValue) {
											// Create a new value from the user input
											children[index].SchoolFullName = newValue.inputValue;
										} else {
											children[index].SchoolFullName = event.target.value;
										}
										props.UpdateCart(props.Cart);
									}}
									defaultValue={value.SchoolFullName}
									disableClearable
									id={'SchoolFullNameAuto' + index}
									options={schools
										.sort((a, b) => a.name.localeCompare(b.name))
										.map(e => {
											return e.name;
										})}
									renderInput={params => (
										<TextField
											{...params}
											name="SchoolFullName"
											label="School Full Name *"
											variant="outlined"
										/>
									)}
								/>
							</Grid>
							<Grid item sm={6} xs={12}>
								<TextField
									id={'SchoolYear' + index}
									label="Year Group *"
									value={value.SchoolYear}
									onChange={e => {
										handelInput(e, index);
									}}
									variant="outlined"
									name="SchoolYear"
									fullWidth={true}
								/>
							</Grid>
							<Grid item sm={6} xs={12}>
								<TextField
									id={'SchoolHouse' + index}
									label="House Name/Colour *"
									value={value.SchoolHouse}
									onChange={e => {
										handelInput(e, index);
									}}
									variant="outlined"
									name="SchoolHouse"
									fullWidth={true}
								/>
							</Grid>
							<Grid item sm={6} xs={12}>
								<TextField
									id={'Gender' + index}
									label="Gender *"
									value={value.Gender}
									onChange={e => {
										handelInput(e, index);
									}}
									variant="outlined"
									name="Gender"
									fullWidth={true}
								/>
							</Grid>
							<Grid item sm={6} xs={12}>
								<TextField
									id={'FirstPostcode' + index}
									label="First part of postcode *"
									value={value.FirstPostcode}
									onChange={e => {
										handelInput(e, index);
									}}
									variant="outlined"
									name="FirstPostcode"
									fullWidth={true}
								/>
							</Grid>
							<Grid item sm={6} xs={12}>
								<TextField
									id={'Age' + index}
									label="Age *"
									value={value.Age}
									onChange={e => {
										handelInput(e, index);
									}}
									variant="outlined"
									name="Age"
									type="number"
									fullWidth={true}
								/>
							</Grid>
							<Grid item sm={6} xs={12}>
								<TextField
									id={'Build' + index}
									label="Build *"
									value={value.Build}
									onChange={e => {
										handelInput(e, index);
									}}
									variant="outlined"
									name="Build"
									fullWidth={true}
								/>
							</Grid>
							<Grid item sm={6} xs={12}>
								<TextField
									id={'Height' + index}
									label="Height"
									value={value.Height}
									onChange={e => {
										handelInput(e, index);
									}}
									variant="outlined"
									name="Height"
									fullWidth={true}
								/>
							</Grid>
							<Grid item sm={6} xs={12}>
								<TextField
									id={'ShoeSize' + index}
									label="Shoe Size *"
									value={value.ShoeSize}
									onChange={e => {
										handelInput(e, index);
									}}
									variant="outlined"
									name="ShoeSize"
									fullWidth={true}
								/>
							</Grid>
							<Grid item sm={6} xs={12}>
								<TextField
									id={'TopSize' + index}
									label="Top Size *"
									value={value.TopSize}
									onChange={e => {
										handelInput(e, index);
									}}
									variant="outlined"
									name="TopSize"
									fullWidth={true}
									helperText="Clothing size for shirt/jumper/t-shirt etc"
								/>
							</Grid>
							<Grid item sm={6} xs={12}>
								<TextField
									id={'TrouserSize' + index}
									label="Trouser Size *"
									value={value.TrouserSize}
									onChange={e => {
										handelInput(e, index);
									}}
									variant="outlined"
									name="TrouserSize"
									fullWidth={true}
									helperText="Clothing size for trousers/skirt/shorts etc"
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									id={'Notes' + index}
									label="Additional Information"
									value={value.Notes}
									onChange={e => {
										handelInput(e, index);
									}}
									variant="outlined"
									name="Notes"
									fullWidth={true}
									multiline
									rowsMax={4}
									helperText="Specify alternative items or colours your child could use or additional information relevant to the request."
								/>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			);
		});
	}

	function buildWarning() {
		if (isNullOrUndefined(warningText)) return null;
		return <Alert severity="warning">{warningText}</Alert>;
	}
	return (
		<Container>
			<LoadingOverlay loading={loading} />
			<Typography variant="h3" align="center" gutterBottom>
				My Children
			</Typography>

			<br />
			<br />

			<div className={classes.childrenWrapper}>{buildChildren()}</div>

			<br />
			<Button onClick={addChild} variant="contained" color="secondary" fullWidth className={classes.addChild}>
				+ Add Child
			</Button>
			{buildWarning()}
			<Button
				fullWidth={true}
				onClick={SaveChildren}
				disabled={!canContinue}
				variant="contained"
				color="secondary"
				alt={canContinue ? '' : 'fill out all the fields to continue'}
			>
				{canContinue ? 'Continue' : 'fill out all the fields to continue'}
			</Button>
		</Container>
	);
}

const mapStateToProps = state => ({
	Cart: state.Cart,
});

const mapDispatchToProps = dispatch => ({
	UpdateCart: data => dispatch(Update(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChildEditor);

ChildEditor.propTypes = {
	Cart: PropTypes.object,
	UpdateCart: PropTypes.func,
	history: PropTypes.object,
};
