import React, { useState, useEffect } from 'react';
import {
	Container,
	Card,
	CardContent,
	FormGroup,
	FormControlLabel,
	Switch,
	CardActions,
	Button,
} from '@material-ui/core';
import { isNullOrUndefined } from '../../helpers/Utils';
import { Alert } from '@material-ui/lab';
import { LoadingOverlay } from '../../components/Common/LoadingOverlay';
import SettingsController from '../../controllers/SettingsController';

export default function Settings() {
	const [loading, setLoading] = useState(false);
	const [warningText, setWarningText] = useState(null);
	const [additionalInfoPage, setAdditionalInfoPage] = useState(false);
	const [bookingForm, setBookingForm] = useState(false);

	useEffect(() => {
		async function init() {
			setLoading(true);
			const response = await SettingsController.getSettings();
			if (response.hasError) {
				handleError(response.data);
				setLoading(false);
				return;
			}
			setAdditionalInfoPage(response.data.disableAdditionalInfoPage);
			setBookingForm(response.data.disableBookingForm);
			setLoading(false);
		}
		init();
	}, []);

	async function handleSave() {
		setLoading(true);
		const response = await SettingsController.updateSettings(additionalInfoPage, bookingForm);
		if (response.hasError) {
			handleError(response.data);
		}

		setLoading(false);
	}

	function handleError(response) {
		if (isNullOrUndefined(response)) {
			return 'Failed to connect to server. Please check your internet connection';
		}
		if (isNullOrUndefined(response.response) || isNullOrUndefined(response.response.data)) {
			setWarningText(response.message);
			return;
		}
		setWarningText(response.response.data);
	}

	function buildWarning() {
		if (isNullOrUndefined(warningText)) return null;
		return <Alert severity="warning">{warningText}</Alert>;
	}

	return (
		<>
			<LoadingOverlay loading={loading} />
			<Container maxWidth="sm">
				<Card variant="outlined">
					<CardContent>
						<FormGroup>
							<FormControlLabel
								control={
									<Switch
										checked={additionalInfoPage}
										onChange={e => setAdditionalInfoPage(e.target.checked)}
									/>
								}
								label="Disable Additional Info Page"
							/>
							<FormControlLabel
								control={
									<Switch checked={bookingForm} onChange={e => setBookingForm(e.target.checked)} />
								}
								label="Disable Booking Form"
							/>
						</FormGroup>
						{buildWarning()}
					</CardContent>
					<CardActions>
						<Button id="saveSettingsBtn" color="primary" variant="contained" fullWidth onClick={handleSave}>
							Save
						</Button>
						{buildWarning()}
					</CardActions>
				</Card>
			</Container>
		</>
	);
}
