import Axios from 'axios';
import { APIRoute, APIPath } from '../helpers/Constants';
import { generateResponse } from '../helpers/Utils';
import UserController from './UserController';

const SettingsController = {
	getSettings,
	updateSettings,
};

async function getSettings() {
	const userHeaders = await UserController.getUserHeaders();

	return Axios.get(APIRoute + APIPath.GET_SETTINGS, userHeaders)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}
async function updateSettings(disableAdditionalInfoPage, disableBookingForm) {
	const userHeaders = await UserController.getUserHeaders();

	const data = { disableAdditionalInfoPage, disableBookingForm };

	return Axios.put(APIRoute + APIPath.UPDATE_SETTINGS, data, userHeaders)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

export default SettingsController;
