const AUTH_INITIAL_STATE = {
	isAuthenticated: false,
	userName: '',
	role: [],
	isLoggingIn: true,
};

const Authentication = (state = AUTH_INITIAL_STATE, action) => {
	switch (action.type) {
		case 'LOGGED_IN':
			return Object.assign({}, state, action.data);
		case 'LOGGED_OUT':
			return Object.assign({}, state, {
				isAuthenticated: false,
				userName: '',
				role: [],
				isLoggingIn: false,
			});
		default:
			return state;
	}
};

export default Authentication;
