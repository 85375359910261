import React from 'react';
import NavMenu from './NavMenu.js';
import PropTypes from 'prop-types';
import MOTD from './MOTD.js';
import { VersionChecker } from './VersionChecker';
import SettingsChecker from './SettingsChecker.js';

export function Layout(props) {
	return (
		<>
			<SettingsChecker />
			<NavMenu>
				<MOTD />
				<VersionChecker />
				{props.children}
			</NavMenu>
		</>
	);
}

Layout.propTypes = {
	children: PropTypes.object,
};
