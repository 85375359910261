import Axios from 'axios';
import UserController from './UserController';
import { APIRoute, APIPath } from '../helpers/Constants';
import { generateResponse, isNullOrWhitespace } from '../helpers/Utils';

const ProductRequestController = {
	postProductRequest,
	getProductRequests,
	getProductRequestExcels,
	acceptProductRequestWithoutChanges,
	acceptProductRequestWithChanges,
	declineProductRequest,
	fulfillProductRequest,
};

async function postProductRequest(
	Email,
	RequesterName,
	RequesterMobileNumber,
	RequesterRole,
	RequesterCenter,
	DeliveryPoint,
	IsUrgent,
	RequestedDate,
	ContactDetails,
	RequestReason,
	Children
) {
	const data = {
		Email: Email,
		RequesterName: RequesterName,
		RequesterMobileNumber: RequesterMobileNumber,
		RequesterRole: RequesterRole,
		RequesterCenter: RequesterCenter,
		DeliveryPoint: DeliveryPoint,
		IsUrgent: IsUrgent ? true : false,
		RequestedDate: RequestedDate,
		ContactDetails: ContactDetails,
		RequestReason: RequestReason,
		AdditionalInfoOptionId: localStorage.getItem('selectedAdditionalInfo'),
		Children: Children.map(value => {
			return {
				LastName: value.LastName,
				RefNumber: value.RefNumber,
				LastNameOrRef: value.LastNameOrRef,
				FirstPostcode: value.FirstPostcode,
				Age: Number(value.Age),
				Gender: value.Gender,
				Build: value.Build,
				Height: value.Height,
				SchoolFullName: value.SchoolFullName,
				SchoolYear: value.SchoolYear,
				SchoolHouse: value.SchoolHouse,
				ShoeSize: value.ShoeSize,
				TopSize: value.TopSize,
				TrouserSize: value.TrouserSize,
				Notes: value.Notes,

				Products: value.cart
					.filter(product => {
						return product.amount > 0;
					})
					.map(product => {
						return {
							ProductId: product.productId,
							StockCountId: product.stockId,
							Amount: product.amount,
						};
					}),
			};
		}),
	};
	return Axios.post(APIRoute + APIPath.CREATE_PRODUCT_REQUEST, data)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function getProductRequests(state, filter, startDate, endDate) {
	const userHeaders = await UserController.getUserHeaders();

	const filterQuery = filter ? `&filter=${filter}` : '';
	const startDateQuery = startDate ? `&startDate=${startDate}` : '';
	const endDateQuery = endDate ? `&endDate=${endDate}` : '';

	if (isNullOrWhitespace(filter)) {
		return Axios.get(
			APIRoute + APIPath.GET_PRODUCT_REQUESTS + `?state=${state}${startDateQuery}${endDateQuery}`,
			userHeaders
		)
			.then(response => {
				return generateResponse(false, response.data);
			})
			.catch(reason => {
				return generateResponse(true, reason);
			});
	} else {
		return Axios.get(
			APIRoute +
				APIPath.GET_FILTERED_PRODUCT_REQUESTS +
				`?state=${state}${filterQuery}${startDateQuery}${endDateQuery}`,
			userHeaders
		)
			.then(response => {
				return generateResponse(false, response.data);
			})
			.catch(reason => {
				return generateResponse(true, reason);
			});
	}
}

async function getProductRequestExcels(state, filter, startDate, endDate) {
	const userHeaders = await UserController.getUserHeaders();

	const filterQuery = filter ? `&filter=${filter}` : '';
	const startDateQuery = startDate ? `&startDate=${startDate}` : '';
	const endDateQuery = endDate ? `&endDate=${endDate}` : '';

	return Axios.get(
		APIRoute + APIPath.GET_PRODUCT_EXCEL_REQUESTS + `?state=${state}${filterQuery}${startDateQuery}${endDateQuery}`,
		userHeaders
	)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function acceptProductRequestWithoutChanges(id) {
	const userHeaders = await UserController.getUserHeaders();
	return Axios.post(APIRoute + APIPath.ACCEPT_PRODUCT_REQUEST_NO_CHANGES + id, null, userHeaders)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function acceptProductRequestWithChanges(id, productRequestAmounts) {
	const userHeaders = await UserController.getUserHeaders();

	const data = {
		Id: id,
		ProductRequestAmounts: productRequestAmounts,
	};

	return Axios.post(APIRoute + APIPath.ACCEPT_PRODUCT_REQUEST_WITH_CHANGES, data, userHeaders)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function declineProductRequest(id) {
	const userHeaders = await UserController.getUserHeaders();
	return Axios.post(APIRoute + APIPath.DECLINE_PRODUCT_REQUEST + id, null, userHeaders)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}
async function fulfillProductRequest(id) {
	const userHeaders = await UserController.getUserHeaders();
	return Axios.post(APIRoute + APIPath.FULFILL_PRODUCT_REQUEST + id, null, userHeaders)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

export default ProductRequestController;
