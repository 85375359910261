import Axios from 'axios';
import { APIRoute, APIPath } from '../helpers/Constants';
import { generateResponse } from '../helpers/Utils';

const VersionController = {
	getVersion,
};

async function getVersion(name, currentVersion) {
	const data = { name, currentVersion };
	return Axios.post(APIRoute + APIPath.GET_VERSION, data)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

export default VersionController;
